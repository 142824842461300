import React, { Component} from 'react'
import {
  Button,
  Grid,
  FormControlLabel,
  Radio,
  Container
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import { renderRadioButtonGroup, renderCheckbox } from '../../../utils/wrappers'
import { Field, change } from 'redux-form'
import parse from 'html-react-parser'
import browserHistory from '../../../history'

const styles = (theme) => ({
  surveyContainer: {
    marginTop: 1,
    padding: '20px 20px 0 20px',
    background: 'white'
  },
  survey_title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 4px 10px 4px'
  },
  survey_description: {
    position: 'relative',
    padding: '10px 4px'
  },

  question_item_saved: {
    padding: '5vh 4px',
    whiteSpace: 'pre-line'
  },
  question_item_saved_single_text: {
    padding: '2vh 4px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  survey_title_text: {
    fontWeight: '700',
    fontStyle: 'normal',
    textDecoration: 'none',
    color: theme.palette.primary.green,
    lineHeight: '1.25'
  },
  survey_pageTitle_text: {
    fontWeight: '400',
    fontStyle: 'normal',
    textDecoration: 'none',
    color: theme.palette.primary.green,
    lineHeight: '1.25'
  },

  answers_item_input: {
    fontStyle: 'normal',
    fontSize: '18px',
    fontWeight: '300',
    textDecoration: 'none',
    outlineColor: '#00BF6F',
    color: '#333E48',
    backgroundColor: 'rgba(0, 0, 0, 0.10)',
    border: 0,
    width: '50%',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  btn_done_container: {
    flexDirection: 'column',
    justifyContent: 'center',
    marginBottom: '10vh',
    [theme.breakpoints.up('sm')]: {
      alignItems: 'center'
    },
    '& .MuiButton-label': {
      padding: '5px 20px'
    }
  },
  btn_done: {
    marginTop: '0.5rem',
    fontSize: '1.1rem',
    [theme.breakpoints.down('xs')]: {
      margin: '0rem 4rem',
      padding: '5px '
    }
  },
  questionTitle: {
    width: '40%',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  focused: {
    border: '1px solid white',
    padding: '4vh 5px',
    whiteSpace: 'pre-line',
    borderRadius: '5px',
    boxShadow:
      '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
  },

  empty: {
    padding: '2vh 4px',
    whiteSpace: 'pre-line',
    border: 'none'
  }
})

const utilizeScroll = () => {
  const htmlElRef = React.createRef()
  const executeScroll = () => {
    window.scrollTo(0, htmlElRef.current.offsetTop)
  }

  return { executeScroll, htmlElRef }
}

class SurveyForJobSeeker extends Component {
  constructor() {
    super()
    this.elScroll = utilizeScroll()
    this.state = {
      hover: '',
      topTitle: false,
      topPage: false,
      quesClicked: 0,
      timerId:null
    }

  }
  toggleHover = (field) => {
    this.setState({
      hover: field
    })
  }
  toggleDrawer = (side) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }
    this.setState({
      [side]: !this.state[side]
    })
  }
  goToCurrentQues = (id) => {
    const { formValues } = this.props
    const quesList = formValues && formValues.surveyList
    if (id === quesList.length) {
      this.setState({
        quesClicked: 0
      })
    } else if (id < quesList.length) {
      this.setState({
        quesClicked: id
      })
    }
  }

  goToNextQues = (id) => () => {
    const { formValues } = this.props
    const quesList = formValues && formValues.surveyList
    if (quesList[id].type === 'multi_textboxes') {
      if (this.state.timerId) {
        // Clear any existing timeout if it exists
        clearTimeout(this.state.timerId)
      }
       this.setState({
        timerId:setTimeout(() => {
        this.getFocusElement(this.state.quesClicked)
      }, 4500)})
    }
    else if (quesList[id].type === 'single_textbox') {
      if (this.state.timerId) {
        // Clear any existing timeout if it exists
        clearTimeout(this.state.timerId)
      }
      this.setState({
        timerId:setTimeout(() => {
        this.getFocusElement(this.state.quesClicked)
      }, 2500)})
    }
    else if (quesList[id].type === 'checkboxes') {
      setTimeout(() => {
        this.getFocusElement(this.state.quesClicked)
      }, 3000)
    }
    else if(quesList[id].type === 'multi_choice'){
      setTimeout(() => {
        this.getFocusElement(this.state.quesClicked)
      }, 600)
    }
    else {
        this.getFocusElement(this.state.quesClicked)
    }

  }
  getFocusElement = (id) => {
    //selecting the element by id and auto scrolling into it  for better user accessibilty
    let focusedElement = document.getElementById(`#question${id}`)
    if (focusedElement) {
      focusedElement.focus()
      focusedElement.scrollIntoView({ behavior: 'smooth'})
    }
  }
  componentDidMount() {
    const { surveyId } = this.props.match.params
    this.props.getSurveyDetailsByPostId(surveyId, 'full')
  }

  render() {
    const { classes, formValues, loading } = this.props
    return (
      <Container>
        <div className={classes.surveyContainer}>
          <div
            onClick={this.toggleDrawer('topTitle')}
            className={classes.survey_title}
            onMouseLeave={() => this.toggleHover()}
            onMouseEnter={() => this.toggleHover('survey_title')}
          >
            <h4 className={classes.survey_title_text}>
              {formValues && formValues.surveyTitle}
            </h4>
          </div>

          <div className={classes.survey_title}>
            {formValues && formValues.pageTitle && (
              <h4 className={classes.survey_pageTitle_text}>
                {formValues.pageTitle}
              </h4>
            )}
          </div>

          {formValues && formValues.description && (
            <div className={classes.survey_description}>
              {parse(formValues.description)}
            </div>
          )}

          {formValues &&
            formValues.surveyList &&
            formValues.surveyList.map((data, index) => {
              const id = parseInt(data.id)
              this.props.dispatch(
                change(
                  'surveyAnswer',
                  `question${parseInt(data.id) + 1}.type`,
                  data.type
                )
              )
              this.props.dispatch(
                change(
                  'surveyAnswer',
                  `question${parseInt(data.id) + 1}.question`,
                  data.question
                )
              )
              this.props.dispatch(
                change(
                  'surveyAnswer',
                  `question${parseInt(data.id) + 1}.questionID`,
                  data.QuestionID
                )
              )

              switch (data.type) {
                case 'multi_choice':
                  return (
                    data.question && (
                      <div onClick={() => this.goToCurrentQues(id)}>
                        <div
                          id={`#question${id}`}
                          className={
                            data.id !== this.state.quesClicked
                              ? classes.empty
                              : classes.focused
                          }
                        >
                          <h4>
                            {parseInt(data.id) + 1}. {data.question}
                          </h4>
                          <Field
                            name={`question${parseInt(data.id) + 1}.answer`}
                            component={renderRadioButtonGroup}
                          >
                            {data.answers.map((answer, index) => {
                              return (
                                answer && (
                                  <FormControlLabel
                                    key={index}
                                    value={`${answer}`}
                                    control={
                                      <Radio onClick={this.goToNextQues(id)} />
                                    }
                                    label={`${answer}`}
                                  />
                                )
                              )
                            })}
                          </Field>
                        </div>
                      </div>
                    )
                  )

                case 'checkboxes':
                  return (
                    <div onClick={() => this.goToCurrentQues(id)}>
                      <div
                        id={`#question${id}`}
                        className={
                          data.id !== this.state.quesClicked
                            ? classes.empty
                            : classes.focused
                        }
                      >
                        <h4>
                          {parseInt(data.id) + 1}. {data.question}
                        </h4>

                        {data.answers.map((answer, index) => {
                          return (
                            answer && (
                              <Field
                                key={index}
                                label={`${answer}`}
                                name={`question${
                                  parseInt(data.id) + 1
                                }.[${answer}]`}
                                value={answer}
                                component={renderCheckbox}
                                onClick={this.goToNextQues(id)}
                              />
                            )
                          )
                        })}
                      </div>
                    </div>
                  )

                case 'multi_textboxes':
                  return (
                    <div onClick={() => this.goToCurrentQues(id)}>
                      <div
                        id={`#question${id}`}
                        className={
                          data.id !== this.state.quesClicked
                            ? classes.empty
                            : classes.focused
                        }
                      >
                        <h4>
                          {parseInt(data.id) + 1}. {data.question}
                        </h4>
                        {data.answers.map((answer, index) => {
                          return (
                            answer && (
                              <Grid
                                key={index}
                                container
                                alignItems="center"
                                direction="row"
                                style={{ marginTop: '10px' }}
                              >
                                <span className={classes.questionTitle}>
                                  {answer}
                                </span>
                                <Field
                                  className={classes.answers_item_input}
                                  name={`question${
                                    parseInt(data.id) + 1
                                  }[${answer}]`}
                                  component="input"
                                  type="text"
                                  onInput={this.goToNextQues(id)}
                                ></Field>
                              </Grid>
                            )
                          )
                        })}
                      </div>
                    </div>
                  )
                case 'single_textbox':
                  return (
                    <div onClick={() => this.goToCurrentQues(id)}>
                      <div
                        id={`#question${id}`}
                        className={
                            data.id !== this.state.quesClicked
                              ? classes.empty
                              : classes.focused
                          }
                      >
                        <h4>
                          {parseInt(data.id) + 1}. {data.question}
                        </h4>
                        <Field
                          className={classes.answers_item_input}
                          name={`question${parseInt(data.id) + 1}.answer`}
                          component="input"
                          type="text"
                          onInput={this.goToNextQues(id)}
                        ></Field>
                      </div>
                    </div>
                  )
                default:
                  return ''
              }
            })}

          {formValues && formValues.surveyList && (
            <Grid container className={classes.btn_done_container}>
              <Button
                id="#btnDone"
                ref="btnDone"
                variant="contained"
                color="primary"
                className={classes.btn_done}
                onClick={() => {
                  browserHistory.push('/survey/done')
                }}
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress size={20}> </CircularProgress>
                ) : (
                  'Seuraava'
                )}
              </Button>
            </Grid>
          )}
        </div>
      </Container>
    )
  }
}
export default withStyles(styles)(SurveyForJobSeeker)
