import React, { Component } from 'react'
import { Button, Container, Grid } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import LinkIcon from '@material-ui/icons/Link'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import Snackbar from '@material-ui/core/Snackbar'
import { MySnackbarContentWrapper } from '../../../utils/snackbar.utils'
import { URL_GET_LINK_DEV } from '../../../constants'

const styles = (theme) => ({
  '@global': {
    body: {
      background: theme.palette.primary.bg
    }
  },

  toolbar_root: {
    background: 'white',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',

    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      paddingBottom: '10px'
    }
  },
  toolbar_processList: {
    background: 'white',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      marginBottom: '10px'
    }
  },
  toolbar_text: {
    margin: 0,
    padding: 10,
    fontWeight: '400',
    color: theme.palette.primary.black
  },
  toolbar_text_active: {
    margin: 0,
    padding: 10,
    fontWeight: '500',
    color: theme.palette.primary.green,
    borderBottom: `4px solid ${theme.palette.primary.green} `
  },
  toolbar_btn: {
    marginRight: '20px',
    [theme.breakpoints.down('xs')]: {
      alignSelf: 'flex-end'
    }
  },
  surveyContainer: {
    marginTop: 1,
    padding: theme.spacing(2),

    [theme.breakpoints.up('sm')]: {
      width: '70%'
    }
  },
  survey_title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 4px 10px 4px'
  },
  survey_description: {
    position: 'relative',
    padding: '10px 4px 5vh 4px'
  },
  survey_description_editBtn: {
    position: 'absolute',
    top: '6px',
    right: '3px'
  },
  question_item_btn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px 4px 20px 4px',
    background: theme.palette.primary.bg,
    marginTop: '30px'
  },
  question_item_saved: {
    padding: '10vh 4px 10vh 4px'
  },
  question_item_saved_single_text: {
    padding: '10vh 4px 10vh 4px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  survey_title_text: {
    fontWeight: '700',
    fontStyle: 'normal',
    textDecoration: 'none',
    color: theme.palette.primary.green,
    lineHeight: '1.25'
  },
  survey_pageTitle_text: {
    fontWeight: '400',
    fontStyle: 'normal',
    textDecoration: 'none',
    color: theme.palette.primary.green,
    lineHeight: '1.25'
  },

  survey_pageTitle_btn_label: {
    textTransform: 'capitalize',
    color: theme.palette.primary.black
  },

  label: {
    backgroundColor: 'white'
  },

  survey_quesItem_num: {
    fontWeight: '400'
  },

  survey_quesItem: {
    border: '1px solid #333E48',
    padding: '20px',
    backgroundColor: theme.palette.primary.lightGray
  },

  answers_item_input: {
    fontStyle: 'normal',
    fontSize: '18px',
    fontWeight: '300',
    textDecoration: 'none',
    outlineColor: '#00BF6F',
    color: '#333E48',
    backgroundColor: 'rgba(0, 0, 0, 0.10)',
    border: 0,
    width: '50%'
  },
  fullList: {
    width: 'auto'
  },

  survey_title_drawer: {
    background: theme.palette.primary.bg,
    padding: '30px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },

  btn_done: {
    padding: '1em 2em 1em 2em',
    [theme.breakpoints.down('sm')]: {
      margin: '0em 2em'
    }
  },
  btn_done_container: {
    flexDirection: 'column',
    justifyContent: 'center',
    margin: '20vh 0',
    [theme.breakpoints.up('sm')]: {
      alignItems: 'center'
    }
  },
  question_item_overlay: {
    opacity: '0.3',
    zIndex: '999'
  },
  getData: {
    display: 'flex',
    justifyContent: 'center',
    padding: '20px 0',
    color: '#E225D7',
    textTransform: 'uppercase',
    letterSpacing: '2px'
  }
})

class SurveyCollect extends Component {
  constructor() {
    super()
    this.state = {
      value: 'https://www.surveymonkey.com/r/MQG6B72',
      copied: false,
      isSnackbarOpen: false
    }
  }
  onCopy = () => {
    this.setState({ copied: true })
    this.handleClick()
  }

  handleClick = () => {
    this.setState({
      isSnackbarOpen: true
    })
  }

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    this.setState({
      isSnackbarOpen: false
    })
  }

  handleFocus = (event) => event.target.select()

  render() {
    const { classes, postDetail } = this.props

    const jobTitle =
      postDetail.Title &&
      postDetail.Title.toLowerCase().replace(/[?+%#/]/g, '-')

    const jobLocation =
      postDetail.Location &&
      postDetail.Location.toLowerCase().replace(/[?+%#/]/g, '-')

    const URL =
      postDetail &&
      `${URL_GET_LINK_DEV}/${jobTitle}/${jobLocation}/${postDetail.ID}`

    const url = URL && URL.replace(/[,\s]/g, '-')

    return (
      <Container>
        <div className={classes.getData}>
          <h2>Collect saved files</h2>
        </div>
        <Grid container direction="column" alignItems="center">
          <div className={classes.surveyContainer}>
            <h2>Web Link</h2>
            <Grid
              container
              style={{ background: 'white', padding: '10px' }}
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs={12} sm={10}>
                <LinkIcon />
                <input
                  onFocus={this.handleFocus}
                  style={{ width: '80%', border: '0' }}
                  readOnly
                  value={url}
                ></input>
              </Grid>
              <Grid item xs={12} sm={2}>
                <CopyToClipboard onCopy={this.onCopy} text={url}>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.toolbar_btn}
                  >
                    Copy
                  </Button>
                </CopyToClipboard>
              </Grid>
            </Grid>
          </div>
        </Grid>

        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          open={this.state.isSnackbarOpen}
          autoHideDuration={1000}
          onClose={this.handleClose}
        >
          <MySnackbarContentWrapper
            onClose={this.handleClose}
            variant="success"
            message="Copy success!"
          />
        </Snackbar>
      </Container>
    )
  }
}
export default withStyles(styles)(SurveyCollect)
