import {
  RETRIEVE_IMAGE_INFO,
  CLEAR_IMAGES,
  SAVE_POST_SUCCESS,
  GET_ALL_POSTS_SUCCESS,
  UPDATE_POST_SUCCESS,
  CLEAR_POST_DETAIL,
  GET_POST_DETAILS_BY_ID_SUCCESS,
  SET_POSTS_DETAIL,
  COPY_POSTS_DETAIL,
  COPY_POSTS_DETAIL_SUCCESS,
  AUTO_EMAIL_TO_APPLICANT,
  POPULATE_AUTO_MESSAGE_SUCCESS,
  SET_POST_STATE
} from '../constants'

const initialState = {
  postList: [],
  id: 0,
  postDetail: {},
  document: {},
  postState: 'none',
  copiedPostId: 0,
  automaticEmailToggleBtn: false,
  automessage: {}
}

const surveyReducer = (state = initialState, action) => {
  switch (action.type) {
    case RETRIEVE_IMAGE_INFO:
      return {
        ...state,
        document: action.image
      }

    case CLEAR_IMAGES:
      return {
        ...state,
        document: {}
      }
    case AUTO_EMAIL_TO_APPLICANT:
      return {
        ...state,
        automaticEmailToggleBtn: state.automaticEmailToggleBtn
      }
    case SAVE_POST_SUCCESS:
      return {
        ...state,
        postDetail: action.result,
        automaticEmailToggleBtn: false
      }
    case UPDATE_POST_SUCCESS:
      return {
        ...state,
        postDetail: action.result,
        automaticEmailToggleBtn: false
      }
    case GET_ALL_POSTS_SUCCESS:
      return {
        ...state,
        postList: action.result
      }

    case GET_POST_DETAILS_BY_ID_SUCCESS:
      return {
        ...state,
        postDetail: action.result
      }

    case SET_POSTS_DETAIL:
      return {
        ...state,
        postDetail: action.post
      }
    case CLEAR_POST_DETAIL:
      return {
        ...state,
        postDetail: {}
      }
    case SET_POST_STATE: 
      return {
        ...state,
        postState: action.postState
      }
    case POPULATE_AUTO_MESSAGE_SUCCESS:
      return {
        ...state,
        automessage: action.result
      }
    case COPY_POSTS_DETAIL:
      const { Document, ...detail } = action.post //Don't copy image

      return {
        ...state,
        postDetail: detail,
        postState: 'copy',
        copiedPostId: action.post.ID,
        document: {}
      }
    case COPY_POSTS_DETAIL_SUCCESS:
      return {
        ...state,
        postState: 'none',
        copiedPostId: 0
      }
    default:
      return state
  }
}

export default surveyReducer
