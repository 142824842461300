import React from 'react'

export const customURL = (url) => {
  // return url.replace(/ /g, '-')
  const newUrl = url.replace(/[^äöåa-zÄÖÅA-Z0-9]/g, ' ')
  return newUrl.replace(/ /g, '-')
}

export const showCampaign = (t, type) => {
  return (
    <div>
      {type === 'free' && <h6>{t('type1')}</h6>}
      {type === 'lift' && <h6>{t('type2')}</h6>}
      {type === 'visual' && <h6>{t('type3')} </h6>}
      {type === 'home_page_thing' && <h6>{t('type4')}</h6>}
      {type === 'some_star' && <h6>{t('type5')} </h6>}
      {type === 'noteworthy' && <h6>{t('type6')}</h6>}
    </div>
  )
}

export const sortByLatestDate = (data, dateKey) => {
  return data.sort((x, y) => {
    return new Date(y[dateKey]) - new Date(x[dateKey])
  })
}
