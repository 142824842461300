import { takeEvery, call, put, cancelled } from 'redux-saga/effects'
import { SIGNIN_FORM_SUBMIT, VPT_IDENTITY_SERVER, LOGOUT } from '../constants'
import store from '../store'
import { getFormValues } from 'redux-form'
import CryptoJS from 'crypto-js'
import { loginPost } from '../utils/request'
import {
  signInFormSubmitFailed,
  signInFormSubmitSuccess,
  setClient,
  unsetClient
} from '../actions'
import browserHistory from '../history'
function* signInSaga() {
  let result
  try {
    const url = `${VPT_IDENTITY_SERVER}/CheckUser`
    const formValues = getFormValues('signin')(store.getState())
    const hashedPassword = CryptoJS.SHA256(formValues.password).toString()
    const body = JSON.stringify({
      email: formValues.email,
      password: hashedPassword,
      SubjectId: formValues.email
    })
    result = yield call(loginPost, url, body)
    yield put(setClient(result))
    sessionStorage.setItem('user', JSON.stringify(result))
    if (result.data[0].access_token) {
      yield put(signInFormSubmitSuccess())
      browserHistory.push('/survey')
    } else {
      yield put(signInFormSubmitFailed(result.data[0].error_description))
      yield put(unsetClient())
      sessionStorage.removeItem('user')
      browserHistory.push('/login')
    }
  } catch (error) {
    yield put(signInFormSubmitFailed(error))
    yield put(unsetClient())
    sessionStorage.removeItem('user')
    browserHistory.push('/login')
  } finally {
    if (yield cancelled()) {
      browserHistory.push('/dashboard/login')
    }
    return result
  }
}

function* logout() {
  yield put(unsetClient())
  sessionStorage.removeItem('user')
  browserHistory.push('/login')
}
export function* watchSignInSaga() {
  yield takeEvery(SIGNIN_FORM_SUBMIT, signInSaga)
}

export function* watchLogoutSaga() {
  yield takeEvery(LOGOUT, logout)
}
