import { connect } from 'react-redux'
import {
  getAllSurveys,
  unsetSurveyDetails,
  deleteSurvey,
  updateSurveyStatus,
  getAllPosts,
  getPostDetailsById,
  clearPostDetail,
  deletePost,
  updatePostActive,
  setPostDetail,
  copyPostDetail,
  clearImagesFromState,
  populateAutoMessage,
  setPostState
} from '../../../actions'
import SurveyDashboardComponent from '../../../components/survey/company/SurveyDashboard.component'
import { reduxForm } from 'redux-form'

const mapStateToProps = (state) => {
  const { survey, async, post } = state

  return {
    state,
    surveyList: survey.surveyList,
    loading: async.getAllSurveys.loading,
    postList: post.postList
  }
}

const actions = (dispatch) => {
  return {
    dispatch,
    getAllSurveys: () => dispatch(getAllSurveys()),
    unsetSurveyDetails: () => dispatch(unsetSurveyDetails()),
    deleteSurvey: (id) => dispatch(deleteSurvey(id)),
    updateSurveyStatus: (id) => dispatch(updateSurveyStatus(id)),
    getAllPosts: () => dispatch(getAllPosts()),
    getPostDetailsById: (id) => dispatch(getPostDetailsById(id)),
    clearPostDetail: () => dispatch(clearPostDetail()),
    deletePost: (id) => dispatch(deletePost(id)),
    updatePostActive: (id) => dispatch(updatePostActive(id)),
    setPostDetail: (post) => dispatch(setPostDetail(post)),
    copyPostDetail: (post) => dispatch(copyPostDetail(post)),
    clearImagesFromState: () => dispatch(clearImagesFromState()),
    populateAutoMessage: (id) => dispatch(populateAutoMessage(id)),
    setPostState: (postState) => dispatch(setPostState(postState))
  }
}

const SurveysForm = reduxForm({
  form: 'surveyTitle',
  destroyOnUnmount: false,
  enableReinitialize: true
})(SurveyDashboardComponent)

const SurveyDashboardContainer = connect(mapStateToProps, actions)(SurveysForm)

export default SurveyDashboardContainer
