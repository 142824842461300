import { call, put, takeEvery } from 'redux-saga/effects'
import {
  VPT_API_SERVER,
  SAVE_POST,
  GET_ALL_POSTS,
  UPDATE_POST,
  DELETE_POST,
  UPDATE_POST_ACTIVE,
  GET_POST_DETAILS_BY_ID,
  POPULATE_AUTO_MESSAGE
} from '../constants'
import {
  asyncActionStart,
  asyncActionFinish,
  asyncActionError,
  getPostDetailsByIdSuccess,
  populateAutoMessageBySuccess,
  savePostSuccess,
  getAllPostsSuccess,
  updatePostSuccess,
  getAllPosts,
  clearPostDetail,
  unsetSurveyDetails,
  clearUploadedOtherFiles,
  clearUploadedCV,
  clearImagesFromState
} from '../actions'
import { apiManualPost, normalPost } from '../utils/request'
import store from '../store'
import { getFormValues, initialize, change } from 'redux-form'

function* getAllPostsSaga() {
  try {
    yield put(asyncActionStart('getAllPosts'))
    const url = `${VPT_API_SERVER}/GetPosts`
    const uuid = store.getState().client.user.data[2]
    const body = JSON.stringify({
      UUID: uuid
    })

    const result = yield call(apiManualPost, url, body)

    if (result.data.success) {
      yield put(getAllPostsSuccess(JSON.parse(result.data.data)))
      yield put(asyncActionFinish('getAllPosts'))
      yield put(clearImagesFromState())
      yield put(initialize('survey', null))
      yield put(unsetSurveyDetails())
      yield put(initialize('surveyDetail', null))
      yield put(clearUploadedCV())
      yield put(clearUploadedOtherFiles())
    } else {
      yield put(asyncActionError('getAllPosts'))
    }
  } catch (e) {
    yield put(asyncActionError('getAllPosts'))
    console.warn(e)
  }
}

function* getPostDetailsByIdSaga({ postId }) {
  try {
    yield put(asyncActionStart('getPostDetailsById'))
    yield put(clearPostDetail())

    const url = `${VPT_API_SERVER}/GetPostByID`

    const body = JSON.stringify({
      ID: postId
    })

    const result = yield call(normalPost, url, body)

    if (result.data.success) {
      const data = JSON.parse(result.data.data)[0]

      yield put(getPostDetailsByIdSuccess(data))

      yield put(asyncActionFinish('getPostDetailsById'))
    } else {
      yield put(initialize('survey', null))
      yield put(unsetSurveyDetails())
      yield put(initialize('surveyDetail', null))
      yield put(clearUploadedCV())
      yield put(clearUploadedOtherFiles())

      yield put(asyncActionFinish('getPostDetailsById'))
    }
  } catch (e) {
    yield put(initialize('survey', null))
    yield put(unsetSurveyDetails())
    yield put(initialize('surveyDetail', null))
    yield put(clearUploadedCV())
    yield put(clearUploadedOtherFiles())
    console.warn(e)
    yield put(asyncActionError('getPostDetailsById'))
  }
}
function* populateAutoMessageSaga({ id }) {
  try {
    const send_thank_you_email =
      store.getState().post.postDetail.send_thankyou_email
    const body1 = JSON.stringify({
      PostId: id
    })
    if (send_thank_you_email) {
      const autoReplyUrl = `${VPT_API_SERVER}/GetAutomaticReplyByPostsId`
      const result1 = yield call(normalPost, autoReplyUrl, body1)
      const parsedResult = JSON.parse(result1.data)[0]
      if (send_thank_you_email === true) {
        yield put(
          change(
            'post',
            'email_language',
            JSON.parse(parsedResult.email_language)
          )
        )
        yield put(change('post', 'email_message', parsedResult.email_message))
        yield put(change('post', 'email_subject', parsedResult.email_subject))
      }
      yield put(populateAutoMessageBySuccess(parsedResult))
    }
  } catch (e) {
    console.log(e)
  }
}
function* savePostSaga() {
  try {
    yield put(asyncActionStart('savePost'))
    const url = `${VPT_API_SERVER}/AddPosts`
    const surveyForm = getFormValues('post')(store.getState())
    const uuid = store.getState().client.user.data[2]
    const document = store.getState().post.document
    const { name, type } = document
    const hasThankYouEmail = surveyForm && surveyForm.automaticEmailToggleBtn

    let newBody
    if (hasThankYouEmail) {
      newBody = {
        UUID: uuid,
        Title: (surveyForm && surveyForm.title) || null,
        Location: (surveyForm && surveyForm.location) || null,
        Description: (surveyForm && surveyForm.description) || null,
        dueDate:
          (surveyForm && surveyForm.dueDate) ||
          (surveyForm && surveyForm.validuntill_due) ||
          null,
        // validuntill_OrDue: surveyForm && surveyForm.validuntill_due,
        Document: {
          FileName: name,
          FileType: type,
          Path: '',
          Data: (surveyForm && surveyForm.document) || null
        },
        send_thank_you_email: hasThankYouEmail || null,
        email_subject: (surveyForm && surveyForm.email_subject) || null,
        email_message: (surveyForm && surveyForm.email_message) || null,
        email_language: [
          {
            type: 'finnish',
            label: 'Finnish'
          }
        ]
      }
    } else {
      newBody = {
        UUID: uuid,
        Title: (surveyForm && surveyForm.title) || null,
        Location: (surveyForm && surveyForm.location) || null,
        Description: (surveyForm && surveyForm.description) || null,
        dueDate:
          (surveyForm && surveyForm.dueDate) ||
          (surveyForm && surveyForm.validuntill_due) ||
          null,
        Document: {
          FileName: name,
          FileType: type,
          Path: '',
          Data: (surveyForm && surveyForm.document) || null
        },
        send_thank_you_email: hasThankYouEmail
      }
    }
    const body = JSON.stringify(newBody)

    const result = yield call(apiManualPost, url, body)

    if (result.data.success) {
      const data = JSON.parse(result.data.data)[0]

      yield put(savePostSuccess(data))
      yield put(asyncActionStart('showCreateSurvey'))
      yield put(asyncActionFinish('savePost'))
    } else {
      yield put(asyncActionFinish('savePost'))
    }
  } catch (e) {
    console.warn(e)
    yield put(asyncActionFinish('savePost'))
  }
}

function* updatePostSaga({ isChangeImg }) {
  try {
    const url = `${VPT_API_SERVER}/UpdatePosts`
    const surveyForm = getFormValues('post')(store.getState())
    const uuid = store.getState().client.user.data[2]
    const document = store.getState().post.document
    const { name, type } = document

    const documentPath=store.getState().post.postDetail.Document
    let newBody = {}

    if (isChangeImg) {
      newBody = {
        ID: surveyForm.id,
        UUID: uuid,
        Title: (surveyForm && surveyForm.title) || null,
        Location: (surveyForm && surveyForm.location) || null,
        Description: (surveyForm && surveyForm.description) || null,
        dueDate:
          (surveyForm && surveyForm.dueDate) ||
          (surveyForm && surveyForm.validuntill_due) ||
          null,
        Document: {
          FileName: name,
          FileType: type,
          Path: '',
          Data: (surveyForm && surveyForm.document) || null
        },
        send_thank_you_email: surveyForm && surveyForm.automaticEmailToggleBtn,
        email_subject: (surveyForm && surveyForm.email_subject) || null,
        email_message: (surveyForm && surveyForm.email_message) || null,
        email_language: [
          {
            type: 'finnish',
            label: 'Finnish'
          }
        ]
      }
    }
    else if(!document.name&& !surveyForm.document){// no image name means, no need to send any base64. Also, if there is an image stored in db..and we want to delete it,sending path to delete from storage.
        newBody = {
        ID: surveyForm.id,
        UUID: uuid,
        Title: (surveyForm && surveyForm.title) || null,
        Location: (surveyForm && surveyForm.location) || null,
        Description: (surveyForm && surveyForm.description) || null,
        dueDate:
          (surveyForm && surveyForm.dueDate) ||
          (surveyForm && surveyForm.validuntill_due) ||
          null,
        Document: {path:documentPath},
        send_thank_you_email: surveyForm && surveyForm.automaticEmailToggleBtn,
        email_subject: (surveyForm && surveyForm.email_subject) || null,
        email_message: (surveyForm && surveyForm.email_message) || null,
        email_language: [
          {
            type: 'finnish',
            label: 'Finnish'
          }
        ]
      }
    }
    else {
      newBody = {
        ID: surveyForm.id,
        UUID: uuid,
        Title: (surveyForm && surveyForm.title) || null,
        Location: (surveyForm && surveyForm.location) || null,
        dueDate:
          (surveyForm && surveyForm.dueDate) ||
          (surveyForm && surveyForm.validuntill_due) ||
          null,
        /* dueDate: (surveyForm && surveyForm.dueDate) || null,
        validuntill_OrDue: surveyForm && surveyForm.validuntill_due,*/
        Description: (surveyForm && surveyForm.description) || null,
        send_thank_you_email: surveyForm && surveyForm.automaticEmailToggleBtn,
        email_subject: (surveyForm && surveyForm.email_subject) || null,
        email_message: (surveyForm && surveyForm.email_message) || null,
        email_language: [
          {
            type: 'finnish',
            label: 'Finnish'
          }
        ]
      }
    }

    const body = JSON.stringify(newBody)

    const result = yield call(apiManualPost, url, body)

    if (result.data.success) {
      const data = JSON.parse(result.data.data)[0]

      // modify data for action, as otherwise the data will have a path of a deleted image which results in broken image if user presses back button
      if (!document.name && !surveyForm.document) {
        const dataWithoutImagePath = {
          ...data,
          Document: null
        }
        yield put(updatePostSuccess(dataWithoutImagePath))
        yield put(asyncActionStart('showCreateSurvey'))
      }
     else {
      yield put(updatePostSuccess(data))
      yield put(asyncActionStart('showCreateSurvey'))
    }
  }
    else {
      console.log('Hi, err updating post!: ', result.data.message)
    }
  } catch (e) {
    console.warn(e)
  }
}

function* deletePostSaga({ id }) {
  try {
    const url = `${VPT_API_SERVER}/DeletePosts`

    const uuid = store.getState().client.user.data[2]

    const newBody = {
      ID: id,
      UUID: uuid
    }

    const body = JSON.stringify(newBody)

    const result = yield call(apiManualPost, url, body)

    if (result.data.success) {
      yield put(getAllPosts())

      //yield put(getAllSurveysSuccess(JSON.parse(result.data.data)))
    } else {
      console.log('Hi, err deleting post!: ', result.data.message)
    }
  } catch (e) {
    console.warn(e)
  }
}

function* updatePostActiveSaga({ postId }) {
  try {
    const url = `${VPT_API_SERVER}/UpdatePostActive`

    const newBody = {
      ID: postId
    }

    const body = JSON.stringify(newBody)

    const result = yield call(apiManualPost, url, body)

    if (result.data.success) {
      yield put(getAllPosts())

      //yield put(getAllSurveysSuccess(JSON.parse(result.data.data)))
    } else {
      console.log('Hi, err updating post status!: ', result.data.message)
    }
  } catch (e) {
    console.log('oops, an error: ', e)
    console.warn(e)
  }
}

export function* watchGetAllPosts() {
  yield takeEvery(GET_ALL_POSTS, getAllPostsSaga)
}

export function* watchGetPostDetailsById() {
  yield takeEvery(GET_POST_DETAILS_BY_ID, getPostDetailsByIdSaga)
}

export function* watchSavePost() {
  yield takeEvery(SAVE_POST, savePostSaga)
}

export function* watchUpdatePost() {
  yield takeEvery(UPDATE_POST, updatePostSaga)
}

export function* watchDeletePost() {
  yield takeEvery(DELETE_POST, deletePostSaga)
}

export function* watchUpdatePostActiveSaga() {
  yield takeEvery(UPDATE_POST_ACTIVE, updatePostActiveSaga)
}
export function* watchPopulateAutoMessageSaga() {
  yield takeEvery(POPULATE_AUTO_MESSAGE, populateAutoMessageSaga)
}
