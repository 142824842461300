import React, { Component } from 'react'
import store from '../../../store'
import { Button, Grid, IconButton, Drawer, Container } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import {
  Close,
  RadioButtonUnchecked,
  AddCircleOutline,
  RemoveCircleOutline,
  CheckBoxOutlineBlank
} from '@material-ui/icons'
// import { Link } from 'react-router-dom'
import { renderTextField, renderSelectField } from '../../../utils/wrappers'
import { Field, FieldArray, change, getFormValues } from 'redux-form'
import EditorField from './richText/EditorField'
import parse from 'html-react-parser'
import browserHistory from '../../../history'
import { emptyAnswers } from '../../../containers/validate'

const styles = (theme) => ({
  toolbar_btn: {
    marginRight: '20px',
    [theme.breakpoints.down('xs')]: {
      alignSelf: 'flex-end'
    }
  },
  surveyContainer: {
    marginTop: 1,
    padding: '20px 20px 0 20px',
    background: 'white'
  },
  survey_title: {
    alignItems: 'center',
    marginTop: 30,
    '&:hover': {
      background: theme.palette.primary.bg,
      cursor: 'pointer'
    }
  },
  survey_description: {
    position: 'relative',
    padding: '10px 4px 10px 4px',
    '&:hover': {
      background: theme.palette.primary.bg,
      cursor: 'pointer'
    }
  },
  survey_description_editBtn: {
    position: 'absolute',
    top: '6px',
    right: '3px'
  },
  question_item_btn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px 4px 20px 4px',
    background: theme.palette.primary.bg,
    marginTop: '30px'
  },
  question_item_saved: {
    padding: '10px 4px 10px 4px',
    margin: '10px 0',
    '&:hover': {
      background: theme.palette.primary.bg,
      cursor: 'pointer'
    }
  },

  survey_pageTitle_text: {
    fontWeight: '400',
    fontStyle: 'normal',
    textDecoration: 'none',
    color: theme.palette.primary.green,
    lineHeight: '1.25'
  },

  survey_pageTitle_btn_label: {
    textTransform: 'capitalize',
    color: theme.palette.primary.black
  },
  questionNumBox: {
    maxWidth: '35px',
    marginTop: '5px',
    [theme.breakpoints.only('xs')]: {
      maxWidth: '28px',
      margin: '0 5px'
    }
  },
  questionBox: {
    marginRight: '5px',
    [theme.breakpoints.only('xs')]: {
      margin: '0 5px',
      marginLeft: '10px'
    }
  },
  questionChoiceBox: {
    [theme.breakpoints.only('xs')]: {
      margin: '15px',
      marginLeft: '47px'
    }
  },

  survey_quesItem_num: {
    fontWeight: '400'
  },

  survey_quesItem: {
    border: '1px solid #333E48',
    padding: '20px',
    backgroundColor: theme.palette.primary.lightGray
  },

  answers_item_input: {
    fontStyle: 'normal',
    fontSize: '18px',
    fontWeight: '300',
    textDecoration: 'none',
    outlineColor: '#00BF6F',
    color: '#333E48',
    backgroundColor: 'rgba(0, 0, 0, 0.10)',
    border: 0,
    width: '50%',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },

  survey_title_drawer: {
    background: theme.palette.primary.bg,
    padding: '30px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  question_item_btnGroup: {
    [theme.breakpoints.down('sm')]: {
      marginTop: '10px',
      justifyContent: 'space-between'
    }
  },

  createSurveyTitle: {
    display: 'flex',
    justifyContent: 'center',
    padding: '20px 0',
    color: '#E225D7',
    textTransform: 'uppercase',
    letterSpacing: '2px'
  },
  nextAndBackBtn: {
    display: 'flex',
    justifyContent: 'center',
    padding: '30px 0'
  },
  questionTitle: {
    width: '40%',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  }
})

const renderAnswers = ({ fields, question }) => (
  <div>
    {fields.map((answer, index) => (
      <Grid
        key={index}
        container
        style={{ margin:'20px 0px 20px 0px'}}
        alignItems="center"
      >
        <Grid item sm={1} xs={2}>
          {question.type === 'multi_textboxes' ? (
            <h6>Label {index + 1}</h6>
          ) : question.type === 'checkboxes' ? (
            <CheckBoxOutlineBlank color="disabled" />
          ) : (
            <RadioButtonUnchecked color="disabled"></RadioButtonUnchecked>
          )}
        </Grid>
        <Grid item sm={9} xs={8}>
          <Field
            name={answer}
            type="text"
            component={renderTextField}
            label={`Answer #${index + 1}`}
          />
        </Grid>
        <Grid
          item
          sm={2}
          xs={2}
          alignItems="center"
          container
          justifyContent="space-around"
        >
          <IconButton
            onClick={() => fields.push()}
            caria-label="add"
            style={{ outline: 'none' }}
          >
            <AddCircleOutline color="disabled"></AddCircleOutline>
          </IconButton>
          {fields.length !== 1 && (
            <IconButton
              onClick={() => fields.remove(index)}
              caria-label="delete"
              style={{ outline: 'none' }}
            >
              <RemoveCircleOutline color="disabled"> </RemoveCircleOutline>
            </IconButton>
          )}
        </Grid>
      </Grid>
    ))}
  </div>
)

const renderQuestions = ({ fields, dispatch, classes, toggleHover }) => {
  return (
    <div>
      {fields.map((question, index) => {
        dispatch(change('survey', `surveyList.${index}.id`, `${index}`))
        const formValues = getFormValues('survey')(store.getState())
        const data = formValues.surveyList[index]

        const value = data && data.question
        const isSaved = data && data.isSaved

        return (
          <div key={index}>
            {!isSaved ? (
              <Grid container direction="column" style={{ marginTop: '20px' }}>
                <Close
                  style={{ alignSelf: 'flex-end' }}
                  onClick={() => fields.remove(index)}
                ></Close>
                <Grid container className={classes.survey_quesItem}>
                  <Grid item sm={2} xs={2} className={classes.questionNumBox}>
                    <span className={classes.survey_quesItem_num}>
                      Q{index + 1}
                    </span>
                  </Grid>
                  <Grid item sm={6} xs={8} className={classes.questionBox}>
                    <Field
                      name={`${question}.question`}
                      placeholder="Enter your question"
                      component={renderTextField}
                      label="Question"
                    />
                  </Grid>
                  <Grid
                    item
                    sm={4}
                    xs={8}
                    className={classes.questionChoiceBox}
                  >
                    <Field
                      name={`${question}.type`}
                      component={renderSelectField}
                      label="Type"
                    >
                      {/* <option value='' /> */}
                      <option value={'multi_choice'}>Multiple Choice</option>
                      <option value={'checkboxes'}>Checkboxes</option>
                      <option value={'multi_textboxes'}>
                        Multiple Textboxes
                      </option>
                      <option value={'single_textbox'}>Single Textbox</option>
                    </Field>
                  </Grid>

                  <Grid item xs={12}>
                    {value && data.type !== 'single_textbox' && (
                      <FieldArray
                        classes={classes}
                        question={data}
                        name={`${question}.answers`}
                        component={renderAnswers}
                      ></FieldArray>
                    )}
                  </Grid>

                  {/* button group */}

                  {value && (
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      style={{ marginTop: '20px' }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          if (data.type === 'single_textbox' || !emptyAnswers(data)) {
                          dispatch(
                            change(
                              'survey',
                              `surveyList.${index}.isSaved`,
                              true
                            )
                          )
                          fields.splice(index + 1, 0, {
                            answers: [null, null],
                            type: data.type
                          })
                        } else {
                          alert('Täytä kaikki kentät.')
                        }
                        }}
                      >
                        <AddCircleOutline style={{ marginRight: '5' }} />
                        NEXT QUESTION
                      </Button>

                      <div className={classes.question_item_btnGroup}>
                        <Button
                          variant="outlined"
                          color="primary"
                          classes={{
                            label: classes.survey_pageTitle_btn_label
                          }}
                          onClick={() => {
                            fields.remove(index)
                          }}
                        >
                          DELETE
                        </Button>

                        <Button
                          variant="contained"
                          color="primary"
                          style={{ marginLeft: '10px' }}
                          onClick={() => {
                            if (data.type === 'single_textbox' || !emptyAnswers(data)) {
                            dispatch(
                              change(
                                'survey',
                                `surveyList.${index}.isSaved`,
                                true
                              )
                            )
                          } else {
                            alert('Täytä kaikki kentät.')
                          }
                          }}
                        >
                          SAVE
                        </Button>
                      </div>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            ) : data.type === 'multi_textboxes' ? (
              <div
                className={classes.question_item_saved}
                onMouseLeave={() => toggleHover()}
                onMouseEnter={() => toggleHover('question_item')}
                onClick={() => {
                  dispatch(
                    change('survey', `surveyList.${index}.isSaved`, false)
                  )
                }}
              >
                <h4>
                  {index + 1}. {data.question}
                </h4>
                {data &&
                  data.answers &&
                  data.answers.map((answer, index) => {
                    return (
                      answer && (
                        <Grid
                          key={index}
                          container
                          direction="row"
                          style={{ marginTop: '10px' }}
                        >
                          <span className={classes.questionTitle}>
                            {answer}
                          </span>
                          <input
                            type="text"
                            disabled="disabled"
                            className={classes.answers_item_input}
                          ></input>
                        </Grid>
                      )
                    )
                  })}
              </div>
            ) : data.type === 'single_textbox' ? (
              <div
                className={classes.question_item_saved}
                onMouseLeave={() => toggleHover()}
                onMouseEnter={() => toggleHover('question_item')}
                onClick={() => {
                  dispatch(
                    change('survey', `surveyList.${index}.isSaved`, false)
                  )
                }}
              >
                <h4>
                  {index + 1}. {data.question}
                </h4>
                <input
                  type="text"
                  disabled="disabled"
                  className={classes.answers_item_input}
                ></input>
              </div>
            ) : (
              <div
                className={classes.question_item_saved}
                onMouseLeave={() => toggleHover()}
                onMouseEnter={() => toggleHover('question_item')}
                onClick={() => {
                  dispatch(
                    change('survey', `surveyList.${index}.isSaved`, false)
                  )
                }}
              >
                <h4>
                  {index + 1}. {data.question}
                </h4>
                {data &&
                  data.answers &&
                  data.answers.map((answer, index) => {
                    return (
                      answer && (
                        <Grid key={index} container direction="row">
                          {data.type === 'checkboxes' ? (
                            <CheckBoxOutlineBlank
                              color="disabled"
                              style={{ marginRight: '10px' }}
                            />
                          ) : (
                            <RadioButtonUnchecked
                              color="disabled"
                              style={{ marginRight: '10px' }}
                            ></RadioButtonUnchecked>
                          )}

                          <span>{answer}</span>
                        </Grid>
                      )
                    )
                  })}
              </div>
            )}
          </div>
        )
      })}

      <div className={classes.question_item_btn}>
        <Button
          onMouseLeave={() => toggleHover()}
          onMouseEnter={() => toggleHover('btn_add')}
          variant="contained"
          color="primary"
          onClick={() => {
            fields.push({
              answers: [null, null],
              type: 'multi_choice'
            })
          }}
        >
          <AddCircleOutline style={{ marginRight: '5' }} />
          Add Question
        </Button>
      </div>
    </div>
  )
}

class Survey extends Component {
  state = {
    hover: '',
    topTitle: false,
    topPage: false,
    isCreateDialogOpen: true
  }

  toggleHover = (field) => {
    this.setState({
      hover: field
    })
  }
  toggleDrawer = (side) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }
    this.setState({
      [side]: !this.state[side]
    })
  }

  handleOpenCreateDialog = () => {
    this.setState({
      isCreateDialogOpen: true
    })
  }

  handleCloseCreateDialog = () => {
    this.setState({
      isCreateDialogOpen: false
    })
  }

  render() {
    const { classes, invalid, handleSubmit } = this.props
    const formValues = getFormValues('survey')(store.getState())

    const fullList = (isTitle) => {
      return !isTitle ? (
        <div className={classes.survey_title_drawer} role="presentation">
          <h5>Page title</h5>
          <Field name={'pageTitle'} component={renderTextField} />

          <h5 style={{ marginTop: '10px' }}>Description</h5>
          <EditorField
            key="field"
            name="description"
            id="inputEditorText"
            disabled={false}
          ></EditorField>
          <Grid container direction="row" style={{ marginTop: '20px' }}>
            <Button
              onClick={this.toggleDrawer('topPage')}
              variant="outlined"
              color="primary"
              classes={{
                label: classes.survey_pageTitle_btn_label
              }}
            >
              CANCEL
            </Button>

            <Button
              onClick={this.toggleDrawer('topPage')}
              variant="contained"
              color="primary"
              style={{ marginLeft: '10px' }}
            >
              SAVE
            </Button>
          </Grid>
        </div>
      ) : (
        <div className={classes.survey_title_drawer} role="presentation">
          <h5>Survey title</h5>
          <Field name={'surveyTitle'} component={renderTextField} />
          <Grid container direction="row" style={{ marginTop: '20px' }}>
            <Button
              onClick={this.toggleDrawer('topTitle')}
              variant="outlined"
              color="primary"
              classes={{
                label: classes.survey_pageTitle_btn_label
              }}
            >
              CANCEL
            </Button>

            <Button
              onClick={this.toggleDrawer('topTitle')}
              variant="contained"
              color="primary"
              style={{ marginLeft: '10px' }}
            >
              SAVE
            </Button>
          </Grid>
        </div>
      )
    }

    return (
      <Container>
        <form>
          <div className={classes.createSurveyTitle}>
            <h2>Create Survey</h2>
          </div>

          <div className={classes.surveyContainer}>
            <Field
              name={'surveyTitle'}
              component={renderTextField}
              placeholder="Add survey title"
            />

            <Drawer
              anchor="top"
              open={this.state.topPage}
              onClose={this.toggleDrawer('topPage')}
            >
              {fullList(false)}
            </Drawer>

            <div
              onClick={this.toggleDrawer('topPage')}
              className={classes.survey_title}
              onMouseLeave={() => this.toggleHover()}
              onMouseEnter={() => this.toggleHover('page_title')}
            >
              {formValues.pageTitle ? (
                <h4 className={classes.survey_pageTitle_text}>
                  {formValues.pageTitle}
                </h4>
              ) : (
                <Button
                  variant="outlined"
                  color="secondary"
                  classes={{
                    label: classes.survey_pageTitle_btn_label
                  }}
                >
                  <AddCircleOutline style={{ marginRight: '5' }} />
                  Page Title
                </Button>
              )}
            </div>

            {formValues.description && (
              <div
                onClick={this.toggleDrawer('topPage')}
                className={classes.survey_description}
                onMouseLeave={() => this.toggleHover()}
                onMouseEnter={() => this.toggleHover('page_description')}
              >
                {parse(formValues.description)}

                {this.state.hover === 'page_description' && (
                  <Button
                    className={classes.survey_description_editBtn}
                    style={{ marginRight: '10px' }}
                    variant="contained"
                    color="secondary"
                  >
                    Edit
                  </Button>
                )}
              </div>
            )}

            {/* Question part */}

            <div className={classes.quesList}>
              <FieldArray
                classes={classes}
                dispatch={this.props.dispatch}
                name={'surveyList'}
                component={renderQuestions}
                toggleHover={this.toggleHover}
              ></FieldArray>
            </div>
          </div>
          <div className={classes.nextAndBackBtn}>
            <Button
              variant="contained"
              onClick={() => {
                this.props.setPostState('edit')
                this.props.history.push('/post/create')
              }}
              color="primary"
              className={classes.toolbar_btn}
            >
              BACK
            </Button>

            <Button
              variant="contained"
              color="primary"
              className={classes.toolbar_btn}
              onClick={handleSubmit(() =>
                !invalid
                  ? browserHistory.push('/survey/preview')
                  : browserHistory.push('/survey/create')
              )}
            >
              NEXT
            </Button>
          </div>
        </form>
      </Container>
    )
  }
}
export default withStyles(styles)(Survey)
