import React from 'react'
import { connect } from 'react-redux'
import NavBarComponent from '../../components/layout/navBar.component'

class NavBarContainer extends React.Component {
  render() {
    return <NavBarComponent {...this.props} />
  }
}

const mapStateToProps = (state) => ({
  isUserLoggedIn: state.client.user
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(NavBarContainer)
