//import { createMuiTheme } from '@material-ui/core/styles'
//the createMuiTheme function was renamed to createTheme.
import { createTheme } from '@material-ui/core/styles'
const theme = createTheme({
  typography: {
    useNextVariants: true,
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      'Poppins',
      'National2',
      'Helvetica Neue',
      'Helvetica',
      'Arial',
      'sans-serif'
    ].join(','),
    fontWeightMedium: 500,
    body1: {
      fontWeight: 500
    },
    subtitle1: {
      fontSize: 13
    }
  },

  palette: {
    primary: {
      main: '#E225D7',
      bg: '#EFEFEF',
      lightGray: '#f4f5f5',
      red: '#F05B24',
      green: '#E225D7',
      black: '#212529',
      darkGray: '#333e48',
      subtitle1: '13px'
    },
    secondary: {
      main: '#6B787F'
    },
    background: {
      default: '#fff'
    }
  },
  overrides: {
    // Style sheet name ⚛️
    MuiButton: {
      // Name of the rule
      label: {
        // Some CSS
        textTransform: 'none',
        letterSpacing: 1.3
      }
    },

    MuiFormControl: {
      root: {
        minWidth: '-webkit-fill-available',
        width:'100%'
      }
    },
    MuiFormHelperText: {
      root: {
        //  prevents shifting content above or below field
        marginTop: 0,
        height: 0
      }
    },
    MuiInputBase: {
      root: {
        background: 'white'
      }
    },
    MuiTypography: {
      body1: {
        // fontFamily: 'National2, "Helvetica Neue", Helvetica, Arial, sans-serif',
        // fontWeight: '300',
        fontFamily: `
        'Poppins', sans-serif`
      }
    },
    MuiIconButton: {
      root: {
        padding: '5px'
      }
    },
    MuiPopover: {
      paper: {
        minHeight: '22vh',
        height: '28vh'
      }
    },
    MuiSwitch: {
      switchBase: {
        // Controls default (unchecked) color for the thumb
        /* color: '#ccc'*/
      },
      colorSecondary: {
        '&$checked': {
          // Controls checked color for the thumb
          color: '#E225D7'
        }
      },
      track: {
        // Controls default (unchecked) color for the track
        opacity: 0.2,
        '$checked$checked + &': {
          // Controls checked color for the track
          opacity: 0.7,
          backgroundColor: '#E225D7'
        }
      }
    }
  }
})

export default theme
