import React, { Component } from 'react'
import {
  Button,
  Grid,
  FormControlLabel,
  Radio,
  CircularProgress,
  Container
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import { Field } from 'redux-form'
import parse from 'html-react-parser'
import { renderRadioButtonGroup, renderCheckbox } from '../../../utils/wrappers'

const styles = (theme) => ({
  toolbar_btn: {
    marginRight: '20px',
    [theme.breakpoints.down('xs')]: {
      alignSelf: 'flex-end'
    }
  },
  surveyContainer: {
    marginTop: 1,
    padding: '20px 20px 0 20px',
    background: 'white'
  },
  survey_title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 4px 10px 4px'
  },
  survey_description: {
    position: 'relative',
    padding: '10px 4px',
  },

  question_item_saved: {
    padding: '2vh 4px',
    whiteSpace: 'pre-line'
  },
  question_item_saved_single_text: {
    padding: '2vh 4px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  survey_title_text: {
    fontWeight: '700',
    fontStyle: 'normal',
    textDecoration: 'none',
    color: theme.palette.primary.green,
    lineHeight: '1.25'
  },
  survey_pageTitle_text: {
    fontWeight: '400',
    fontStyle: 'normal',
    textDecoration: 'none',
    color: theme.palette.primary.green,
    lineHeight: '1.25'
  },

  answers_item_input: {
    fontStyle: 'normal',
    fontSize: '18px',
    fontWeight: '300',
    textDecoration: 'none',
    outlineColor: '#00BF6F',
    color: '#333E48',
    backgroundColor: 'rgba(0, 0, 0, 0.10)',
    border: 0,
    width: '50%',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },

  btn_done_container: {
    display: 'flex',
    justifyContent: 'center',
    padding: '30px 0',
    [theme.breakpoints.up('sm')]: {
      alignItems: 'center'
    }
  },

  previewTitle: {
    display: 'flex',
    justifyContent: 'center',
    padding: '20px 0',
    color: '#E225D7',
    textTransform: 'uppercase',
    letterSpacing: '2px'
  },

  questionTitle: {
    width: '40%',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  }
})

class SurveyPreview extends Component {
  
  render() {
    const { classes, formValues, surveyDetail, loading } = this.props

    return (
      <Container>
        <div className={classes.previewTitle}>
          <h2>Preview</h2>
        </div>
        <div className={classes.surveyContainer}>
          <div className={classes.survey_title}>
            <h4 className={classes.survey_title_text}>
              {formValues && formValues.surveyTitle}
            </h4>
          </div>

          <div className={classes.survey_title}>
            {formValues && formValues.pageTitle && (
              <h4 className={classes.survey_pageTitle_text}>
                {formValues.pageTitle}
              </h4>
            )}
          </div>

          {formValues && formValues.description && (
            <div className={classes.survey_description}>
              {parse(formValues.description)}
            </div>
          )}

          {formValues &&
            formValues.surveyList.map((data) => {
              const id = parseInt(data.id)

              switch (data.type) {
                case 'multi_choice':
                  return (
                    data.question && (
                        <div key={id}>
                          <div
                            id={`#question${id}`}
                            className={classes.question_item_saved}
                          >
                            <h4>
                              {id + 1}. {data.question}
                            </h4>
                            <Field
                              name={`question${id}.[]`}
                              component={renderRadioButtonGroup}
                            >
                              {data.answers.map((answer, index) => {
                                return (
                                  answer && (
                                    <FormControlLabel
                                      key={index}
                                      value={`${answer}`}
                                      control={<Radio />}
                                      label={`${answer}`}
                                    />
                                  )
                                )
                              })}
                            </Field>
                          </div>
                        </div>
                    )
                  )

                case 'checkboxes':
                  return (
                     <div key={id}>
                        <div
                          id={`#question${id}`}
                          className={classes.question_item_saved}
                        >
                          <h4>
                            {id + 1}. {data.question}
                          </h4>

                          {data.answers.map((answer, index) => {
                            return (
                              answer && (
                                <Field
                                  key={index}
                                  label={`${answer}`}
                                  name={`question${id}.[]`}
                                  component={renderCheckbox}
                                />
                              )
                            )
                          })}
                        </div>
                      </div>
                  )

                case 'multi_textboxes':
                  return ( 
                      <div key={id}>
                        <div
                          id={`#question${id}`}
                          className={classes.question_item_saved}
                        >
                          <h4>
                            {id + 1}.{data.question}
                          </h4>
                          {data &&
                            data.answers.map((answer, index) => {
                              return (
                                answer && (
                                  <Grid
                                    key={index}
                                    container
                                    alignItems="center"
                                    direction="row"
                                    style={{ marginTop: '10px' }}
                                  >
                                    <span className={classes.questionTitle}>
                                      {answer}
                                    </span>
                                    <Field
                                      className={classes.answers_item_input}
                                      name={`question${id}.[]`}
                                      component="input"
                                      type="text"
                                      disabled
                                    ></Field>
                                  </Grid>
                                )
                              )
                            })}
                        </div>
                      </div>
                  )
                case 'single_textbox':
                  return (
                      <div key={id}>
                        <div
                          id={`#question${id}`}
                          className={classes.question_item_saved_single_text}
                        >
                          <h4>
                            {id + 1}.{data.question}
                          </h4>
                          <Field
                            className={classes.answers_item_input}
                            name={`question${id}.[]`}
                            component="input"
                            type="text"
                            disabled
                          ></Field>
                        </div>
                      </div>
                  )
                default:
                  return ''
              }
            })}
        </div>
        <div className={classes.btn_done_container}>
          <Button
            component={Link}
            to={surveyDetail.ID ? '/survey/detail' : '/survey/create'}
            variant="contained"
            color="primary"
            className={classes.toolbar_btn}
          >
            BACK
          </Button>
          <Button
            disabled={loading}
            onClick={() => {
              if (formValues && formValues.ID) {
                this.props.updateSurvey()
              } else {
                this.props.saveSurvey()
              }
            }}
            variant="contained"
            color="primary"
            className={classes.toolbar_btn}
          >
            {loading ? <CircularProgress size={20} /> : 'SAVE'}
          </Button>
        </div>
      </Container>
    )
  }
}
export default withStyles(styles)(SurveyPreview)
