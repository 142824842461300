import React, { Component } from 'react'
import { EditorState, convertToRaw, ContentState } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import draftToHtml from 'draftjs-to-html'
import { getFormValues } from 'redux-form'
import store from '../../../../store'
import htmlToDraft from 'html-to-draftjs'

import { unemojify } from 'node-emoji'

export default class ControlledEditor extends Component {
  constructor(props) {
    super(props)
    this.state = {
      editorState: EditorState.createEmpty()
    }
  }

  onEditorStateChange = (editorState) => {
    const { onChange, value } = this.props

    const newValue = unemojify(
      draftToHtml(convertToRaw(editorState.getCurrentContent()))
    )

    if (value !== newValue) {
      onChange(newValue)
    }

    this.setState({
      editorState
    })
  }

  componentDidMount() {
    let formValues = getFormValues('post')(store.getState())

    const contentBlock =
      formValues.description && htmlToDraft(formValues.description)
    const contentState =
      contentBlock &&
      ContentState.createFromBlockArray(contentBlock.contentBlocks)
    const editorState =
      contentState && EditorState.createWithContent(contentState)

    if (editorState) {
      this.setState({
        editorState
      })
    } else {
      return
    }
  }

  render() {
    const { editorState } = this.state

    return (
      <div>
        <Editor
          //toolbarOnFocus
          editorState={editorState}
          placeholder="Description*"
          toolbarClassName="toolbar-class"
          wrapperClassName="demo-wrapper"
          editorClassName="demo-editor"
          onEditorStateChange={this.onEditorStateChange}
          toolbar={{
            options: [
              'inline',
              'blockType',
              'fontSize',
              'fontFamily',
              'list',
              'textAlign',
              'colorPicker',
              'remove',
              'history'
            ],
            inline: { inDropdown: true },
            textAlign: { inDropdown: true }
          }}
        />
      </div>
    )
  }
}
