import { call, put, takeEvery } from 'redux-saga/effects'
import {
  GET_ALL_SURVEYS,
  VPT_API_SERVER,
  GET_SURVEY_DETAILS_BY_ID,
  SAVE_SURVEY,
  UPDATE_SURVEY,
  DELETE_SURVEY,
  APPLICANT_SUBMIT_SURVEY,
  UPDATE_SURVEY_STATUS,
  DELETE_OTHER_FILES,
  DELETE_CV
} from '../constants'
import {
  getAllSurveysSuccess,
  getSurveyDetailsByPostIdSuccess,
  getAllSurveys,
  asyncActionStart,
  asyncActionFinish,
  asyncActionError,
  clearUploadedOtherFiles,
  clearUploadedCV,
  copyPostDetailSucess,
  getPostDetailsById
} from '../actions'
import { apiManualPost, normalPost } from '../utils/request'
import store from '../store'
import browserHistory from '../history'
import { change, getFormValues, initialize } from 'redux-form'

function* getAllSurveysSaga() {
  try {
    yield put(asyncActionStart('getAllSurveys'))
    const url = `${VPT_API_SERVER}/GetSurveys`
    const uuid = store.getState().client.user.data[2]

    const body = JSON.stringify({
      UUID: uuid
    })

    const result = yield call(apiManualPost, url, body)

    if (result.data.success) {
      yield put(getAllSurveysSuccess(JSON.parse(result.data.data)))
      yield put(asyncActionFinish('getAllSurveys'))
    } else {
      console.log('Hi, err getting all surveys!: ', result.data.message)
      yield put(asyncActionError('getAllSurveys'))
    }
  } catch (e) {
    yield put(asyncActionError('getAllSurveys'))
    console.log('oops, an error: ', e)
    console.warn(e)
  }
}

function* getSurveyDetailsByPostIdSaga({ id, routeDes }) {
  try {
    const url = `${VPT_API_SERVER}/GetSurveyByPostID`

    const { ID } = store.getState().post.postDetail

    const { copiedPostId, postState } = store.getState().post

    const body = JSON.stringify({
      PostID: postState === 'copy' ? copiedPostId : ID
    })

    const result = yield call(normalPost, url, body)

    if (result.data && result.data.success && result.data.data.length > 0) {
      const data = JSON.parse(result.data.data)[0]

      const newData = {
        ...data,
        Questions: data.Questions.map((item, index) => {
          if (item && item.answers) {
            const itemArr = item.answers.split(',')

            const newItemArr = itemArr.map((item) => {
              return item && item.replace(/\u1234/g, ',')
            })

            return {
              ...item,
              answers: newItemArr
            }
          }

          return item
        })
      }
      if (postState === 'copy') {
        yield put(
          initialize('survey', {
            surveyTitle: newData.SurveyTitle,
            surveyList: newData.Questions
          })
        )
        yield put(copyPostDetailSucess())
        yield put(getPostDetailsById(postState === 'copy' ? ID : copiedPostId))
      } else yield put(getSurveyDetailsByPostIdSuccess(newData))
      if (routeDes === 'detail') {
        browserHistory.push('/survey/detail')
      } else if (routeDes === 'getLink') {
        browserHistory.push('/survey/collect')
      } else if (routeDes === 'create') {
        browserHistory.push('/survey/create')
      }
    } else if (result.data && result.data.success && !result.data.data.length) {
      browserHistory.push('/survey/create')
    } else {
      console.log('Hi, err getting sruvey by id!: ', result.data.message)
    }
  } catch (e) {
    console.warn(e)
  }
}

function* saveSurveySaga() {
  try {
    yield put(asyncActionStart('saveSurvey'))
    const url = `${VPT_API_SERVER}/AddSurveys`
    const surveyForm = getFormValues('survey')(store.getState())
    const { surveyList } = surveyForm
    const newQuestions = surveyList.map((item, index) => {
      const encodedCommaAnswers = item.answers.map((item) => {
        return item && item.replace(/,/g, '\u1234')
      })

      return {
        Value: item.question,
        Type: item.type,
        Answers: encodedCommaAnswers
      }
    })

    const uuid = store.getState().client.user.data[2]
    const postID = store.getState().post.postDetail.ID

    const newBody = {
      PostID: postID,
      UUID: uuid,
      SurveyTitle: surveyForm.surveyTitle,
      PageTitle: (surveyForm && surveyForm.pageTitle) || null,
      Description: (surveyForm && surveyForm.description) || null,
      Questions: newQuestions
    }

    const body = JSON.stringify(newBody)

    const result = yield call(apiManualPost, url, body)

    if (result.data.success) {
      const data = JSON.parse(result.data.data)[0]
      yield put(asyncActionFinish('saveSurvey'))

      yield put(getSurveyDetailsByPostIdSuccess(data))

      browserHistory.push('/survey/collect')

      //yield put(getAllSurveysSuccess(JSON.parse(result.data.data)))
    } else {
      console.log('Hi, err saving survey!: ', result.data.message)
      yield put(asyncActionFinish('saveSurvey'))
    }
  } catch (e) {
    console.log('oops, an error: ', e)
    console.warn(e)
    yield put(asyncActionFinish('saveSurvey'))
  }
}

function* updateSurveySaga() {
  try {
    const url = `${VPT_API_SERVER}/UpdateSurveys`
    const surveyForm = getFormValues('surveyDetail')(store.getState())

    const { surveyList } = surveyForm

    const newQuestions = surveyList.map((item, index) => {
      const encodedCommaAnswers = item.answers.map((item) => {
        return item && item.replace(/,/g, '\u1234')
      })

      return {
        QuestionID:item.QuestionID?item.QuestionID:null,
        Value: item.question,
        Type: item.type,
        Answers: encodedCommaAnswers,
        surveyId: surveyForm.ID
      }
    })
    const uuid = store.getState().client.user.data[2]

    const newBody = {
      ID: surveyForm.ID,
      Status: surveyForm.status,
      UUID: uuid,
      SurveyTitle: surveyForm.surveyTitle,
      PageTitle: (surveyForm && surveyForm.pageTitle) || null,
      Description: (surveyForm && surveyForm.description) || null,
      Questions: newQuestions
    }

    const body = JSON.stringify(newBody)

    const result = yield call(apiManualPost, url, body)

    if (result.data.success) {
      browserHistory.push('/survey/collect')
      //yield put(getAllSurveysSuccess(JSON.parse(result.data.data)))
    } else {
      console.log('Hi, err updating survey!: ', result.data.message)
    }
  } catch (e) {
    console.log('oops, an error: ', e)
    console.warn(e)
  }
}

function* deleteSurveySaga({ id }) {
  try {
    const url = `${VPT_API_SERVER}/DeleteSurveys`

    const uuid = store.getState().client.user.data[2]

    const newBody = {
      ID: id,
      UUID: uuid
    }

    const body = JSON.stringify(newBody)

    const result = yield call(apiManualPost, url, body)

    if (result.data.success) {
      yield put(getAllSurveys())

      //yield put(getAllSurveysSuccess(JSON.parse(result.data.data)))
    } else {
      console.log('Hi, err deleting survey!: ', result.data.message)
    }
  } catch (e) {
    console.log('oops, an error: ', e)
    console.warn(e)
  }
}

function* deleteCVSaga() {
  try {
    yield put(change('surveyDone', 'cv_document', null))
  } catch (error) {
    console.warn('errorCv', error)
  }
}
function* deleteOtherFilesSaga() {
  try {
    yield put(change('surveyDone', 'other_documents', null))
  } catch (error) {
    console.warn('errorOtherDcouments', error)
  }
}

function* applicantSubmitSurveySaga({ result }) {
  try {
    yield put(asyncActionStart('saveApplicantProfileInfos'))
    yield put(asyncActionStart('saveApplicantDocData'))
    yield put(asyncActionStart('applicantSendSurvey'))

    const url = `${VPT_API_SERVER}/SendEmail`

    const urlApplicantProfileInfos = `${VPT_API_SERVER}/SaveApplicantProfileInfos`
    const urlApplicantDocData = `${VPT_API_SERVER}/SaveApplicantDocData`
    const urlApplicantAnswer= `${VPT_API_SERVER}/SaveApplicantAnswers`
    const answerForm = getFormValues('surveyAnswer')(store.getState())
    const surveyDoneForm = getFormValues('surveyDone')(store.getState())
    const other_documents = surveyDoneForm && surveyDoneForm.other_documents
    const cv_document = surveyDoneForm && surveyDoneForm.cv_document
    const linkedin = surveyDoneForm && surveyDoneForm.linkedin
    const applicant_first_name =
      surveyDoneForm && surveyDoneForm.applicant_first_name
    const applicant_last_name =
      surveyDoneForm && surveyDoneForm.applicant_last_name
    const applicant_email = surveyDoneForm && surveyDoneForm.applicant_email
    const applicant_phone = surveyDoneForm && surveyDoneForm.applicant_phone
    const portfolio = surveyDoneForm && surveyDoneForm.portfolio
    const { uploadedDocument } = store.getState().survey
    const { uploadedDoc } = store.getState().survey
    const client_email = store.getState().client?.user?.data?.[1]

    //TL-22
    let arr = []
    if (answerForm) {
      const _arr = Object.keys(answerForm).map((k) => answerForm[k])
      arr = _arr
    }

    let answers = arr.map((item, index) => {
      if (item.type === 'multi_choice' || item.type === 'single_textbox') {
        return {
          ...item,
          answer: [item.answer]
        }
      } else if (item.type === 'multi_textboxes') {
        let { type, question, ...restAnswers } = item

        let answerOfInput = Object.keys(restAnswers).map((key) => {
          return {
            [`${key}`]: restAnswers[key]
          }
        })

        return {
          type,
          question,
          answerOfInput
        }
      } else if (item.type === 'checkboxes') {
        let { type, question, ...restAnswers } = item

        let answer = Object.keys(restAnswers)
          .map((key) => {
            if (restAnswers[key] === true) {
              return `${key}`
            }

            return null
          })
          .filter((item) => item != null)

        return {
          type,
          question,
          answer
        }
      }

      return item
    })

    //TL-22
    let email = client_email
    let title = null
    let ID = null

    if (
      store.getState().survey.surveyDetail &&
      Object.keys(store.getState().survey.surveyDetail).length > 0
    ) {
      title = store.getState().survey.surveyDetail.SurveyTitle
      ID = store.getState().survey.surveyDetail.ID
    }

    //for job title
    const jobPostTitle = store.getState().post.postDetail.Title
    const postID = store.getState().post.postDetail.ID
    const newBody = {
      jobPostTitle,
      ID,
      title,
      FirstName: applicant_first_name,
      LastName: applicant_last_name,
      phone: applicant_phone,
      applicant_email: applicant_email,
      email,
      answers: answers,
      otherDocuments: other_documents && other_documents,
      cvDocument: cv_document && cv_document,
      linkedin: linkedin,
      fileName: uploadedDocument[0] && uploadedDocument[0].name,
      otherFileName: uploadedDoc[0] && uploadedDoc[0].name,
      portfolio: portfolio,
      postID: postID
    }

    const bodyProfileInfosToSave = {
      FirstName: applicant_first_name,
      LastName: applicant_last_name,
      email: applicant_email,
      phone: applicant_phone,
      linkedin: linkedin,
      portfolio: portfolio
    }

    const bodyDocDataToSave = {
      postID: postID,
      SurveyID: ID,
      otherDocuments: other_documents && other_documents,
      cvDocuments: cv_document && cv_document,
      FileName: uploadedDocument[0] && uploadedDocument[0].name,
      otherFileName: uploadedDoc[0] && uploadedDoc[0].name,
      email: applicant_email
    }
    let applicantAnswers = arr.map((item) => {
      if ( item.type === 'single_textbox') {
        return {
          questionId:item.questionID,
          value: [item.answer]
        }
      }
      else if (item.type === 'multi_textboxes'||item.type === 'multi_choice') {
        let { questionID,type,question, ...restAnswers } = item
        let answerOfInput = Object.keys(restAnswers).map((key) => {
          if (key !== type||key !== question)  {
            return restAnswers[key]
          }
          return null
        })
        return {
          questionId:questionID,
          value:answerOfInput
        }
      }
      else if (item.type === 'checkboxes') {
        let { questionID, ...restAnswers } = item
        console.log(restAnswers)
        let answer = Object.keys(restAnswers)
          .map((key) => {
            if (restAnswers[key] === true) {
              return `${key}`
            }
            return null
          })
          .filter((item) => item != null)

        return {
          questionId:questionID,
          value:answer
        }
      }
      return item
    })

    const answerToSend={
      applicantEmail:applicant_email,
      answers:applicantAnswers
    }
    const body = JSON.stringify(newBody)
    const bodyAppProfileInfosToSave = JSON.stringify(bodyProfileInfosToSave)
    const bodyAppDocDataToSave = JSON.stringify(bodyDocDataToSave)
    const bodyApplicantAnswers= JSON.stringify(answerToSend)
    const result = yield call(normalPost, url, body)

    const docAppProfInfostoSave = yield call(
      normalPost,
      urlApplicantProfileInfos,
      bodyAppProfileInfosToSave
    )

    const docAppDocDataToSave = yield call(
      normalPost,
      urlApplicantDocData,
      bodyAppDocDataToSave
    )
    const applicantAnswersToSave = yield call(
      normalPost,
      urlApplicantAnswer,
      bodyApplicantAnswers
    )
    const sendEmailUrl = `${VPT_API_SERVER}/SendAutomaticReply`
    const emailBody = JSON.stringify({
      ID: postID,
      postID: postID,
      receiver: applicant_email
    })
   console.log(applicantAnswersToSave)
    if (
      docAppProfInfostoSave.data.success &&
      docAppDocDataToSave.data.success &&
      result.data.success
    ) {
      yield put(asyncActionFinish('applicantSendSurvey'))
      yield put(asyncActionStart('isShowSnackbarSendApplicantSuccess'))
      yield put(asyncActionFinish('saveApplicantProfileInfos'))
      yield put(
        asyncActionStart('isShowSnackbarSaveApplicantProfileInfosSuccess')
      )
      yield put(asyncActionFinish('saveApplicantDocData'))
      yield put(asyncActionStart('isShowSnackbarSaveApplicantDocDataSuccess'))
      yield put(asyncActionStart('isDisabledSendButton'))
      yield put(clearUploadedCV())
      yield put(clearUploadedOtherFiles())
      yield call(normalPost, sendEmailUrl, emailBody)
      /* yield call(normalPost,urlApplicantAnswer,bodyApplicantAnswers) */
      //yield put(getAllSurveysSuccess(JSON.parse(result.data.data)))
    } else {
      console.log('Hi, err submiting survey!: ', result.data.message)
      yield put(asyncActionError('applicantSendSurvey'))
      yield put(asyncActionStart('isShowSnackbarSendApplicantFalse'))
      yield put(asyncActionStart('isShowSnackbarSaveApplicantError'))
      yield put(asyncActionError('surveyDoneValidate'))
    }
  } catch (e) {
    console.log('oops, an error: ', e)
    yield put(asyncActionError('applicantSendSurvey'))
    yield put(asyncActionStart('isShowSnackbarSendApplicantFalse'))
    yield put(asyncActionStart('isShowSnackbarSaveApplicantProfileInfosError'))
    yield put(asyncActionStart('isShowSnackbarSaveApplicantDocDataError'))

    console.warn(e)
  }
}

function* updateSurveySatusSaga({ id }) {
  try {
    const url = `${VPT_API_SERVER}/UpdateSurveyStatus`

    //const uuid = store.getState().client.user.data[2]

    const newBody = {
      ID: id
    }

    const body = JSON.stringify(newBody)

    const result = yield call(apiManualPost, url, body)

    if (result.data.success) {
      yield put(getAllSurveys())

      //yield put(getAllSurveysSuccess(JSON.parse(result.data.data)))
    } else {
      console.log('Hi, err updating suvey status!: ', result.data.message)
    }
  } catch (e) {
    console.log('oops, an error: ', e)
    console.warn(e)
  }
}

export function* watchGetAllSurveys() {
  yield takeEvery(GET_ALL_SURVEYS, getAllSurveysSaga)
}

export function* watchGetSurveyDetailsById() {
  yield takeEvery(GET_SURVEY_DETAILS_BY_ID, getSurveyDetailsByPostIdSaga)
}

export function* watchSaveSurvey() {
  yield takeEvery(SAVE_SURVEY, saveSurveySaga)
}

export function* watchUpdateSurveys() {
  yield takeEvery(UPDATE_SURVEY, updateSurveySaga)
}

export function* watchDeleteSurveys() {
  yield takeEvery(DELETE_SURVEY, deleteSurveySaga)
}

export function* watchApplicantSubmitSurvey() {
  yield takeEvery(APPLICANT_SUBMIT_SURVEY, applicantSubmitSurveySaga)
}

export function* watchUpdateSurveyStatusSurvey() {
  yield takeEvery(UPDATE_SURVEY_STATUS, updateSurveySatusSaga)
}
export function* watchDeleteCVSaga() {
  yield takeEvery(DELETE_CV, deleteCVSaga)
}
export function* watchDeleteOtherFilesSaga() {
  yield takeEvery(DELETE_OTHER_FILES, deleteOtherFilesSaga)
}
