import store from '../store'

const getManualAuthHeaders = () => {
  const headers = new Headers()

  headers.append('Accept', 'application/json')
  headers.append(
    'Authorization',
    `Bearer ${store.getState().client.user.data[0].access_token}`
  )

  return headers
}

export const apiManualPost = (url, body, method = 'POST') => {
  const headers = getManualAuthHeaders()
  headers.append('Content-Type', 'application/json')

  const options = {
    method,
    headers,
    body
  }

  return fetch(url, options)
    .then((res) => res.json())
    .then((data) => ({ data }))
    .catch((error) => ({ error }))
}

export const apiManualRequest = (url, method = 'GET') => {
  const headers = getManualAuthHeaders()
  const options = {
    method,
    headers
  }

  return fetch(url, options)
    .then((res) => res.json())
    .then((data) => ({ data }))
    .catch((error) => ({ error }))
}

export const normalPost = (url, body, method = 'POST') => {
  const headers = new Headers()
  headers.append('Content-Type', 'application/json')
  const options = {
    method,
    headers,
    body
  }
  return fetch(url, options)
    .then((res) => res.json())
    .then((data) => ({ data }))
    .catch((error) => ({ error }))
}

export const registerPost = (url, body, method = 'POST') => {
  const headers = new Headers()
  headers.append('Content-Type', 'application/json')
  const options = {
    method,
    headers,
    body
  }
  return fetch(url, options)
    .then((res) => res.json())
    .then((data) => ({ data }))
    .catch((error) => ({ error }))
}

export const loginPost = (url, body, method = 'POST') => {
  const headers = new Headers()
  headers.append('Content-Type', 'application/json')
  const options = {
    method,
    headers,
    body
  }
  return fetch(url, options)
    .then((res) => res.json())
    .then((data) => ({ data }))
    .catch((error) => ({ error }))
}
