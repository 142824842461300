import React from 'react'
import ReactDOM from 'react-dom'
import './styles/index.css'

import RootComponent from './root'

ReactDOM.render(
  <RootComponent />,

  document.getElementById('root')
)
