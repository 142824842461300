import { connect } from 'react-redux'
import { setPostDetail, setPostState } from '../../../actions'
import SurveyCreateComponent from '../../../components/survey/company/SurveyCreate.component'
import { reduxForm } from 'redux-form'
import { surveyValidate as validate } from '../../validate'

const mapStateToProps = (state) => {
  let initialValues = {
    surveyTitle: '',
    surveyList: [
      {
        answers: [null, null],
        type: 'multi_choice'
      }
    ]
  }

  return {
    state,
    initialValues
  }
}

const actions = (dispatch) => {
  return {
    dispatch,
    setPostDetail: (post) => dispatch(setPostDetail(post)),
    setPostState: (postState) => dispatch(setPostState(postState))
  }
}

const SurveyCreateForm = reduxForm({
  form: 'survey',
  destroyOnUnmount: false,
  enableReinitialize: false,
  validate
})(SurveyCreateComponent)

const SurveyCreateContainer = connect(
  mapStateToProps,
  actions
)(SurveyCreateForm)

export default SurveyCreateContainer
