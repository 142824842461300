import {
  ASYNC_ACTION_ERROR,
  ASYNC_ACTION_FINISH,
  ASYNC_ACTION_START
} from '../constants'

const initialState = {
  getAllSurveys: {
    loading: false
  },
  applicantSendSurvey: {
    loading: false
  },
  showCreateSurvey: {
    loading: false
  },
  getAllPosts: {
    loading: false
  },
  getPostDetailsById: {
    loading: false
  },
  savePost: {
    loading: false
  },
  saveSurvey: {
    loading: false
  },
  sendCv: {
    loading: false
  },
  isShowSnackbarSendApplicantSuccess: {
    loading: false
  },
  isShowSnackbarSendApplicantFalse: {
    loading: false
  },
  isDisabledSendButton: {
    loading: false
  },
  isShowSnackbarFileMaximunSize: {
    loading: false
  },
  isShowAllowedFileTypes: {
    loading: false
  },
  getAllApplicants: {
    loading: false
  },
  handleSearch: {
    loading: false
  },
  getApplicantDocDataByID: {
    loading: false
  },
  saveApplicantData: {
    loading: false
  },

  saveApplicantProfileInfos: {
    loading: false
  },
  isShowSnackbarSaveApplicantSuccess: {
    loading: false
  },
  isShowSnackbarSaveApplicantProfileInfosSuccess: {
    loading: false
  },
  isShowSnackbarSaveApplicantProfileInfosError: {
    loading: false
  },
  isShowSnackbarSaveApplicantDocDataSuccess: {
    loading: false
  },
  isShowSnackbarSaveApplicantDocDataError: {
    loading: false
  },
  isShowSnackbarSaveApplicantError: {
    loading: false
  },
  fetchApplicants: {
    loading: false
  },
  resetFilter: {
    loading: false
  }
}

const async = (state = initialState, action) => {
  switch (action.type) {
    case ASYNC_ACTION_START:
      return {
        ...state,
        [action.name]: { loading: true }
      }

    case ASYNC_ACTION_FINISH:
      return {
        ...state,
        [action.name]: { loading: false }
      }

    case ASYNC_ACTION_ERROR:
      return {
        ...state,
        [action.name]: { loading: false }
      }

    default:
      return state
  }
}

export default async
