import { reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import AutomaticEmailAnswers from '../../../components/survey/company/automaticEmailAnswers.component'
import {} from '../../../actions'

const AutomaticEmailAnswersContainer = reduxForm({
  form: 'post'
})(AutomaticEmailAnswers)

const mapStateToProps = (state) => {
  return {
    automaticEmailToggleBtn: state.post.automaticEmailToggleBtn,
    formValues: state.form.post,
    isSendThankyou: state.post.postDetail.send_thankyou_email
  }
}
const actions = (dispatch) => {
  return {
    dispatch
  }
}
export default connect(mapStateToProps, actions)(AutomaticEmailAnswersContainer)
