import {
  SIGNUP_FORM_SUBMIT,
  SIGNUP_FORM_SUBMIT_SUCCESS,
  SIGNUP_FORM_SUBMIT_USER_EXIST_ERROR,
  SIGNUP_FORM_SUBMIT_FAILED,
  CLOSE_SIGNUP_SNACKBAR,
  SIGNIN_FORM_SUBMIT,
  SIGNIN_FORM_SUBMIT_SUCCESS,
  SIGNIN_FORM_SUBMIT_FAILED,
  CLOSE_SIGNIN_SNACKBAR,
  CLIENT_SET,
  CLIENT_UNSET,
  LOGOUT,
  RETRIEVE_IMAGE_INFO,
  RETRIEVE_LOGO_INFO,
  CLEAR_IMAGES,
  GET_ALL_SURVEYS,
  GET_ALL_SURVEYS_SUCCESS,
  GET_SURVEY_DETAILS_BY_ID,
  GET_SURVEY_DETAILS_BY_ID_SUCCESS,
  UNSET_SURVEY_DETAILS,
  SAVE_SURVEY,
  SAVE_SURVEY_SUCCESS,
  UPDATE_SURVEY,
  UPDATE_SURVEY_SUCCESS,
  DELETE_SURVEY,
  DELETE_SURVEY_SUCCESS,
  APPLICANT_SUBMIT_SURVEY,
  APPLICANT_SUBMIT_SURVEY_SUCCESS,
  ASYNC_ACTION_START,
  ASYNC_ACTION_FINISH,
  ASYNC_ACTION_ERROR,
  UPDATE_SURVEY_STATUS,
  UPDATE_SURVEY_STATUS_SUCCESS,
  GET_ALL_POSTS,
  GET_ALL_POSTS_SUCCESS,
  GET_POST_DETAILS_BY_ID,
  GET_POST_DETAILS_BY_ID_SUCCESS,
  SAVE_POST,
  SAVE_POST_SUCCESS,
  UPDATE_POST,
  UPDATE_POST_SUCCESS,
  CLEAR_POST_DETAIL,
  DELETE_POST,
  UPDATE_POST_ACTIVE,
  UPDATE_POST_ACTIVE_SUCCESS,
  SET_POSTS_DETAIL,
  POPULATE_AUTO_MESSAGE,
  POPULATE_AUTO_MESSAGE_SUCCESS,
  RETRIEVE_DOCUMENT_INFO,
  RETRIEVE_OTHER_FILES,
  DELETE_CV,
  DELETE_OTHER_FILES,
  COPY_POSTS_DETAIL,
  COPY_POSTS_DETAIL_SUCCESS,
  DELETE_FILES,
  AUTO_EMAIL_TO_APPLICANT,

  //search applicants
  FETCH_APPLICANTS,
  FETCH_APPLICANTS_SUCCESS,
  FETCH_APPLICANTS_ERROR,
  HANDLE_SEARCH,
  GET_APPLICANT_DOC_DATA_BY_ID,
  GET_APPLICANT_DOC_DATA_BY_ID_SUCCESS,
  GET_APPLICANT_DOC_DATA_BY_ID_ERROR,
  SAVE_APPLICANT_DOC_DATA,
  SAVE_APPLICANT_DOC_DATA_SUCCESS,
  SAVE_APPLICANT_DOC_DATA_ERROR,
  SAVE_APPLICANT_PROFILE_INFOS,
  SAVE_APPLICANT_PROFILE_INFOS_SUCCESS,
  SAVE_APPLICANT_PROFILE_INFOS_ERROR,
  RESET_FILTER,
  SET_DROPDOWN_OPEN,
  SET_POST_STATE
} from '../constants'

// DELETE FILES
export const deleteFiles = (formName) => ({ type: DELETE_FILES, formName })

//REGISTER USER
export const signUpFormSubmit = () => ({ type: SIGNUP_FORM_SUBMIT })
export const signUpFormSubmitSuccess = (result) => ({
  type: SIGNUP_FORM_SUBMIT_SUCCESS,
  result
})
export const signUpFormSubmitUserExistError = (error) => ({
  type: SIGNUP_FORM_SUBMIT_USER_EXIST_ERROR,
  error
})
export const signUpFormSubmitFailed = (error) => ({
  type: SIGNUP_FORM_SUBMIT_FAILED,
  error
})
export const closeRegisterSnackbar = () => ({
  type: CLOSE_SIGNUP_SNACKBAR
})

//SIGNIN USER
export const signInFormSubmit = () => ({ type: SIGNIN_FORM_SUBMIT })
export const signInFormSubmitSuccess = (result) => ({
  type: SIGNIN_FORM_SUBMIT_SUCCESS,
  result
})
export const signInFormSubmitFailed = (error) => ({
  type: SIGNIN_FORM_SUBMIT_FAILED,
  error
})
export const closeSignInSnackBar = () => ({ type: CLOSE_SIGNIN_SNACKBAR })
export const setClient = (token) => ({ type: CLIENT_SET, token })
export const unsetClient = () => ({ type: CLIENT_UNSET })
export const logout = () => ({ type: LOGOUT })

export const asyncActionStart = (name) => ({ type: ASYNC_ACTION_START, name })
export const asyncActionFinish = (name) => ({ type: ASYNC_ACTION_FINISH, name })
export const asyncActionError = (name) => ({ type: ASYNC_ACTION_ERROR, name })

export const retrieveImageInfo = (image) => ({
  type: RETRIEVE_IMAGE_INFO,
  image: {
    name: image.name,
    type: image.type,
    id: 0
  }
})
export const retrieveLogoInfo = (image) => ({
  type: RETRIEVE_LOGO_INFO,
  image: {
    name: image.name,
    type: image.type,
    id: 0
  }
})
export const clearImagesFromState = () => ({ type: CLEAR_IMAGES })

//SURVEYS
export const getAllSurveys = () => ({ type: GET_ALL_SURVEYS })
export const getAllSurveysSuccess = (result) => ({
  type: GET_ALL_SURVEYS_SUCCESS,
  result
})

export const getSurveyDetailsByPostId = (id, routeDes) => ({
  type: GET_SURVEY_DETAILS_BY_ID,
  id,
  routeDes
})
export const getSurveyDetailsByPostIdSuccess = (result) => ({
  type: GET_SURVEY_DETAILS_BY_ID_SUCCESS,
  result
})
export const autoEmailToApplicant = () => ({
  type: AUTO_EMAIL_TO_APPLICANT
})

export const unsetSurveyDetails = () => ({ type: UNSET_SURVEY_DETAILS })

export const saveSurvey = () => ({ type: SAVE_SURVEY })
export const saveSurveySuccess = (result) => ({
  type: SAVE_SURVEY_SUCCESS,
  result
})

export const updateSurvey = () => ({ type: UPDATE_SURVEY })
export const updateSurveySuccess = (result) => ({
  type: UPDATE_SURVEY_SUCCESS,
  result
})

export const deleteSurvey = (id) => ({ type: DELETE_SURVEY, id })
export const deleteSurveySuccess = (result) => ({
  type: DELETE_SURVEY_SUCCESS,
  result
})

export const applicantSubmitSurvey = () => ({ type: APPLICANT_SUBMIT_SURVEY })
export const applicantSubmitSurveySuccess = (result) => ({
  type: APPLICANT_SUBMIT_SURVEY_SUCCESS,
  result
})

export const updateSurveyStatus = (id) => ({ type: UPDATE_SURVEY_STATUS, id })

export const updateSurveyStatusSuccess = (result) => ({
  type: UPDATE_SURVEY_STATUS_SUCCESS,
  result
})

export const retrieveDocumentInfo = (document) => ({
  type: RETRIEVE_DOCUMENT_INFO,
  document: {
    name: document.name,
    type: document.type,
    id: 0,
    size: document.size
  }
})

export const retrieveOtherFilesInfo = (otherFiles) => ({
  type: RETRIEVE_OTHER_FILES,
  document: {
    name: otherFiles.name,
    type: otherFiles.type,
    id: 0,
    size: otherFiles.size
  }
})

export const clearUploadedCV = () => ({ type: DELETE_CV })

export const clearUploadedOtherFiles = () => ({ type: DELETE_OTHER_FILES })

// POSTS

export const getAllPosts = () => ({ type: GET_ALL_POSTS })
export const getAllPostsSuccess = (result) => ({
  type: GET_ALL_POSTS_SUCCESS,
  result
})

export const getPostDetailsById = (postId) => ({
  type: GET_POST_DETAILS_BY_ID,
  postId
})

export const getPostDetailsByIdSuccess = (result) => ({
  type: GET_POST_DETAILS_BY_ID_SUCCESS,
  result
})
// POPULATE Message

export const populateAutoMessage = (id) => ({
  type: POPULATE_AUTO_MESSAGE,
  id
})
export const populateAutoMessageBySuccess = (result) => ({
  type: POPULATE_AUTO_MESSAGE_SUCCESS,
  result
})
export const savePost = () => ({ type: SAVE_POST })
export const savePostSuccess = (result) => ({
  type: SAVE_POST_SUCCESS,
  result
})
export const deletePost = (id) => ({ type: DELETE_POST, id })
export const deletePostSuccess = (result) => ({
  type: SAVE_POST_SUCCESS,
  result
})

export const updatePost = (isChangeImg) => ({ type: UPDATE_POST, isChangeImg })
export const updatePostSuccess = (result) => ({
  type: UPDATE_POST_SUCCESS,
  result
})

export const updatePostActive = (postId) => ({
  type: UPDATE_POST_ACTIVE,
  postId
})
export const updatePostActiveSuccess = (result) => ({
  type: UPDATE_POST_ACTIVE_SUCCESS,
  result
})

export const setPostDetail = (post) => ({ type: SET_POSTS_DETAIL, post })
export const clearPostDetail = () => ({ type: CLEAR_POST_DETAIL })

export const copyPostDetail = (post) => ({ type: COPY_POSTS_DETAIL, post })

export const copyPostDetailSucess = () => ({ type: COPY_POSTS_DETAIL_SUCCESS })

export const setPostState = (postState) => ({
  type: SET_POST_STATE,
  postState
})

// SEARCH APPLICANTS

export const handleSearch = () => ({
  type: HANDLE_SEARCH
})

export const fetchApplicants = () => ({
  type: FETCH_APPLICANTS
})

export const fetchApplicantsSuccess = (applicants) => ({
  type: FETCH_APPLICANTS_SUCCESS,
  payload: applicants
})

export const fetchApplicantsError = (error) => ({
  type: FETCH_APPLICANTS_ERROR,
  payload: error
})

export const getApplicantDocDataByID = (applicantID,postID) => ({
  type: GET_APPLICANT_DOC_DATA_BY_ID,
  applicantID,
  postID
})

export const getApplicantDocDataByIDSuccess = (result) => ({
  type: GET_APPLICANT_DOC_DATA_BY_ID_SUCCESS,
  payload: result
})

export const getApplicantDocDataByIDError = (error) => ({
  type: GET_APPLICANT_DOC_DATA_BY_ID_ERROR,
  error
})

export const saveApplicantDocData = () => ({
  type: SAVE_APPLICANT_DOC_DATA
})

export const saveApplicantDocDataSuccess = (result) => ({
  type: SAVE_APPLICANT_DOC_DATA_SUCCESS,
  result
})

export const saveApplicantDocDataError = (error) => ({
  type: SAVE_APPLICANT_DOC_DATA_ERROR,
  error
})

export const saveApplicantProfileInfos = () => ({
  type: SAVE_APPLICANT_PROFILE_INFOS
})

export const saveApplicantProfileInfosSuccess = (result) => ({
  type: SAVE_APPLICANT_PROFILE_INFOS_SUCCESS,
  result
})

export const saveApplicantProfileInfosError = (error) => ({
  type: SAVE_APPLICANT_PROFILE_INFOS_ERROR,
  error
})

export const resetFilter = () => ({
  type: RESET_FILTER
})

export const setDropdownOpen = (isOpen) => ({ type: SET_DROPDOWN_OPEN, payload: isOpen })