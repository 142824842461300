import React from 'react'
import ControlledEditor from './ControlledEditor'

const EditorFieldComponent = (props) => {
  const {
    placeholder,
    input: { onChange, value },
    disabled,
    id,
    meta,
    ...restProps
  } = props

  return (
    <div>
      <ControlledEditor
        id={id}
        disabled={disabled}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        {...restProps}
      />
      {meta.submitFailed && meta.invalid ? (
        <p style={{ color: 'red', fontSize: '12px', marginLeft: '4 rem' }}>
          {meta.error}
        </p>
      ) : null}
    </div>
  )
}

export default EditorFieldComponent
