import React from 'react'


const HtmlTemp = ({
  SurveyTitle,
  Title,
  Questions,
  className,
  FirstName,
  LastName,
  Email
}) => {

  return (
    <>
      <header>
        <p>
          <strong> Survey title: {SurveyTitle} </strong>
        </p>
        <hr></hr>
        <br />
        <p>Job title: {Title} </p>
      </header>
      <section>
        <article>
        {Questions.map((item,index) => {
            return <div key={index}>
              <p>Q: {item.Value}</p>
              <div>{item.Answers!==null
                ?<ul>{item.Answers.map((ans,i)=><li className={className} key={`ans-${i}`}>{ans}</li>)}</ul>
                :<p>{item.Answers}</p>}
              </div>
            </div>
          })}
        </article>
      </section>

      <footer className={className}>
        <hr></hr>
          <strong>
            Applicant name : {FirstName} {LastName}{' '}
          </strong><br/>
          <strong>
            Email: {Email}
          </strong>
      </footer>
    </>
  )
}

export default HtmlTemp
