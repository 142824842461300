import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import AccountCircle from '@material-ui/icons/AccountCircle'
import { NavLink, Link } from 'react-router-dom'
import logo from '../../assets/logo.png'
import store from '../../store'
import { logout } from '../../actions'

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    fontSize: '1.2rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.8rem'
    }
  },
  paper: {
    boxShadow: 'none'
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    color: 'white',
    textAlign: 'center',
    cursor: 'pointer',
    textTransform: 'uppercase',
    fontSize: '15px',
    marginRight: '50px',
    [theme.breakpoints.up('xl')]: {
      marginRight: '0px'
    }
  },
  header: {
    minHeight: 100,
    backgroundColor: theme.palette.secondary.main
  },
  toolbar: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    margin: '0px 30px',
    [theme.breakpoints.up('xl')]: {
      width: '95%',
      marginLeft: '50px'
    }
  },

  toolbar_nav: {
    display: 'flex',
    justifyContent: 'flex-start',
    paddingLeft: '55px',
    backgroundColor: 'rgb(160,160,160)',
    [theme.breakpoints.up('xl')]: {
      paddingLeft: '75px'
    }
  },
  isActive: {
    fontWeight: 'bold'
  }
})

const NavBarComponent = ({ classes, isUserLoggedIn }) => {
  return (
    <div className={classes.root}>
      {isUserLoggedIn && (
        <AppBar position="static" className={classes.paper}>
          <Toolbar className={classes.toolbar}>
            <Link to="/survey">
              <img src={logo} alt="logo" style={{ width: '30px' }}></img>
            </Link>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              className={classes.title}
              onClick={() => store.dispatch(logout())}
            >
              logout
              <AccountCircle />
            </IconButton>
          </Toolbar>
          <Toolbar className={classes.toolbar_nav}>
            <NavLink to="/survey" activeClassName={classes.isActive}>
              <div
                style={{
                  color: 'white',
                  marginRight: '40px'
                }}
              >
                Job Posts
              </div>
            </NavLink>
            <NavLink to="/jobseekers" activeClassName={classes.isActive}>
              <div style={{ color: 'white' }}>Jobseekers</div>
            </NavLink>
          </Toolbar>
        </AppBar>
      )}
    </div>
  )
}

export default withStyles(styles)(NavBarComponent)
