import React, { useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { Field } from 'redux-form'
import { Button, MenuItem } from '@material-ui/core'
import {
  renderTextField,
  renderSelectMultiField,
  renderDateField
} from '../../utils/wrappers'
import classNames from 'classnames'
import { useDispatch } from 'react-redux'
import { setDropdownOpen } from '../../actions'

const styles = (theme) => ({
  root: {
    backgroundColor: 'rgb(226,226,226)',
    margin: '0px',
    padding: '0px 20px',
    [theme.breakpoints.up('xl')]: {
      padding: '0 40px'
    },
    [theme.breakpoints.down('lg')]: {
      padding: '0 20px'
    },
    '& .MuiInputBase-root': {
      color: 'rgba(0,0,0,0.54)'
    }
  },
  '@global': {
    body: {
      background: theme.palette.primary.bg
    }
  },
  card: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    border: '1px solid black',
    borderRadius: '5px',
    textAlign: 'center'
  },

  dashbrd: {
    display: 'flex',
    padding: '20px 0'
  },

  createpost: {
    marginTop: '20px'
  },

  survey_list: {
    width: '80%',
    marginTop: '20px',
    display: 'flex',
    flexDirection: 'column'
  },
  button_container: {
    alignItems: 'center',
    justifyContent:'flex-end'

  },
  common_btn: {
    width: '120px',
    justifyContent: 'center',
    padding: '5px 16px',
    borderRadius: '4px',
    textAlign: 'center',
    cursor: 'pointer',
    paddingTop: '6px',
    textTransform: 'none',
    [theme.breakpoints.up('xl')]: {
      width: '150px'
    },
    [theme.breakpoints.down('lg')]: {
      width: '130px'
    },
    [theme.breakpoints.down('md')]: {
      width: '120px'
    }
  },
  reset_btn: {
    marginRight: '10px',
    marginTop: '0px',
    color: '#E225D7',
    border: '0.2px solid',
    backgroundColor: 'white',

    [theme.breakpoints.down('md')]: {
      marginBottom: '5px',
      marginRight: '0px'
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: '0px',
      marginRight: '5px'
    }
  },
  select_job: {
    border: '1px solid transparent',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '4px',
    fontSize: '1rem',
    textTransform: 'capitalize'
  },
  active_inactive_job: {
    marginLeft: '10px',
    padding: '0 4px',
    color: 'white',
    fontWeight: 'medium'
  },
  survey_item: {
    backgroundColor: theme.palette.background.default,
    position: 'relative',
    margin: '16px 0',
    border: '1px solid #EFEFEF',
    alignItems: 'stretch',
    '&:hover': {
      border: '1px solid #00BF6F'
    },
    '& .status_draft': {
      backgroundColor: theme.palette.primary.red,
      position: 'absolute',
      left: '16px',
      zIndex: 2,
      borderRadius: '0 0 3px 3px',
      padding: '2px 5px',
      color: '#fff',
      verticalAlign: 'top',
      fontSize: '13px',
      fontWeight: 500
    },
    '& .status_open': {
      backgroundColor: theme.palette.primary.green,
      position: 'absolute',
      left: '16px',
      zIndex: 2,
      borderRadius: '0 0 3px 3px',
      padding: '2px 5px',
      color: '#fff',
      verticalAlign: 'top',
      fontSize: '13px',
      fontWeight: 500
    }
  },
  survey_button: {
    alignSelf: 'flex-end'
  },
  survey_item_title: {
    fontSize: '16px',
    fontWeight: '500'
  },
  options: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  options_icon: {
    paddingRight: '9px'
  },
  btn_dis_label: {
    color: theme.palette.primary.green
  },
  submit: {
    margin: theme.spacing(3, 1, 2)
  }
})

const ApplicantsFilterRow = ({
  classes,
  handleSubmit,
  fetchApplicants,
  resetFilter,
  state,
  getAllPosts
}) => {
  const [selectedval, setSelected] = React.useState('')
  useEffect(()=>{
    getAllPosts()
  },[getAllPosts])
  let defaultDate = new Date()
  const dispatch = useDispatch()
  defaultDate = defaultDate.toISOString().split('T')[0]
  const filteredData = state.post.postList
    .filter((data) => {
      let date = new Date(data.CreatedDate).toISOString().split('T')[0]
      return date <= defaultDate
    })
    .reverse()
  /* const jobtitles = filteredData.map((filtered) => filtered.Title) */
  const newJobObj = filteredData.map((filtered) => {
    let JobsObj = {
      title: filtered.Title,
      isActive: filtered.IsActive,
      viewCount: filtered.ViewCount,
      ID:filtered.ID
    }
    return JobsObj
  })
  const handleChange = (event) => {
    setSelected(event.target.value)
  }

  const _onFormSubmit = () => {
    return false
  }

  return (
    <>
      <form onSubmit={handleSubmit(_onFormSubmit)}>
        {/*Failed prop type: The prop `justify` of `ForwardRef(Grid)` is deprecated. Use `justifyContent`*/}
        <Grid container className={classes.root}>
          <Grid
            style={{
              margin: '5px 20px'
            }}
            container
            spacing={2}
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item xs={12} sm={3}>
              <Field
                component={renderSelectMultiField}
                name="qJobTitle"
                variant="outlined"
                id="job_title"
                onChange={handleChange}
                selectedval={selectedval}
                classes={classes}
                label={'Job title'}
              >
                {newJobObj.map((job, i) => (
                  <MenuItem
                    key={i}
                    value={job.ID}
                    className={classes.select_job}
                  >
                    {`${job.title} (${job.viewCount})`}
                    {job.isActive === true ? (
                      <span
                        className={classes.active_inactive_job}
                        style={{ backgroundColor: '#E225D7' }}
                      >
                        {' '}
                        {'A'}{' '}
                      </span>
                    ) : (
                      <span
                        className={classes.active_inactive_job}
                        style={{ backgroundColor: '#fd7e14' }}
                      >
                        {' '}
                        {'C'}{' '}
                      </span>
                    )}
                  </MenuItem>
                ))}
              </Field>
            </Grid>
            <Grid
              item
              xs={12}
              sm={3}
              style={{ paddingTop: '2px', marginTop: '0px' }}
            >
              <Field
                component={renderTextField}
                name="qFirstName"
                variant="outlined"
                fullWidth
                label="First name"
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Field
                component={renderTextField}
                name="qLastname"
                variant="outlined"
                fullWidth
                label="Last name"
                //autoFocus
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Field
                component={renderTextField}
                name="qEmail"
                variant="outlined"
                fullWidth
                label="Email"
                //autoFocus
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Field
                component={renderTextField}
                name="qPhone"
                variant="outlined"
                fullWidth
                label="Phone number"
                // autoFocus
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Field
                component={renderDateField}
                type="date"
                name="qDate"
                label="Start date"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Field
                component={renderDateField}
                type="date"
                name="qToDate"
                label="End date"
                variant="outlined"
                fullWidth
              />
            </Grid>            
            <Grid item xs={12} sm={3}>
              <Grid container className={classes.button_container}>
                  <Button
                    type="submit"
                    color="primary"
                    className={classNames(
                      classes.common_btn,
                      classes.reset_btn
                    )}
                    onClick={() => {
                      setSelected('')
                      resetFilter()
                      fetchApplicants()
                    }}
                  >
                    Reset
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classNames(
                      classes.common_btn,
                      classes.submit_btn
                    )}
                    onClick={() => {
                    fetchApplicants()
                    dispatch(setDropdownOpen(false))
                    }
                  }
                  >
                    Search
                  </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </>
  )
}

export default withStyles(styles)(ApplicantsFilterRow)
