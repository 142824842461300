export const ASYNC_ACTION_START = 'ASYNC_ACTION_START'
export const ASYNC_ACTION_FINISH = 'ASYNC_ACTION_FINISH'
export const ASYNC_ACTION_ERROR = 'ASYNC_ACTION_ERROR'

// REGISTER USER
export const SIGNUP_FORM_SUBMIT = 'SIGNUP_FORM_SUBMIT'
export const SIGNUP_FORM_SUBMIT_SUCCESS = 'SIGNUP_FORM_SUBMIT_SUCCESS'
export const SIGNUP_FORM_SUBMIT_USER_EXIST_ERROR =
  'SIGNUP_FORM_SUBMIT_USER_EXIST_ERROR'
export const SIGNUP_FORM_SUBMIT_FAILED = 'SIGNUP_FORM_SUBMIT_FAILED'
export const CLOSE_SIGNUP_SNACKBAR = 'CLOSE_SIGNUP_SNACKBAR'

//SIGNIN/ SIGNOUT USER
export const SIGNIN_FORM_SUBMIT = 'SIGNIN_FORM_SUBMIT'
export const SIGNIN_FORM_SUBMIT_SUCCESS = 'SIGNIN_FORM_SUBMIT_SUCCESS'
export const SIGNIN_FORM_SUBMIT_FAILED = ' SIGNIN_FORM_SUBMIT_FAILED'
export const CLOSE_SIGNIN_SNACKBAR = 'CLOSE_SIGNIN_SNACKBAR'
export const CLIENT_SET = 'CLIENT_SET'
export const CLIENT_UNSET = 'CLIENT_UNSET'
export const LOGOUT = 'LOGOUT'

export const CLEAR_IMAGES = 'CLEAR_IMAGES'
export const RETRIEVE_IMAGE_INFO = 'RETRIEVE_IMAGE_INFO'
export const RETRIEVE_LOGO_INFO = 'RETRIEVE_LOGO_INFO'

//SURVEY
export const GET_ALL_SURVEYS = 'GET_ALL_SURVEYS'
export const GET_ALL_SURVEYS_SUCCESS = 'GET_ALL_SURVEYS_SUCCESS'
export const GET_ALL_SURVEYS_FAIL = 'GET_ALL_SURVEYS_FAIL'

export const GET_SURVEY_DETAILS_BY_ID = 'GET_SURVEY_DETAILS_BY_ID'
export const GET_SURVEY_DETAILS_BY_ID_SUCCESS =
  'GET_SURVEY_DETAILS_BY_ID_SUCCESS'

export const UNSET_SURVEY_DETAILS = 'UNSET_SURVEY_DETAILS'

export const SAVE_SURVEY = 'SAVE_SURVEY'
export const SAVE_SURVEY_SUCCESS = 'SAVE_SURVEY_SUCCESS'
export const SAVE_SURVEY_FAIL = 'SAVE_SURVEY_FAIL'

export const UPDATE_SURVEY = 'UPDATE_SURVEY'
export const UPDATE_SURVEY_SUCCESS = 'UPDATE_SURVEY_SUCCESS'
export const UPDATE_SURVEY_FAIL = 'UPDATE_SURVEY_FAIL'

export const DELETE_SURVEY = 'DELETE_SURVEY'
export const DELETE_SURVEY_SUCCESS = 'DELETE_SURVEY_SUCCESS'
export const DELETE_SURVEY_FAIL = 'DELETE_SURVEY_FAIL'

export const APPLICANT_SUBMIT_SURVEY = 'APPLICANT_SUBMIT_SURVEY'
export const APPLICANT_SUBMIT_SURVEY_SUCCESS = 'APPLICANT_SUBMIT_SURVEY_SUCCESS'
export const APPLICANT_SUBMIT_SURVEY_FAIL = 'APPLICANT_SUBMIT_SURVEY_FAIL'

export const UPDATE_SURVEY_STATUS = 'UPDATE_SURVEY_STATUS'
export const UPDATE_SURVEY_STATUS_SUCCESS = 'UPDATE_SURVEY_STATUS_SUCCESS'
export const UPDATE_SURVEY_STATUS_FAIL = 'UPDATE_SURVEY_STATUS_FAIL'

export const RETRIEVE_DOCUMENT_INFO = 'RETRIEVE_DOCUMENT_INFO'

//POST

export const GET_ALL_POSTS = 'GET_ALL_POSTS'
export const GET_ALL_POSTS_SUCCESS = 'GET_ALL_POSTS_SUCCESS'
export const GET_ALL_POSTS_FAIL = 'GET_ALL_POSTS_FAIL'

export const GET_POST_DETAILS_BY_ID = 'GET_POST_DETAILS_BY_ID'
export const GET_POST_DETAILS_BY_ID_SUCCESS = 'GET_POST_DETAILS_BY_ID_SUCCESS'

export const SET_POSTS_DETAIL = 'SET_POSTS_DETAIL'
export const UNSET_POST_DETAILS = 'UNSET_POST_DETAILS'

export const AUTO_EMAIL_TO_APPLICANT = 'AUTO_EMAIL_TO_APPLICANT'
export const POPULATE_AUTO_MESSAGE = 'POPULATE_AUTO_MESSAGE'
export const POPULATE_AUTO_MESSAGE_SUCCESS = 'POPULATE_AUTO_MESSAGE_SUCCESS'

export const SAVE_POST = 'SAVE_POST'
export const SAVE_POST_SUCCESS = 'SAVE_POST_SUCCESS'
export const SAVE_POST_FAIL = 'SAVE_POST_FAIL'

export const UPDATE_POST = 'UPDATE_POST'
export const UPDATE_POST_SUCCESS = 'UPDATE_POST_SUCCESS'
export const UPDATE_POST_FAIL = 'UPDATE_POST_FAIL'

export const UPDATE_POST_ACTIVE = 'UPDATE_POST_ACTIVE'
export const UPDATE_POST_ACTIVE_SUCCESS = 'UPDATE_POST_ACTIVE_SUCCESS'
export const UPDATE_POST_ACTIVE_FAIL = 'UPDATE_POST_FAIL'

export const DELETE_POST = 'DELETE_POST'
export const DELETE_POST_SUCCESS = 'DELETE_POST_SUCCESS'
export const DELETE_POST_FAIL = 'DELETE_POST_FAIL'

export const CLEAR_POST_DETAIL = 'CLEAR_POST_DETAIL'

export const RETRIEVE_OTHER_FILES = 'RETRIEVE_OTHER_FILES'

export const DELETE_CV = 'DELETE_CV'
export const DELETE_OTHER_FILES = 'DELETE_OTHER_FILES'

export const COPY_POSTS_DETAIL = 'COPY_POSTS_DETAIL'
export const COPY_POSTS_DETAIL_SUCCESS = 'COPY_POSTS_DETAIL_SUCCESS'

export const DELETE_FILES = 'DELETE_FILES'

//Development Server

// export const VPT_IDENTITY_SERVER =
//   'https://qr-identity-dev.azurewebsites.net/api'

// export const VPT_API_SERVER = 'https://qrapi-dev.azurewebsites.net/api'

// export const URL_GET_LINK_DEV = 'https://qr-ui-dev.azurewebsites.net'

/* export const VPT_API_SERVER = 'http://localhost:7071/api' */

//export const URL_GET_LINK_DEV = 'http://localhost:3000';

//Production Server

export const VPT_IDENTITY_SERVER =
  'https://talenttia-identityserver-prod.azurewebsites.net/api'

export const VPT_API_SERVER =
  'https://talenttia-api-qr-prod.azurewebsites.net/api'

export const URL_GET_LINK_DEV = 'https://talenttia.c-management.fi'

// export const URL_GET_LINK_LOCAL = 'http://localhost:3000'

export const HANDLE_SEARCH = 'HANDLE_SEARCH'
export const FETCH_APPLICANTS = 'FETCH_APPLICANTS'
export const FETCH_APPLICANTS_SUCCESS = 'FETCH_APPLICANTS_SUCCESS'
export const FETCH_APPLICANTS_ERROR = 'FETCH_APPLICANTS_ERROR'

export const GET_APPLICANT_DOC_DATA_BY_ID = 'GET_APPLICANT_DOC_DATA_BY_ID'
export const GET_APPLICANT_DOC_DATA_BY_ID_SUCCESS =
  'GET_APPLICANT_DOC_DATA_BY_ID_SUCCESS'
export const GET_APPLICANT_DOC_DATA_BY_ID_ERROR =
  'GET_APPLICANT_DOC_DATA_BY_ID_ERROR'

export const SAVE_APPLICANT_DOC_DATA = 'SAVE_APPLICANT_DOC_DATA'
export const SAVE_APPLICANT_DOC_DATA_SUCCESS = 'SAVE_APPLICANT_DOC_DATA_SUCCES'
export const SAVE_APPLICANT_DOC_DATA_ERROR = 'SAVE_APPLICANT_DOC_DATA_ERROR'

export const SAVE_APPLICANT_PROFILE_INFOS = 'SAVE_APPLICANT_PROFILE_INFOS'
export const SAVE_APPLICANT_PROFILE_INFOS_SUCCESS =
  'SAVE_APPLICANT_PROFILE_INFOS_SUCCES'
export const SAVE_APPLICANT_PROFILE_INFOS_ERROR =
  'SAVE_APPLICANT_PROFILE_INFOS_ERROR'

export const RESET_FILTER = 'RESET_FILTER'
export const SET_DROPDOWN_OPEN = 'SET_DROPDOWN_OPEN'

export const SET_POST_STATE = 'SET_POST_STATE'
