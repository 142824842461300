import { connect } from 'react-redux'
import { getPostDetailsById } from '../../../actions'
import PostComponent from '../../../components/survey/jobSeeker/Post.component'

const mapStateToProps = (state) => {
  const {
    post: { postDetail },
    async
  } = state

  return {
    state,
    postDetail,
    isGetPostDetailsByIdLoading: async.getPostDetailsById.loading
  }
}

const actions = (dispatch) => {
  return {
    dispatch,
    getPostDetailsById: (id) => dispatch(getPostDetailsById(id))
  }
}

const PostContainer = connect(mapStateToProps, actions)(PostComponent)

export default PostContainer
