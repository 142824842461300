import React from 'react'
import ApplicantsFilterRowContainer from '../../containers/applicants/ApplicantsFilterRowContainer'

import PaginationTable from './PaginationTable'

const MainApplicants = () => {
  return (
    <>
      <ApplicantsFilterRowContainer />
      <PaginationTable />
    </>
  )
}

export default MainApplicants
