import React from 'react'
import Avatar from '@material-ui/core/Avatar'
import {
  Button,
  CssBaseline,
  Typography,
  Container,
  Snackbar,
  Dialog,
  DialogContent,
  DialogContentText,
  LinearProgress
} from '@material-ui/core'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import { Field } from 'redux-form'
import { renderTextField } from '../../utils/wrappers'
import { withStyles } from '@material-ui/core/styles'
import { MySnackbarContentWrapper } from '../../utils/snackbar.utils'

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
})

const _onFormSubmit = () => {
  return false
}

const SignInComponent = ({
  classes,
  signInFormSubmit,
  handleSubmit,
  errorMessage,
  showFailedSnackbar,
  closeSignInSnackBar,
  showSpinner,
  invalid
}) => {
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit(_onFormSubmit)}>
          <Field
            component={renderTextField}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <Field
            component={renderTextField}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            autoComplete="current-password"
          />
          {invalid ? (
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Sign In
            </Button>
          ) : (
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={signInFormSubmit}
            >
              Sign In
            </Button>
          )}
        </form>

        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          open={showFailedSnackbar}
          autoHideDuration={3000}
          onClose={() => {
            closeSignInSnackBar()
          }}
        >
          <MySnackbarContentWrapper
            variant="error"
            message={
              errorMessage === 'invalid_username_or_password' &&
              'Invalid username or password'
            }
            onClose={() => {
              closeSignInSnackBar()
            }}
          />
        </Snackbar>

        <Dialog open={showSpinner} aria-describedby="alert-dialog-description">
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              style={{ fontSize: '15px', margin: '20px' }}
            >
              Logging in...
            </DialogContentText>
          </DialogContent>
          <div className={classes.progress}>
            <LinearProgress />
          </div>
        </Dialog>
      </div>
    </Container>
  )
}

export default withStyles(styles)(SignInComponent)
