import React from 'react'
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  RadioGroup,
  FormControlLabel,
  Checkbox,
  FormGroup,
  Switch,
  FormHelperText
} from '@material-ui/core'
import { Editor } from '@tinymce/tinymce-react'

export const renderTextField = (props) => {
  const {
    input,
    meta: { touched, invalid, error },
    ...restProps
  } = props
  return (
    <TextField
      {...input}
      {...restProps}
      variant="outlined"
      id="mui-theme-provider-outlined-input"
      error={touched && invalid}
      helperText={touched && error}
      size="small"
    />
  )
}
export const renderDateField = (props) => {
  const {
    input,
    label,
    validuntill,
    meta: { touched, invalid, error },
    ...restProps
  } = props
  const min = new Date().setDate(new Date().getDate() + 1)
  const fetchedDate=new Date(input.value)
  const today=new Date()
  const tomorrow = new Date(today)
  tomorrow.setDate(tomorrow.getDate() + 1)
  const tomorrowIso = tomorrow.toISOString().split('T')[0]
  const currentYear = today.getFullYear()
  const maxYear = currentYear + 2
  /*  let max = new Date().setDate(new Date().getDate() + (4 * 7 + 1))*/
  return (
    <TextField
      type="date"
      {...input}
      {...restProps}
      variant="outlined"
      id="mui-theme-provider-outlined-input"
      error={touched && invalid}
      helperText={touched && error}
      size="small"
      label={label}
      value={validuntill === true
        ? ''
        : fetchedDate < today&& input.name==='dueDate' ?tomorrowIso : input.value}
      InputLabelProps={{
        shrink: true
      }}
      inputProps={
        input.name === 'dueDate'
          ? {
              min: new Date(min).toISOString().split('T')[0],
              onFocus: (e) => {
                e.currentTarget.blur() //preventing user to manually add date from date input field
              },
              onChange:(e) => {
                e.currentTarget.blur() //disabling tab movement and manual changing of date from date input field
              },
              onClick:(e)=>{
                e.currentTarget.blur()
              },
              tabIndex:'-1'
            }
            : input.name === 'qDate' ? {
              // Using 2022 as min year as there is no data before that and never will be
              min: '2022-01-01',
              max: new Date().toISOString().split('T')[0]
            } 
            : input.name === 'qToDate' ? {
              min: '2022-01-01',
              max: `${maxYear}-12-31` 
            }
          : { min: null }
      }
    />
  )
}
export const renderDenseTextField = (props) => {
  const { input, ...restProps } = props
  return (
    <TextField
      {...input}
      {...restProps}
      id="outlined-dense"
      margin="dense"
      variant="outlined"
      size="small"
    />
  )
}

export const renderLinkTextField = (props) => {
  const {
    input,
    meta: { touched, invalid, error },
    ...restProps
  } = props
  return (
    <TextField
      {...input}
      {...restProps}
      id="outlined-dense"
      margin="dense"
      variant="outlined"
      error={touched && invalid}
      helperText={touched && error}
      size="small"
    />
  )
}

export const renderSelectField = ({
  input,
  label,
  meta: { touched, error },
  children,
  ...custom
}) => (
  <FormControl variant="outlined" error={touched && error} size="small">
    <InputLabel style={{ background: 'white', paddingRight: '5px' }}>
      {label}
    </InputLabel>
    <Select variant="outlined" native {...input} {...custom}>
      {children}
    </Select>
  </FormControl>
)
export const renderSelectMultiField = ({
  input,
  meta: { touched, error },
  label,
  children,
  selectedval,
  classes,
  ...custom
}) => {
  return (
    <FormControl variant="outlined" error={touched && error} size="small">
      <InputLabel>{label}</InputLabel>
      <Select
        {...input}
        {...custom}
        {...classes}
        value={selectedval}
        label={label}
        MenuProps={{
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          }
        }}
      >
        {children}
      </Select>
    </FormControl>
  )
}

export const renderRadioButton = ({ input, ...props }) => (
  <RadioGroup
    {...input}
    {...props}
    value={input.value}
    onChange={(e, value) => input.onChange(value)}
  />
)
export const renderRadioButtonDueDate = ({
  input,
  meta: { touched, error },
  ...props
}) => {
  return (
    <FormControl>
      <RadioGroup
        {...input}
        {...props}
        value={input.value}
        onChange={(e, value) => input.onChange(value)}
      />

      {renderFormHelper({ touched, error })}
    </FormControl>
  )
}
export const renderRadioButtonGroup = ({
  input,
  name,
  children,
  value,
  onChange,
  ...restProps
}) => (
  <RadioGroup
    {...restProps}
    name={name}
    value={value}
    onChange={onChange}
    children={children}
    {...input}
  />
)

export const renderCheckbox = ({ input, label, onClick }) => (
  <div>
    <FormControlLabel
      control={
        <Checkbox
          checked={input.value ? true : false}
          onChange={input.onChange}
          onClick={onClick}
          value={label}
        />
      }
      label={label}
    />
  </div>
)

export const renderSwitchLabels = ({
  toggleChecked,
  label,
  isSendThankyou,
  switchBaseStyle
}) => {
  return (
    <FormGroup row style={{ justifyContent: 'center' }}>
      <FormControlLabel
        control={
          <Switch
            checked={isSendThankyou}
            onChange={toggleChecked}
            classes={{
              switchBase: switchBaseStyle
            }}
          />
        }
        label={label}
      />
    </FormGroup>
  )
}

const TextEditor = ({ input: { value, onChange }, meta, ...props }) => {
  const handleChange = (content) => {
    onChange(content)
  }

  return (
    <>
      <Editor
        className={meta.touched && meta.error ? 'react-rte' : null}
        apiKey="ej2ojcr51g8w93mnoryq2p3ditvdbjldihlj3a3ob2u6wj5i"
        value={value}
        init={{
          branding: false,
          menubar: false,
          statusbar: false,
          placeholder: props.placeholder
        }}
        onEditorChange={handleChange}
      />
      {meta.submitFailed && meta.invalid ? (
        <p style={{ color: 'red', fontSize: '12px', marginLeft: '2rem' }}>
          {meta.error}
        </p>
      ) : null}
    </>
  )
}

export default TextEditor

export const renderFormHelper = ({ touched, error, marginLeft }) => {
  if (!(touched && error)) {
  } else {
    return (
      <FormHelperText style={{ color: '#ff0000', marginLeft: marginLeft }}>
        {touched && error}
      </FormHelperText>
    )
  }
}

export const reWriteString=string => {
  const cutString=string.length-14
  const newArray=string
    .trim()
    .replace(/\s/g,'_')
    .split('')
  newArray.splice(5,cutString,'...')
  const newString=string.length>15? newArray.join(''):string.trim().replace(/\s/g,'_')
  return newString
}
