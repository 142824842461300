const initialState = {
  result: [],
  loading: false,
  error: null
}

export default function (state = initialState, action) {
  switch (action.type) {
    case 'GET_APPLICANT_DOC_DATA_BY_ID':
      return { ...state, loading: true }
    case 'GET_APPLICANT_DOC_DATA_BY_ID_SUCCESS':
      return {
        ...state,
        loading: false,
        result: action.result
      }
    case 'GET_APPLICANT_DOC_DATA_BY_ID_ERROR':
      return {
        ...state,
        loading: false,
        error: action.message
      }
    default:
      return state
  }
}
