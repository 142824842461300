import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import {
  fetchApplicants,
  getApplicantDocDataByID,
  resetFilter,
  getAllPosts
} from '../../actions'
import { validateDateRange as validate } from '../validate'

import ApplicantsFilterRowComponent from '../../components/applicants/ApplicantsSearch'

const ApplicantsFilterRowContainer = reduxForm({
  form: 'searchForm',
  validate
})(ApplicantsFilterRowComponent)

const mapStateToProps = (state) => {
  const {
    survey: { surveyDetail },
    post: { postDetail },
    applicantsDocData: { loading }
  } = state

  return {
    state,
    surveyDetail,
    postDetail,
    loading
  }
}
const actions = (dispatch) => {
  return {
    dispatch,
    getAllPosts:() => dispatch(getAllPosts()),
    fetchApplicants: () => dispatch(fetchApplicants()),
    getApplicantDocDataByID: (ID,postId) => dispatch(getApplicantDocDataByID(ID,postId)),
    resetFilter: () => dispatch(resetFilter())
  }
}
export default connect(mapStateToProps, actions)(ApplicantsFilterRowContainer)
