import { connect } from 'react-redux'
import { getSurveyDetailsByPostId } from '../../../actions'
import SurveyCreateComponent from '../../../components/survey/company/SurveyDetail.component'
import { reduxForm } from 'redux-form'

const mapStateToProps = (state) => {
  const { survey } = state

  const { surveyDetail } = survey

  let initialValues = {
    surveyTitle: '',
    surveyList: [
      {
        answers: [null, null],
        type: 'multi_choice'
      }
    ]
  }

  if (surveyDetail && surveyDetail.ID) {
    initialValues = {
      ID: surveyDetail.ID,
      status: surveyDetail.Status,
      surveyTitle: surveyDetail.SurveyTitle,
      pageTitle: (surveyDetail && surveyDetail.PageTitle) || null,
      description: (surveyDetail && surveyDetail.Description) || null,
      surveyList: [
        ...surveyDetail.Questions.map((item, index) => {
          return {
            ...surveyDetail.Questions[index],
            isSaved: true
          }
        })
      ]
    }
  }

  return {
    state,
    initialValues
  }
}

const actions = (dispatch) => {
  return {
    dispatch,
    getSurveyDetailsByPostId: (onlyFillSurveyID, route) =>
      dispatch(getSurveyDetailsByPostId(onlyFillSurveyID, route))
  }
}

const SurveyCreateForm = reduxForm({
  form: 'surveyDetail',
  destroyOnUnmount: false
  //   enableReinitialize: true
})(SurveyCreateComponent)

const SurveyCreateContainer = connect(
  mapStateToProps,
  actions
)(SurveyCreateForm)

export default SurveyCreateContainer
