import { connect } from 'react-redux'
import { updateSurvey, saveSurvey } from '../../../actions'
import SurveyPreviewComponent from '../../../components/survey/company/SurveyPreview.component'
import { reduxForm } from 'redux-form'
import store from '../../../store'
import { getFormValues } from 'redux-form'

const mapStateToProps = (state) => {
  const {
    survey: { surveyDetail },
    post: { postState },
    async
  } = state
  const formValues = getFormValues('survey')(store.getState())
  const surveyDetailFormValues = getFormValues('surveyDetail')(store.getState())

  return {
    state,
    formValues:
      formValues && formValues.surveyTitle
        ? formValues
        : surveyDetailFormValues,
    surveyDetail,
    postState,
    loading: async.saveSurvey.loading
  }
}

const actions = (dispatch) => {
  return {
    dispatch,
    updateSurvey: () => dispatch(updateSurvey()),
    saveSurvey: () => dispatch(saveSurvey())
  }
}

const SurveyPreviewForm = reduxForm({
  form: 'surveyAnswer'
})(SurveyPreviewComponent)

const SurveyPreviewContainer = connect(
  mapStateToProps,
  actions
)(SurveyPreviewForm)

export default SurveyPreviewContainer
