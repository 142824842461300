import { connect } from 'react-redux'
import {
  applicantSubmitSurvey,
  asyncActionFinish,
  clearUploadedCV,
  clearUploadedOtherFiles,
  deleteFiles,
  saveApplicantDocData,
  saveApplicantProfileInfos
} from '../../../actions'
import SurveyDoneComponent from '../../../components/survey/jobSeeker/SurveyDone.component'
import { reduxForm } from 'redux-form'
import { surveyDoneValidate as validate } from '../../validate'

const mapStateToProps = (state) => {
  const { survey, async } = state

  return {
    state,
    uploadedDocument:
      survey.uploadedDocument && survey.uploadedDocument[0]
        ? survey.uploadedDocument[0]
        : '',
    uploadedDoc:
      survey.uploadedDoc && survey.uploadedDoc[0] ? survey.uploadedDoc[0] : '',
    loading: async.applicantSendSurvey.loading,
    isShowSnackbarSendApplicantSuccess:
      async.isShowSnackbarSendApplicantSuccess.loading,
    isShowSnackbarSendApplicantFalse:
      async.isShowSnackbarSendApplicantFalse.loading,
    isDisabledSendButton: async.isDisabledSendButton.loading,
    isShowSnackbarFileMaximunSize: async.isShowSnackbarFileMaximunSize.loading,
    isShowAllowedFileTypes: async.isShowAllowedFileTypes.loading,
    isShowSnackbarSaveApplicantSuccess:
      async.isShowSnackbarSaveApplicantSuccess.loading,
    isShowSnackbarSaveApplicantError:
      async.isShowSnackbarSaveApplicantError.loading,
    isShowSnackbarSaveApplicantProfileInfosSuccess:
      async.isShowSnackbarSaveApplicantProfileInfosSuccess.loading,
    isShowSnackbarSaveApplicantProfileInfosError:
      async.isShowSnackbarSaveApplicantProfileInfosError.loading,
    isShowSnackbarSaveApplicantDocDataSuccess:
      async.isShowSnackbarSaveApplicantDocDataSuccess.loading,
    isShowSnackbarSaveApplicantDocDataError:
      async.isShowSnackbarSaveApplicantDocDataError.loading
  }
}

const actions = (dispatch) => {
  return {
    dispatch,
    applicantSubmitSurvey: () => dispatch(applicantSubmitSurvey()),
    asyncActionFinish: (name) => dispatch(asyncActionFinish(name)),
    clearUploadedCV: () => dispatch(clearUploadedCV()),
    clearUploadedOtherFiles: () => dispatch(clearUploadedOtherFiles()),
    deleteFiles: (formName) => dispatch(deleteFiles(formName)),
    saveApplicantDocData: () => dispatch(saveApplicantDocData()),
    saveApplicantProfileInfos: () => dispatch(saveApplicantProfileInfos())
  }
}

const SurveyDoneForm = reduxForm({
  form: 'surveyDone',
  validate,
  touchOnBlur: true,
  destroyOnUnmount: true
})(SurveyDoneComponent)

const SurveyDoneContainer = connect(mapStateToProps, actions)(SurveyDoneForm)

export default SurveyDoneContainer
