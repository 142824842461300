import { createStore, applyMiddleware, compose } from 'redux'
import rootReducer from './reducers'
import createSagaMiddleware from 'redux-saga'
import rootSaga from './sagas'
import { persistStore, persistReducer } from 'redux-persist'
import storageSession from 'redux-persist/lib/storage/session' // saving in Sessionstorage instead of local storage

// redux persist
const persistConfig = {
  key: 'root',
  storage: storageSession,
  blacklist: ['form', 'async']
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

// create the saga middleware
const sagaMiddleware = createSagaMiddleware()

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const createStoreWithMiddleware = composeEnhancers(
  applyMiddleware(sagaMiddleware)
)(createStore)

const initialState = {}

const store = createStoreWithMiddleware(persistedReducer, initialState)
export default store

/* Redux-Persist + Store */

export const persistor = persistStore(store)

/* Run saga */

sagaMiddleware.run(rootSaga)
