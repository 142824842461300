import React, { Component } from 'react'
import {
  Grid,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  LinearProgress
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import parse from 'html-react-parser'
import BlockIcon from '@material-ui/icons/Block'

const styles = (theme) => ({
  description: { margin: '10px 0' },
  title: {
    margin: '20px 0',
    textAlign: 'center'
  },
  imgContainer: {
    cursor: 'pointer'
  },
  btn_done_container: {
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      alignItems: 'center'
    },
    '& .MuiButton-label': {
      padding: '5px 45px'
    }
  },
  btn_done: {
    marginTop: '30px',
    fontSize: '1.1rem',
    [theme.breakpoints.down('xs')]: {
      margin: '0rem 4rem',
      padding: '5px '
    }
  },

  img: {
    maxHeight: '50vh',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  }
})

class PostJobSeeker extends Component {
  componentDidMount() {
    const { postId } = this.props.match.params
    this.props.getPostDetailsById(postId)
  }

  render() {
    const { classes, postDetail, isGetPostDetailsByIdLoading } = this.props
    return (
      <div>
        {postDetail.IsActive ? (
          <div className={classes.innerContainer}>
            <Grid
              style={{ paddingTop: '20px', marginBottom: '20px' }}
              container
              className={classes.mainContent}
              justifyContent="center"
              alignItems="center"
              direction="column"
            >
              <Grid item xs={10}>
                <div
                  className={classes.imgContainer}
                  onClick={() => {
                    this.props.history.push(
                      `/survey/full/${postDetail.SurveyID}`
                    )
                  }}
                >
                  {postDetail && postDetail.Document && (
                    <img
                      className={classes.img}
                      src={postDetail.Document}
                      alt={postDetail.SurveyID}
                    ></img>
                  )}
                </div>
              </Grid>

              <Grid item xs={10} className={classes.description}>
                <h4 className={classes.title}>
                  {postDetail && postDetail.Title}
                </h4>

                <h5 className={classes.title}>
                  {postDetail && postDetail.Location}
                </h5>
                <p className={classes.title}>
                  {postDetail.due_date === 'valid_until_further_notice'
                    ? ''
                    : `Due date: ${postDetail.due_date}`}
                </p>

                {postDetail && postDetail.Description
                  ? parse(postDetail.Description)
                  : ''}
              </Grid>

              <Grid container className={classes.btn_done_container}>
                {/* TL-22 */}
                <Button
                  className={classes.btn_done}
                  onClick={() => {
                    if (postDetail.SurveyID) {
                      this.props.history.push(
                        `/survey/full/${postDetail.SurveyID}`
                      )
                    } else {
                      this.props.history.push('/survey/done')
                    }
                  }}
                  variant="contained"
                  color="primary"
                >
                  TESTAA
                </Button>
              </Grid>
            </Grid>
          </div>
        ) : postDetail.IsActive === false ? (
          <div>
            <Grid
              container
              style={{
                background: 'white',
                padding: '10px',
                paddingTop: '100px'
              }}
              justify="space-between"
              alignItems="center"
            >
              <Grid
                item
                xs={12}
                sm={12}
                container
                alignItems="center"
                justify="center"
                direction="column"
              >
                <BlockIcon color="primary" fontSize="large"></BlockIcon>
                <h3 style={{ fontWeight: '300', marginTop: '10px' }}>
                  The link is no longer active.
                </h3>
              </Grid>
            </Grid>
          </div>
        ) : (
          ''
        )}

        <Dialog
          open={isGetPostDetailsByIdLoading}
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              style={{ fontSize: '15px', margin: '20px' }}
            >
              Loading in...
            </DialogContentText>
          </DialogContent>
          <div className={classes.progress}>
            <LinearProgress />
          </div>
        </Dialog>
      </div>
    )
  }
}
export default withStyles(styles)(PostJobSeeker)
