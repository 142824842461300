import { reduxForm } from 'redux-form'
import SignInComponent from '../../components/account/signIn.component'
import { connect } from 'react-redux'
import { signInFormSubmit, closeSignInSnackBar } from '../../actions'
import { signInValidate as validate } from '../validate'
const SignInContainer = reduxForm({
  form: 'signin',
  validate
})(SignInComponent)

const mapStateToProps = (state) => ({
  state,
  errorMessage: state.signIn.errorMessage,
  showFailedSnackbar: state.signIn.showFailedSnackbar,
  showSpinner: state.signIn.showSpinner
})
const mapDispatchToProps = {
  signInFormSubmit,
  closeSignInSnackBar
}
export default connect(mapStateToProps, mapDispatchToProps)(SignInContainer)
