import React from 'react'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import { withStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'

const styles = {
  renderPlaceholder: {
    background:
      'url(https://duunitori.imgix.net/static/web/images/upload_smallimg_placeholder.svg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: '0%'
  },
  renderImagePlaceholder: {
    background:
      'url(https://vptapiprodstorage.blob.core.windows.net/jobportaldocs/upload_bigimg_placeholder.svg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: '0%'
  },
  unsetRenderPlaceholder: {
    background: 'unset'
  },
  placeholderPreview: {
    height: 180
  },
  showImage: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: '0%',
    borderRadius: 4
  },
  uploadbtn: {
    borderRadius: 4,
    width: 'max-content'
  }
}

const Placeholder = ({ classes, imagefile, btnText, isLogo }) => {
  return (
    <div
      className={
        imagefile === undefined || imagefile === null || imagefile === ''
          ? isLogo
            ? classes.renderPlaceholder
            : classes.renderImagePlaceholder
          : classes.unsetRenderPlaceholder
      }
    >
      <div
        className={
          imagefile !== undefined && imagefile !== null && imagefile !== ''
            ? classes.showImage
            : classes.placeholderPreview
        }
      >
        {imagefile !== undefined && imagefile !== null && imagefile !== '' && (
          <img
            style={{ maxHeight: '400px', maxWidth: '100%' }}
            src={`${imagefile}`}
            alt="demo"
          ></img>
        )}
      </div>
      <div>
        {(imagefile === undefined ||
          imagefile === null ||
          imagefile === '') && (
          <Button
            variant="contained"
            color="primary"
            className={classes.uploadbtn}
          >
            <CloudUploadIcon fontSize="small" style={{ marginRight: 5 }} />{' '}
            {btnText}
          </Button>
        )}
      </div>
    </div>
  )
}

export default withStyles(styles)(Placeholder)
