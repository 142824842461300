import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import signUpReducer from './signUpReducer'
import signInReducer from './signInReducer'
import clientReducer from './clientReducer'
import surveyReducer from './surveyReducer'
import async from './async'
import post from './postReducer'
import applicantsReducer from './applicantsReducer'
import applicantsDocData from './applicantsPost'
import dropdownReducer from './dropdownReducer'

let appReducer = combineReducers({
  post,
  survey: surveyReducer,
  form: formReducer,
  signUp: signUpReducer,
  signIn: signInReducer,
  client: clientReducer,
  async: async,
  applicants: applicantsReducer,
  applicantsDocData: applicantsDocData,
  dropdown: dropdownReducer
})

// To initialize the app with initial state instead of the persisted state once the user logs out.
// When passing the first argument/state as undefined, reducers will return the initial state.

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT') {
    sessionStorage.removeItem('persist:root')
    state = undefined
  }

  return appReducer(state, action)
}

export default rootReducer
