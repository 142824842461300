import { connect } from 'react-redux'
import {} from '../../../actions'
import SurveyCollectComponent from '../../../components/survey/company/SurveyCollect.component'

const mapStateToProps = (state) => {
  const {
    survey: { surveyDetail },
    post: { postDetail }
  } = state

  return {
    state,
    surveyDetail,
    postDetail
  }
}

const actions = (dispatch) => {
  return {
    dispatch
  }
}

const SurveyCollectContainer = connect(
  mapStateToProps,
  actions
)(SurveyCollectComponent)

export default SurveyCollectContainer
