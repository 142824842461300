import {
  SIGNUP_FORM_SUBMIT,
  SIGNUP_FORM_SUBMIT_SUCCESS,
  SIGNUP_FORM_SUBMIT_FAILED,
  SIGNUP_FORM_SUBMIT_USER_EXIST_ERROR,
  CLOSE_SIGNUP_SNACKBAR
} from '../constants'

const intialState = {
  showSnackbar: false,
  showFailSnackbar: false,
  showSpinner: false,
  errorMessage: ''
}

const signUpReducer = (state = intialState, action) => {
  switch (action.type) {
    case SIGNUP_FORM_SUBMIT:
      return {
        ...state,
        showSpinner: true
      }
    case SIGNUP_FORM_SUBMIT_SUCCESS:
      return {
        ...state,
        showSpinner: false,
        showSnackbar: true
      }
    case SIGNUP_FORM_SUBMIT_FAILED:
      return {
        ...state,
        showSpinner: false,
        showFailSnackbar: true
      }
    case SIGNUP_FORM_SUBMIT_USER_EXIST_ERROR:
      return {
        ...state,
        showSpinner: false,
        showFailSnackbar: true,
        errorMessage: action.error
      }
    case CLOSE_SIGNUP_SNACKBAR:
      return {
        ...state,
        showSnackbar: false,
        showFailSnackbar: false
      }

    default:
      return state
  }
}

export default signUpReducer
