import React, { Component } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
  Container,
  Radio
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { Delete } from '@material-ui/icons'
import {
  renderTextField,
  renderDateField,
  renderRadioButtonDueDate
} from '../../../utils/wrappers'
import { Field, change} from 'redux-form'
import EditorField from './richTextPost/EditorField'
import AutomaticEmailAnswersContainer from '../../../containers/survey/company/automaticEmailAnswers.container'
import Dropzone from '../../../utils/dropzone'


const styles = (theme) => ({
  toolbar_btn: {
    marginRight: '20px',
    [theme.breakpoints.down('xs')]: {
      alignSelf: 'flex-end'
    }
  },
  surveyContainer: {
    marginTop: 1,
    padding: '20px 40px',
    boxShadow: '3px 3px 5px 2px rgba(0,0,0,0.3)',
    [theme.breakpoints.down('sx')]: {
      fontSize: '0.8rem',
      padding: '30px'
    }
  },
  basicInfo: {
    padding: '10px 0px',
    '& .rdw-editor-toolbar': {
      margin: '0'
    }
  },
  createPostTitle: {
    display: 'flex',
    justifyContent: 'center',
    padding: '20px 0',
    color: '#E225D7',
    textTransform: 'uppercase',
    letterSpacing: '2px'
  },
  titleLocationBox: {
    marginBottom: '20px',
    display: 'grid',
    /*gridTemplateColumns: ' repeat(4, 1fr)',
    gridGap: '2rem',*/
    justifyContent: 'center',
    alignItems: 'center',
    gridTemplateColumns: 'repeat(auto-fill, minmax(240px, 1fr))', //auto-fit allows us to wrap our columns into rows when there’s not enough space and 240px minimum value without overflowing the content
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))'
    },
    gridGap: '10px',
    '& .MuiInputBase-root': {
      [theme.breakpoints.down('xs')]: {
        margin: '5px',
        fontSize: '0.8rem'
      }
    },
    '& label .Mui-checked': {
      color: theme.palette.primary.main
    }
  },
  radioContainer: {
    display: 'flex',
    paddingTop: '5px',
    [theme.breakpoints.down('sm')]: {
      display: 'block'
    }
  },

  label: {
    color: theme.palette.primary.main,
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.8rem'
    }
  },
  due: {
    width: '200px',
    [theme.breakpoints.down('md')]: {
      width: '165px'
    },
    [theme.breakpoints.only('sm')]: {
      width: '150px'
    }
  },
  imageBox: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      display: 'block'
    }
  },
  imageField: {
    width: '40%',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  imgDescription: {
    margin: '10px',
    paddingTop: '10px',
    [theme.breakpoints.up('lg')]: {
      paddingTop: '5px'
    }
  },

  nextAndBackBtn: {
    display: 'flex',
    justifyContent: 'center',
    padding: '30px 0'
  }

  /*btn_dis_label: {
    color: theme.palette.primary.green
  }*/
})

class PostCreate extends Component {
  state = {
    topTitle: false,
    topPage: false,
    open: false,
    isChangeImg: false,
    validUntill: true,
    dueSelected: false
  }
  handleDue = () => {
    this.setState({
      validUntill: false
    })
    this.setState({
      dueSelected: true
    })
  }
  handleValidUntill = () => {
    this.setState({
      validUntill: true
    })
    this.props.dispatch(change('post', 'dueDate', ''))
    this.setState({
      dueSelected: false
    })
  }
  UNSAFE_componentWillUpdate(nexProps) {
    if (this.props.postPic !== nexProps.postPic) {
      this.setState({
        isChangeImg: true
      })
    }
  }
  componentDidMount() {
    if (this.props.postState === 'edit' || this.props.postState === 'copy') {
      if (
        this.props.state.post.postDetail.due_date !==
        'valid_until_further_notice'
      ) {
        this.setState({
          validUntill: false
        })
        this.setState({
          dueSelected: true
        })
      }
    }
  }

  render() {
    const {
      classes,
      stateImageFile,
      postState,
      loading,
      getSurveyDetailsByPostId,
      handleSubmit
    } = this.props

    return (
      <Container>
        <div className={classes.createPostTitle}>
          <h2>Create Post</h2>
        </div>
        <form>
          <div className={classes.surveyContainer}>
            <div className={classes.basicInfo}>
              <div className={classes.titleLocationBox}>
                {/* job post title */}
                <Field
                  name={'title'}
                  placeholder="Enter post title"
                  component={renderTextField}
                  label="Job Title*"
                />

                {/* job post location */}
                <Field
                  name={'location'}
                  placeholder="Enter job post location"
                  component={renderTextField}
                  label="Job Location*"
                />

                <Field
                  component={renderRadioButtonDueDate}
                  name="validuntill_due"
                  placeholder="Enter date"
                >
                  <div className={classes.radioContainer}>
                    <div>
                      <label
                        className={this.state.validUntill ? classes.label : ''}
                        style={{
                          width: 'max-content',
                          display: 'flex',
                          alignItems: 'center',
                          marginRight: '10px',
                          fontSize: '0.9rem'
                        }}
                      >
                        <Radio
                          value="valid_until_further_notice"
                          onChange={this.handleValidUntill}
                        />
                        Valid Until Further Notice
                      </label>
                    </div>
                    <div>
                      <label style={{ display: 'inline-flex' }}>
                        <Radio
                          value="dueDate"
                          onChange={this.handleDue}
                          checked={this.state.dueSelected === true}
                        />
                        <Field
                          component={renderDateField}
                          placeholder="Enter due date"
                          type="date"
                          name="dueDate"
                          label="Due date"
                          className={classes.due}
                          validuntill={this.state.validUntill}
                          disabled={this.state.validUntill === true}
                        />
                      </label>
                    </div>
                  </div>
                </Field>
              </div>
              <EditorField
                key="field"
                name="description"
                id="inputEditorText"
                disabled={false}
              ></EditorField>
            </div>
            {/* job post image  */}
            <div className={classes.imageBox}>
              <div className={classes.imageField}>
                <Field
                  btnText="Upload image"
                  name="document"
                  component={Dropzone}
                  isLogo={false}
                  isImage={true}
                  imagefile={stateImageFile}
                />

                {stateImageFile && (
                  <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<Delete />}
                    style={{ marginTop: '20px' }}
                    onClick={() => {
                      this.props.dispatch(change('post', 'document', null))
                      this.props.clearImagesFromState()
                    }}
                  >
                    Delete Image
                  </Button>
                )}
              </div>
              <div className={classes.imgDescription}>
                <p>{'(Optional)'}</p>
                <p>
                  Upload an image which will be shown as cover picture of your
                  post.
                  <br />
                  Recommended file type .jpg and min size of 1200 x 630px.
                  <br />
                  Maximum size of the attachment is 2MB.
                </p>
              </div>
            </div>

            <Dialog
              open={this.props.showCreateSurvey}
              onClose={() => this.props.asyncActionFinish('showCreateSurvey')}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {postState === 'edit'
                  ? 'Go to survey detail?'
                  : 'Do you want to create the survey?'}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {postState === 'none' &&
                    'Post has been created successfully. Do you want to create the survey?'}
                  {postState === 'edit' &&
                    'Post has been updated successfully. Do you want to go to the survey?'}
                  {postState === 'copy' &&
                  'Post has been copied successfully. Do you want to create the survey?'}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                {/* TL-22 */}
                <Button
                  classes={{ label: classes.btn_dis_label }}
                  onClick={() => {
                    this.props.setPostState('none')
                    this.props.asyncActionFinish('showCreateSurvey')
                    this.props.history.push('/survey/collect')
                  }}
                  variant="outlined"
                  color="primary"
                >
                  NO
                </Button>

                <Button
                  onClick={() => {
                    this.props.asyncActionFinish('showCreateSurvey')
                    //only if isEditPost surveyDetailsByPostID is needed, for copy survey should be made from scratch
                    if (postState === 'edit') {
                      return getSurveyDetailsByPostId(1, 'detail')
                    } if (postState === 'copy') {
                      return getSurveyDetailsByPostId(1, 'create')
                    } else {
                    this.props.history.push('/survey/create')
                    }
                  }}
                  variant="contained"
                  color="primary"
                  autoFocus
                >
                  YES
                </Button>
              </DialogActions>
            </Dialog>
            <div>
              <AutomaticEmailAnswersContainer />
            </div>
          </div>
          <div className={classes.nextAndBackBtn}>
            <Button
              type="submit"
              disabled={loading}
              onClick={handleSubmit(() => {
                if (postState === 'edit') {
                  this.props.updatePost(this.state.isChangeImg)
                  this.setState({ isChangeImg: false })
                  return
                }

                this.props.savePost()
              })}
              variant="contained"
              color="primary"
              className={classes.toolbar_btn}
            >
              {loading ? <CircularProgress size={20} /> : 'NEXT'}
            </Button>
          </div>
        </form>
      </Container>
    )
  }
}
export default withStyles(styles)(PostCreate)
