import {
  GET_ALL_SURVEYS_SUCCESS,
  GET_SURVEY_DETAILS_BY_ID_SUCCESS,
  UNSET_SURVEY_DETAILS,
  RETRIEVE_DOCUMENT_INFO,
  RETRIEVE_OTHER_FILES,
  DELETE_CV,
  DELETE_OTHER_FILES,
  DELETE_FILES
} from '../constants'

const initialState = {
  surveyList: [],
  id: 0,
  surveyDetail: {},
  uploadedDocument: [],
  uploadedDoc: []
}

const surveyReducer = (state = initialState, action) => {
  switch (action.type) {
    case RETRIEVE_DOCUMENT_INFO:
      return {
        ...state,
        uploadedDocument: [
          {
            ...action.document
          }
        ]
      }
    case RETRIEVE_OTHER_FILES:
      return {
        ...state,
        uploadedDoc: [
          {
            ...action.document
          }
        ]
      }

    case GET_ALL_SURVEYS_SUCCESS:
      return {
        ...state,
        surveyList: action.result
      }

    case GET_SURVEY_DETAILS_BY_ID_SUCCESS:
      return {
        ...state,
        surveyDetail: action.result
      }

    case UNSET_SURVEY_DETAILS:
      return {
        ...state,
        surveyDetail: {}
      }
    case DELETE_CV:
      return {
        ...state,
        uploadedDocument: []
      }
    case DELETE_OTHER_FILES:
      return {
        ...state,
        uploadedDoc: []
      }
    case DELETE_FILES:
      return {
        ...state,
        uploadedDocument: [],
        uploadedDoc: []
      }

    default:
      return state
  }
}

export default surveyReducer
