import React from 'react'
import { Router, Route, Switch, Redirect } from 'react-router-dom'
import store from './store'
import history from './history'
//import SignUpContainer from './containers/account/signUp.container'
import SignInContainer from './containers/account/signIn.container'
import NavBarContainer from './containers/layout/navBar.container'
//Giving access to only authenticated users
import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect'
import SurveyDashboardContainer from './containers/survey/company/SurveyDashboard.container'
import SurveyCreateContainer from './containers/survey/company/SurveyCreate.container'
import SurveyDetailContainer from './containers/survey/company/SurveyDetail.container'
import SurveyPreviewContainer from './containers/survey/company/SurveyPreview.container'
import SurveyCollectContainer from './containers/survey/company/SurveyCollect.container'
import SurveyContainer from './containers/survey/jobSeeker/Survey.container'
import PostCreateContainer from './containers/survey/company/PostCreate.container'
import PostContainer from './containers/survey/jobSeeker/Post.container'
import SurveyDoneContainer from './containers/survey/jobSeeker/SurveyDone.container'
import SurveyThanksComponent from './components/survey/jobSeeker/SurveyThanks.component'
import MainApplicants from './components/applicants/MainApplicants'

const isUserAuthenticated = connectedRouterRedirect({
  // The url to redirect user to if they fail
  redirectPath: '/login',
  // If selector is true, wrapper will not redirect
  // For example let's check that state contains user data
  authenticatedSelector: (state) => state.client.user !== null,
  // A nice display name for this check
  wrapperDisplayName: 'isUserAuthenticated'
})

class Routes extends React.Component {
  render() {
    const uuid =
      (store.getState().client && store.getState().client.user) || null

    return (
      <Router history={history}>
        {!history.location.pathname.includes('/survey/full/') &&
          !history.location.pathname.includes('/post/full') && (
            <NavBarContainer />
          )}

        <div>
          <Switch>
            <Route
              exact
              path="/"
              render={() =>
                uuid ? <Redirect to="/survey" /> : <Redirect to="/login" />
              }
            />

            {/* Routes by Sabina */}

            {/* <Route exact path="/register" component={SignUpContainer} /> */}
            <Route exact path="/login" component={SignInContainer} />

            <Route
              exact
              path="/survey"
              component={isUserAuthenticated(SurveyDashboardContainer)}
            />
            <Route
              exact
              path="/jobseekers"
              component={isUserAuthenticated(MainApplicants)}
            />

            <Route
              exact
              path="/jobs_post"
              component={isUserAuthenticated(SurveyCreateContainer)}
            />
            <Route
              exact
              path="/survey/create"
              component={isUserAuthenticated(SurveyCreateContainer)}
            />
            <Route
              exact
              path="/survey/detail"
              component={isUserAuthenticated(SurveyDetailContainer)}
            />
            <Route
              exact
              path="/survey/preview"
              component={isUserAuthenticated(SurveyPreviewContainer)}
            />
            <Route
              exact
              path="/survey/collect"
              component={isUserAuthenticated(SurveyCollectContainer)}
            />
            <Route
              exact
              path="/survey/full/:surveyId"
              component={SurveyContainer}
            />
            <Route
              exact
              path="/:jobTitle/:jobLocation/:postId"
              component={PostContainer}
            />
            <Route exact path="/survey/done" component={SurveyDoneContainer} />
            <Route
              exact
              path="/survey/thanks"
              component={SurveyThanksComponent}
            />

            <Route exact path="/post/create" component={PostCreateContainer} />
          </Switch>
        </div>
      </Router>
    )
  }
}

export default Routes
