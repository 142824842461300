import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchApplicants,
  getApplicantDocDataByID,
  saveApplicantDocData,
  saveApplicantProfileInfos,
  setDropdownOpen
} from '../../actions'
import {
  Paper,
  makeStyles,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  TableContainer,
  IconButton,
  TableHead,
  Box,
  Table,
  Collapse
} from '@material-ui/core'
import { sortByLatestDate } from '../../utils/customScripts'
//
//'@material-ui/core@4.12.4' and '@material-ui/lab@4.0.0-alpha.61' was used to solve a compatibility issue between component to import

import GetAppIcon from '@material-ui/icons/GetApp'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import { IButton } from '../../utils/reusable.utils'
import { getPDF } from '../../utils/htmltopdf'
import Button from '@material-ui/core/Button'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from '@material-ui/core/Dialog'
import HtmlTemp from '../../utils/htmlTemp'

const useStyles = makeStyles((theme) => ({
  cellStyle: {
    padding: 2,
    width: 200
  },
  pageContent: {
    margin: theme.spacing(5, 6),
    padding: theme.spacing(3),
    [theme.breakpoints.up('xl')]: {
      margin: theme.spacing(5, 8)
    },

    '& th.MuiTableCell-root.MuiTableCell-head': {
      fontSize: '1rem',
      fontWeight: 'bold'
    }
  },
  table: {
    marginTop: theme.spacing(3),
    '& thead th': {
      fontWeight: '600',
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.light
    },
    '& tbody td': {
      fontWeight: '300'
    },
    '& tbody tr:hover': {
      backgroundColor: '#fffbf2',
      cursor: 'pointer'
    }
  },
  qapageA4: {
    backgroundColor: '#f5f5f5',
    width: '210mm',
    minHeight: '297mm',
    marginLeft: 'auto',
    marginRight: 'auto'
  },

  modal: {
    display: 'flex',
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center'
  },
  pdfStyle: {
    width: '100%'
  },
  nestedStyle: {
    textAlign: 'center'
  },
  pdfAnswer: {
    width: '100%',
    wordWrap: 'break-word'
  },
  downloadPdf: {
    lineHeight:'1.6em',
    margin:'0 auto', 
    padding:'25px'
  }
}))

export default function PaginationTable() {
  const applicants = useSelector((state) => state.applicants.applicants)
  const nestedResult = useSelector((state) => state.applicantsDocData.result)
  const searchValues = useSelector((state) => state.form?.searchForm?.values)
  const open = useSelector(state => state.dropdown.open)
  const loading = useSelector((state) => state.applicantsDocData.loading)
  const dispatch = useDispatch()
  const classes = useStyles()
  const [page, setPage] = React.useState(0)
  const [data, setData] = useState([])
  const [rowsPerPage, setRowsPerPage] = React.useState(5)
  const [openDialog, setOpenDialog] = useState(false)
  const [selectedRow, setSelectedRow] = React.useState(null)
  const [filtered, setFiltered] = useState([])
  const [selectedSurvey, setSelectedSurvey] = useState(null)

  // initialize a Set object
  const uniqueValuesSet = new Set()
  // array of objects with duplicate values

  const filteredArr = data.filter((obj) => {
    // check if email property value is already in the set
    const isPresentInSet = uniqueValuesSet.has(obj.email)

    // add email property value to Set
    uniqueValuesSet.add(obj.email)

    // return the negated value of
    // isPresentInSet variable
    return !isPresentInSet
  })

  // sort data by Fisrtname
  const SortArray = (x, y) => {
    return x.FirstName.localeCompare(y.FirstName)
  }

  const sortData = filteredArr.sort(SortArray)

  // const onShowConfirm = () => {
  //     setOpenDialog(true);
  // }

  const onConfirm = () => {
    setOpenDialog(false)
    getPDF()
  }
  const onDialogClose = () => {
    setOpenDialog(false)
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const handleDialogOpen = (item) => {
    setSelectedSurvey(item)
    setOpenDialog(true)
    }

  useEffect(() => {
    dispatch(fetchApplicants())
    dispatch(saveApplicantProfileInfos())
    dispatch(saveApplicantDocData())
  }, [dispatch])

  useEffect(() => {
    setData(applicants)
    setPage(0)
  }, [applicants])

  //TL-26
  const handleExpandClick = (email, applicantID) => {
    dispatch(getApplicantDocDataByID(applicantID))

    if (open && selectedRow !== email) {
      dispatch(setDropdownOpen(false))
      setSelectedRow(email)
      dispatch(setDropdownOpen(true))
    } else if (open && selectedRow === email) {
      dispatch(setDropdownOpen(false))
    } else {
      setSelectedRow(email)
      dispatch(setDropdownOpen(true))
    }
  }

  useEffect(() => {
    if (open === true) {
      let filteredResult = nestedResult
      setFiltered(filteredResult)
    }
  }, [ nestedResult, searchValues, open ])
  return (
    <Paper className={classes.pageContent}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name </TableCell>
              <TableCell align="right">Email</TableCell>
              <TableCell align="right">Phone </TableCell>
              <TableCell align="right">Linkedin</TableCell>
              <TableCell align="right">Portfolio</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>

          {sortData
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row) => {
              return (
                <TableBody key={row.email}>
                  <TableRow>
                    <TableCell>
                      <p>
                        <strong>
                          {' '}
                          {row.FirstName} {}
                          {row.LastName}
                        </strong>
                      </p>
                    </TableCell>

                    <TableCell align="right">
                      {' '}
                      <p>
                        <strong>{row.email} </strong>
                      </p>
                    </TableCell>
                    <TableCell align="right">
                      {' '}
                      <p>
                        <strong>{row.phone} </strong>
                      </p>
                    </TableCell>
                    <TableCell align="right">
                      <span>
                        <p>
                          <strong>
                            <a
                              href={row.linkedin}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Linkedin
                            </a>
                          </strong>
                        </p>
                      </span>
                    </TableCell>

                    <TableCell align="right">
                      <span>
                        <p>
                          <strong>
                            {' '}
                            <a
                              href={row.portfolio}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Portfolio
                            </a>
                          </strong>
                        </p>
                      </span>
                    </TableCell>
                    <TableCell>
                      <span>
                        <p>
                          <strong>
                            <IconButton
                              aria-label="expand row"
                              size="small"
                              //TL-26
                              onClick={ () => {
                                  handleExpandClick(
                                  row.email,
                                  row.applicantID
                                )
                              }}
                            >
                              {open ? (
                                <ArrowDropDownIcon />
                              ) : (
                                <ArrowDropDownIcon />
                              )}
                            </IconButton>
                          </strong>
                        </p>
                      </span>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell
                      style={{ paddingBottom: 0, paddingTop: 0 }}
                      colSpan={6}
                    >
                      <Collapse
                        in={row.email === selectedRow ? open : false}
                        timeout="auto"
                        unmountOnExit
                      >
                        {!loading && (
                        <Box sx={{ margin: 1 }}>
                          <Table size="small" arial-label="purchases">
                            <TableHead>
                              <TableRow>
                                <TableCell>
                                  <p>
                                    <small>
                                      <strong>Company</strong>
                                    </small>
                                  </p>
                                </TableCell>

                                <TableCell>
                                  <p>
                                    <small>
                                      <strong>Job title </strong>
                                    </small>
                                  </p>
                                </TableCell>

                                <TableCell>
                                  <p>
                                    <small>
                                      <strong> Apply date </strong>
                                    </small>
                                  </p>
                                </TableCell>
                                <TableCell align="right">
                                  <p>
                                    <small>
                                      <strong> Application </strong>
                                    </small>
                                  </p>
                                </TableCell>

                                <TableCell align="right">
                                  <p>
                                    <small>
                                      <strong>Q&A </strong>
                                    </small>
                                  </p>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            {filtered && sortByLatestDate(filtered, 'Created_application').map((item, index) => {
                              return (
                                <TableBody
                                  key={index}
                                  className={classes.nestedStyle}
                                >
                                  <TableRow>
                                    <TableCell component="th" scope="row">
                                      Nordic C-Management
                                    </TableCell>
                                    <TableCell>{item.Title}</TableCell>
                                    <TableCell>
                                      {new Intl.DateTimeFormat('en-GB').format(
                                        new Date(item.Created_application)
                                      )}
                                    </TableCell>

                                    <TableCell align="right">
                                      {item.FileName}{' '}
                                      <span>
                                        <a
                                          download={item.FileName}
                                          href={item.CvDocuments}
                                        >
                                          <IButton>
                                            {' '}
                                            <GetAppIcon />
                                          </IButton>
                                        </a>
                                      </span>
                                    </TableCell>
                                    <TableCell align="right">
                                      {item.SurveyTitle}
                                      <span>
                                        {selectedSurvey === item && (
                                          <Dialog
                                            //disableBackdropClick
                                            disableEscapeKeyDown
                                            fullWidth
                                            maxWidth="md"
                                            open={openDialog}
                                            classes={{ paper: 'custom-dialog' }}
                                          >
                                            <DialogContent
                                              maxwidth="lg"
                                              className={classes.pdfStyle}
                                            >
                                              <div className={classes.downloadPdf} id="canvas_div_pdf">
                                                <HtmlTemp
                                                  SurveyTitle={selectedSurvey.SurveyTitle}
                                                  Title={selectedSurvey.Title}
                                                  FirstName={selectedSurvey.FirstName}
                                                  LastName={selectedSurvey.LastName}
                                                  Email={selectedSurvey.Email}
                                                  Questions={selectedSurvey.Questions}
                                                  className={classes.pdfAnswer}
                                                />
                                               
                                              </div>
                                            </DialogContent>
                                            <DialogActions>
                                              <Button
                                                variant="contained"
                                                onClick={onDialogClose}
                                                color="primary"
                                              >
                                                Cancel
                                              </Button>
                                              <Button
                                                variant="contained"
                                                onClick={onConfirm}
                                                color="primary"
                                              >
                                                Download
                                              </Button>
                                            </DialogActions>
                                          </Dialog>
                                        )}
                                        <IButton
                                          onClick={() => handleDialogOpen(item)}
                                        >
                                          <GetAppIcon />{' '}
                                        </IButton>
                                      </span>
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              )
                            })}
                          </Table>
                        </Box>
                        )}
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )
            })}
        </Table>
        {/*Label All removed because not necessary and as quick fix of the bug related to filtering when the selector All is selected*/}
        <TablePagination
          rowsPerPageOptions={[5, 10, 15]}
          colSpan={3}
          component="div"
          count={filteredArr.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </Paper>
  )
}
