import React from 'react'
import {
  Grid,
  Button,
  Snackbar,
  CircularProgress,
  Container,
  Dialog,
  DialogContent,
  DialogContentText,
  LinearProgress
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { Field } from 'redux-form'
import renderDropzoneField from '../../../utils/dropzone'
import { renderLinkTextField, renderTextField } from '../../../utils/wrappers'
import { MySnackbarContentWrapper } from '../../../utils/snackbar.utils'
import CssBaseline from '@material-ui/core/CssBaseline'
import DeleteSharpIcon from '@material-ui/icons/DeleteSharp'
import { red } from '@material-ui/core/colors'
import {
  saveApplicantDocData,
  saveApplicantProfileInfos
} from '../../../actions'
import { maxLength16 } from '../../../containers/validate'
import browserHistory from '../../../history'

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },

  form: {
    width: '100%',
    marginTop: theme.spacing(3)
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    alignItems: 'center',
    textAlign: 'center'
  },
  uploadField: {
    cursor: 'pointer'
  },

  surveyTitle: {
    marginTop: '2rem',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    }
  },
  surveySubTitle: {
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    }
  },
  number: {
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    }
  }
})

const SurveyDone = ({
  uploadedDocument,
  classes,
  loading,
  handleSubmit,
  applicantSubmitSurvey,
  isShowSnackbarSendApplicantSuccess,
  isShowSnackbarSaveApplicantDocDataError,
  isShowSnackbarSaveApplicantProfileInfosError,
  isShowSnackbarSaveApplicantDocDataSuccess,
  isShowSnackbarSaveApplicantProfileInfosSuccess,
  asyncActionFinish,
  isShowSnackbarSendApplicantFalse,
  isDisabledSendButton,
  isShowSnackbarFileMaximunSize,
  isShowAllowedFileTypes,
  uploadedDoc,
  clearUploadedCV,
  clearUploadedOtherFiles
}) => {
  const submitHandler = () => {
    applicantSubmitSurvey()
    saveApplicantDocData()
    saveApplicantProfileInfos()
  }

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <div className={classes.paper}>
        <h6 className={classes.surveyTitle}>
          Haluatko herättää enemmän kiinnostusta työnantajassa?
        </h6>
        <h6 className={classes.surveySubTitle}>
          Liitä tähän halutessasi CV ja/tai LinkedIn -profiilisi
        </h6>

        <form onSubmit={handleSubmit(submitHandler)}>
          <Grid>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6} className={classes.uploadField}>
                <Field
                  component={renderTextField}
                  name="applicant_first_name"
                  placeholder="First name"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} className={classes.uploadField}>
                <Field
                  component={renderTextField}
                  name="applicant_last_name"
                  placeholder="Last name"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6} className={classes.uploadField}>
                <Field
                  component={renderTextField}
                  name="applicant_email"
                  placeholder="username@startup.com"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} className={classes.uploadField}>
                <Field
                  component={renderTextField}
                  name="applicant_phone"
                  placeholder="Phone number"
                  fullWidth
                  type="number"
                  className={classes.number}
                  validate={[maxLength16]}
                  normalize={(value) => value && value.slice(0, 16)}
                />
              </Grid>

              <Grid item xs={12} sm={6} className={classes.uploadField}>
                <Field
                  component={renderDropzoneField}
                  name="cv_document"
                  isCV={true}
                  uploadedDocument={uploadedDocument}
                  cvBtnLabel={'Liitä tiedosto (pdf, word)'}
                  fullWidth
                />
                {!uploadedDocument.name ? (
                  <p style={{ fontStyle: 'italic', fontSize: '0.9rem' }}>
                    Tiedoston enimmäiskoko on 4,5 mb.
                  </p>
                ) : (
                  <DeleteSharpIcon
                    style={{ color: red[500] }}
                    onClick={() => {
                      clearUploadedCV()
                    }}
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={6} className={classes.uploadField}>
                <Field
                  component={renderDropzoneField}
                  name="other_documents"
                  isOtherFiles={true}
                  otherFilesLabel={'Liitä tiedosto (pdf, word)'}
                  uploadedDoc={uploadedDoc}
                  fullWidth
                />

                {!uploadedDoc.name ? (
                  <p style={{ fontStyle: 'italic', fontSize: '0.9rem' }}>
                    Tiedoston enimmäiskoko on 4,5 mb.
                  </p>
                ) : (
                  <DeleteSharpIcon
                    style={{ color: red[500] }}
                    onClick={() => {
                      clearUploadedOtherFiles()
                    }}
                  />
                )}
              </Grid>

              <Grid item xs={12}>
                <Field
                  component={renderLinkTextField}
                  name="linkedin"
                  id="applicantLinkedIn"
                  placeholder="Lisää linkki LinkedIn-profiiliisi"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  component={renderLinkTextField}
                  name="portfolio"
                  id="applicantPortfolio"
                  placeholder="Lisää linkki portfolioon"
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
          <div style={{ textAlign: 'center' }}>
            <Button
              type="submit"
              variant="contained"
              className={classes.submit}
              color="primary"
              disabled={loading || isDisabledSendButton}
            >
              {loading ? (
                <CircularProgress size={20}> </CircularProgress>
              ) : (
                'Lähetä hakemus'
              )}
            </Button>
            <Dialog open={loading} aria-describedby="alert-dialog-description">
              <DialogContent>
                <DialogContentText
                  id="alert-dialog-description"
                  style={{ fontSize: '15px', margin: '20px' }}
                >
                  Sending...
                </DialogContentText>
              </DialogContent>
              <div className={classes.progress}>
                <LinearProgress />
              </div>
            </Dialog>
          </div>
        </form>

        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          open={
            isShowSnackbarSendApplicantSuccess &&
            isShowSnackbarSaveApplicantProfileInfosSuccess &&
            isShowSnackbarSaveApplicantDocDataSuccess
          }
          autoHideDuration={3000}
          onClose={() => {
            asyncActionFinish('isShowSnackbarSendApplicantSuccess')
            asyncActionFinish('isShowSnackbarSaveApplicantProfileInfosSuccess')
            asyncActionFinish('isShowSnackbarSaveApplicantDocDataSuccess')
            browserHistory.push('/survey/thanks')
          }}
        >
          <MySnackbarContentWrapper
            onClose={() => {
              asyncActionFinish('isShowSnackbarSendApplicantSuccess')
              asyncActionFinish(
                'isShowSnackbarSaveApplicantProfileInfosSuccess'
              )
              asyncActionFinish('isShowSnackbarSaveApplicantDocDataSuccess')
            }}
            variant="success"
            message="Lähetetty onnistuneesti!"
          />
        </Snackbar>

        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          open={
            isShowSnackbarSendApplicantFalse ||
            isShowSnackbarSaveApplicantProfileInfosError ||
            isShowSnackbarSaveApplicantDocDataError
          }
          autoHideDuration={3000}
          onClose={() => {
            asyncActionFinish('isShowSnackbarSendApplicantFalse')
          }}
        >
          <MySnackbarContentWrapper
            onClose={() => {
              asyncActionFinish('isShowSnackbarSendApplicantFalse')
            }}
            variant="error"
            message="Lähetetty epäonnistuneesti. Yritä uudelleen myöhemmin."
          />
        </Snackbar>

        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          open={isShowSnackbarFileMaximunSize}
          autoHideDuration={3000}
          onClose={() => asyncActionFinish('isShowSnackbarFileMaximunSize')}
        >
          <MySnackbarContentWrapper
            onClose={() => asyncActionFinish('isShowSnackbarFileMaximunSize')}
            variant="error"
            message="Lataa toinen tiedosto. Tiedoston enimmäiskoko on 4,5 mb."
          />
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          open={isShowAllowedFileTypes}
          autoHideDuration={3000}
          onClose={() => asyncActionFinish('isShowAllowedFileTypes')}
        >
          <MySnackbarContentWrapper
            onClose={() => asyncActionFinish('isShowAllowedFileTypes')}
            variant="error"
            message="vain .pdf, .doc, .docx sallittu"
          />
        </Snackbar>
      </div>
    </Container>
  )
}
export default withStyles(styles)(SurveyDone)
