import { isValid } from 'redux-form'

export const registerValidate = (values) => {
  const errors = {}

  if (!values['firstName']) {
    errors.firstName = 'Kenttä on pakollinen'
  }
  if (!values['lastName']) {
    errors.lastName = 'Kenttä on pakollinen'
  }
  if (!values['password']) {
    errors.password = 'Salasanakenttä on pakollinen'
  }
  if (!values['email']) {
    errors.email = 'Sähköposti on pakollinen'
  }
  if (!values['RoleID']) {
    errors.RoleID = 'RoleID on pakollinen'
  }

  if (
    values['password'] &&
    !/^(?=.*[äöåa-z])(?=.*[ÄÖÅA-Z])(?=.*\d)[äöåa-zÄÖÅA-Z\d]*\S{8,}$/i.test(
      values['password']
    )
  ) {
    errors.password =
      'Virheellinen salasana, Salasanan tulee sisältää vähintään: 8 merkkiä, numeroita, pieniä ja isoja kirjaimia!'
  }

  if (values['password'] !== values['passwordConfirmation']) {
    errors.passwordConfirmation = 'Antamasi salasanat eivät täsmää'
  }

  if (!values['passwordConfirmation']) {
    errors.passwordConfirmation = 'Salasanan vahvistus on pakollinen'
  }

  if (
    values.email &&
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
  ) {
    errors.email = 'Antamasi sähköpostiosoite  on virheellinen'
  }

  return errors
}

export const signInValidate = (values) => {
  const errors = {}
  if (!values.email) {
    errors.email = 'Kenttä on pakollinen'
  }
  if (!values.password) {
    errors.password = 'Kenttä on pakollinen'
  }
  if (
    values.email &&
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
  ) {
    errors.email = 'Antamasi sähköpostiosoite  on virheellinen'
  }
  return errors
}
export const surveyValidate = (values) => {
  const errors = {}
  const surveyListErrors = []
  if (!values.surveyTitle) {
    errors.surveyTitle = 'Kenttä on pakollinen'
  }

  if (values.surveyList && values.surveyList.length) {
    values.surveyList.forEach((question, questionIndex) => {
      const questionErrors = {}

      if (!question || !question.question) {
        questionErrors.question = 'Kenttä on pakollinen'
      }

      if (question.type !== 'single_textbox') {
        const answersArrayErrors = []
        question.answers.forEach((answer, answerIndex) => {
          if (!answer) {
            answersArrayErrors[answerIndex] = 'Kenttä on pakollinen'
          }
        })
        if (answersArrayErrors.length) {
          questionErrors.answers = answersArrayErrors
        }
      }
      if (Object.keys(questionErrors).length) {
        surveyListErrors[questionIndex] = questionErrors
      }
    })
    if (surveyListErrors.length) {
      errors.surveyList = surveyListErrors
    }
  }

  return errors
}

export const postValidate = (values) => {
  const errors = {}
  if (!values.title) {
    errors.title = 'Kenttä on pakollinen'
  }
  if (!values.location) {
    errors.location = 'Kenttä on pakollinen'
  }
  if (
    (values.dueDate === '') & (values.validuntill_due === 'dueDate') ||
    (values.dueDate === 'valid_until_further_notice') &
      (values.validuntill_due === 'dueDate') ||
    !values.dueDate & (values.validuntill_due !== 'valid_until_further_notice')
  ) {
    errors.dueDate = 'Eräpäivä Kenttä on pakollinen'
  }
  if (values.description === '') {
    errors.description = 'Kenttä on pakollinen'
  }
  return errors
}

export const surveyDoneValidate = (values) => {
  const errors = {}
  if (!values.applicant_first_name) {
    errors.applicant_first_name = 'Kenttä on pakollinen'
  }
  if (!values.applicant_first_name && isValid === true) {
    errors.applicant_first_name = 'Kenttä on pakollinen'
  }
  if (!values.applicant_last_name) {
    errors.applicant_last_name = 'Kenttä on pakollinen'
  }
  if (
    values.applicant_email &&
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.applicant_email)
  ) {
    errors.applicant_email = 'Antamasi sähköpostiosoite  on virheellinen'
  }
  if (!values.applicant_email) {
    errors.applicant_email = 'Kenttä on pakollinen'
  }

  if (
    values.applicant_phone &&
    !/^((00)?(\+)?\d{1,3})+(\d{1,3})+(\d{4,10})$/i.test(values.applicant_phone)
  ) {
    errors.applicant_phone = 'Invalid'
  }

  if (!values.applicant_phone) {
    errors.applicant_phone = 'Kenttä on pakollinen'
  }

  return errors
}

export const validateDateRange = (values) => {
  const errors = {}

  if (values.qDate && !values.qToDate) {
    errors.qToDate = 'You must enter an end date'
  }

  return errors
}

const maxLength = (max) => (value) =>
  value && value.length > max ? 'Must be 16 characters or less' : undefined
export const maxLength16 = maxLength(16)

export const required = (value) => (value ? undefined : 'Required')
export const emptyAnswers = (data) =>
  !data || !data.answers || data.answers.some((answer) => !answer)
