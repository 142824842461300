import React, { Component } from 'react'
import { Grid } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'

const styles = (theme) => ({
  button: {
    margin: 6
  },
  formBtn: {
    margin: '30px 5px'
  },
  card: {
    margin: '70px auto'
  },
  layout: {
    margin: '20px auto auto 40px'
  },
  ctaBtn: {
    margin: '-10px auto auto 40px'
  },
  title: {
    margin: '50px 0',
    color: theme.palette.primary.main
  },
  fieldLabel: {
    color: theme.palette.primary.main,
    fontWeight: 'bold'
  }
})

class SurveyDone extends Component {
  render() {
    return (
      <div>
        <Grid
          container
          style={{ background: 'white', padding: '10px', paddingTop: '100px' }}
          justify="space-between"
          alignItems="center"
        >
          <Grid
            item
            xs={12}
            sm={12}
            container
            alignItems="center"
            justify="center"
            direction="column"
          >
            <CheckCircleOutlineIcon
              color="primary"
              fontSize="large"
            ></CheckCircleOutlineIcon>
            <h2 style={{ fontWeight: '600', marginTop: '10px' }}>Kiitos!</h2>
            <p>Olemme vastaanottaneet vastauksesi.</p>
          </Grid>
        </Grid>
      </div>
    )
  }
}
export default withStyles(styles)(SurveyDone)
