import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import {
  MoreHoriz,
  HighlightOff,
  Launch,
  Send,
  Block,
  FlipCameraAndroid
} from '@material-ui/icons'
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined'
import { Field } from 'redux-form'

import {
  Button,
  Grid,
  Menu,
  MenuItem,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core'
import LoadingComp from '../../layout/Loading.component'
import { sortByLatestDate } from '../../../utils/customScripts'

const styles = (theme) => ({
  '@global': {
    body: {
      background: theme.palette.primary.bg
    }
  },
  dashbrd: {
    display: 'flex'
  },
  jobpostTopSection: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  recentPostHeader: {
    [theme.breakpoints.only('xs')]: {
      fontSize: '1.5rem'
    }
  },
  loading: {
    textAlign: 'center'
  },
  survey_list: {
    width: '92%',
    marginTop: '20px',
    display: 'flex',
    flexDirection: 'column'
    // paddingBottom: '20vh'
  },
  survey_item: {
    backgroundColor: theme.palette.background.default,
    position: 'relative',
    margin: '16px 0',
    border: '1px solid #EFEFEF',
    alignItems: 'stretch',
    '&:hover': {
      border: '1px solid #00BF6F'
    },
    '& .status_draft': {
      backgroundColor: theme.palette.primary.red,
      position: 'absolute',
      left: '16px',
      zIndex: 2,
      borderRadius: '0 0 3px 3px',
      padding: '2px 5px',
      color: '#fff',
      verticalAlign: 'top',
      fontSize: '13px',
      fontWeight: 500
    },
    '& .status_open': {
      backgroundColor: theme.palette.primary.green,
      position: 'absolute',
      left: '16px',
      zIndex: 2,
      borderRadius: '0 0 3px 3px',
      padding: '2px 5px',
      color: '#fff',
      verticalAlign: 'top',
      fontSize: '13px',
      fontWeight: 500
    }
  },

  survey_button: {
    alignSelf: 'flex-end'
  },

  survey_item_title: {
    fontSize: '16px',
    fontWeight: '500'
  },
  options: {
    '&:hover': {
      cursor: 'pointer'
    }
  },

  options_icon: {
    paddingRight: '9px'
  },
  btn_dis_label: {
    color: theme.palette.primary.green
  }
})

class SurveyDashboard extends Component {
  state = {
    anchorEl: null,
    clickedSurvey: {},
    open: false,
    isCreateDialogOpen: false
  }
  componentDidMount() {
    this.props.getAllPosts()
  }

  handleClick = (event, item) => {
    this.setState({
      anchorEl: event.currentTarget,
      clickedSurvey: item
    })
  }

  handleClose = () => {
    this.setState({
      anchorEl: null
    })
  }

  handleOpenCreateDialog = () => {
    this.setState({
      isCreateDialogOpen: true
    })
  }

  handleCloseCreateDialog = () => {
    this.setState({
      isCreateDialogOpen: false
    })
  }

  handleOpenDialog = () => {
    this.setState({
      open: true
    })
  }

  handleClosenDialog = () => {
    this.setState({
      open: false,
      clickedSurvey: {}
    })
  }

  render() {
    const { classes, postList } = this.props
    return (
      <div className={classes.dashbrd}>
        {/*Failed prop type: The prop `justify` of `ForwardRef(Grid)` is deprecated. Use `justifyContent`*/}
        <Grid container justifyContent="center">
          <div className={classes.survey_list}>
            <div className={classes.jobpostTopSection}>
              {postList.length ? (
                <h2 className={classes.recentPostHeader}>Recent posts</h2>
              ) : (
                <h2 className={classes.loading}>Loading.....</h2>
              )}
              <div className={classes.createpost}>
                <Button
                  onClick={() => {
                    this.props.clearPostDetail()
                    this.props.setPostState('none')
                    this.props.history.push('/post/create')
                  }}
                  variant="contained"
                  color="primary"
                  className={classes.survey_button}
                >
                  Create Post
                </Button>
              </div>
            </div>
            <div>
              {postList &&
                sortByLatestDate(postList, 'CreatedDate').map((item, index) => {
                  return (
                    <div key={index} className={classes.survey_item}>
                      <span
                        className={
                          item.IsActive ? 'status_open' : 'status_draft'
                        }
                      >
                        {item.IsActive ? 'ACTIVE' : 'CLOSED'}
                      </span>
                      <Grid
                        container
                        style={{ padding: '26px 13px' }}
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Grid item xs={4}>
                          <p className={classes.survey_item_title}>
                            {item.Title}, {item.Location}
                          </p>
                        </Grid>

                        <Grid
                          item
                          xs={4}
                          container
                          justifyContent="center"
                          direction="column"
                          alignItems="center"
                        >
                          <h6>{item.ViewCount}</h6>
                          <Typography variant="subtitle1">Responses</Typography>
                        </Grid>
                        <Grid
                          item
                          xs={4}
                          container
                          justifyContent="center"
                          direction="column"
                          alignItems="center"
                        >
                          <MoreHoriz
                            className={classes.options}
                            onClick={(e) => {
                              this.props.unsetSurveyDetails()
                              this.handleClick(e, item)
                            }}
                          />
                          <Typography variant="subtitle1">Options</Typography>
                        </Grid>
                      </Grid>
                    </div>
                  )
                })}
            </div>
            <Menu
              id="simple-menu"
              anchorEl={this.state.anchorEl}
              keepMounted
              open={Boolean(this.state.anchorEl)}
              onClose={this.handleClose}
            >
              <MenuItem
                onClick={() => {
                  this.props.setPostDetail(this.state.clickedSurvey)
                  this.props.populateAutoMessage(this.state.clickedSurvey.ID)
                  this.props.setPostState('edit')
                  this.props.history.push('/post/create')
                  this.handleClose()
                }}
              >
                {' '}
                <Launch className={classes.options_icon}></Launch>{' '}
                <Typography variant="subtitle1">Edit Post</Typography>
              </MenuItem>

              <MenuItem
                onClick={() => {
                  this.props.copyPostDetail(this.state.clickedSurvey)
                  this.props.clearImagesFromState()
                  this.props.setPostState('copy')
                  this.props.history.push('/post/create')
                  this.handleClose()
                }}
              >
                {' '}
                <FileCopyOutlinedIcon
                  className={classes.options_icon}
                ></FileCopyOutlinedIcon>{' '}
                <Typography variant="subtitle1">Copy Post</Typography>
              </MenuItem>

              <MenuItem
                onClick={() => {
                  this.props.getPostDetailsById(this.state.clickedSurvey.ID)
                  this.props.history.push('/survey/collect')
                  this.handleClose()
                }}
              >
                <Send className={classes.options_icon}></Send>{' '}
                <Typography variant="subtitle1">Get Link Post</Typography>
              </MenuItem>

              {this.state.clickedSurvey.IsActive ? (
                <MenuItem
                  onClick={() => {
                    this.props.updatePostActive(this.state.clickedSurvey.ID)
                    this.handleClose()
                  }}
                >
                  <Block className={classes.options_icon}></Block>{' '}
                  <Typography variant="subtitle1">Disable the link</Typography>
                </MenuItem>
              ) : (
                <MenuItem
                  onClick={() => {
                    this.props.updatePostActive(this.state.clickedSurvey.ID)
                    this.handleClose()
                  }}
                >
                  <FlipCameraAndroid
                    className={classes.options_icon}
                  ></FlipCameraAndroid>{' '}
                  <Typography variant="subtitle1">Activate the link</Typography>
                </MenuItem>
              )}

              <MenuItem
                onClick={() => {
                  this.handleOpenDialog()
                  this.handleClose()
                }}
              >
                {' '}
                <HighlightOff
                  className={classes.options_icon}
                ></HighlightOff>{' '}
                <Typography variant="subtitle1">Delete Post</Typography>
              </MenuItem>
            </Menu>

            <Dialog
              open={this.state.open}
              onClose={this.handleClosenDialog}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {'Delete the survey?'}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  This is a permanent action and cannot be undone.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  classes={{ label: classes.btn_dis_label }}
                  onClick={this.handleClosenDialog}
                  variant="outlined"
                  color="primary"
                >
                  Disagree
                </Button>
                <Button
                  onClick={() => {
                    this.props.deletePost(this.state.clickedSurvey.ID)
                    this.handleClosenDialog()
                  }}
                  variant="contained"
                  color="primary"
                  autoFocus
                >
                  Agree
                </Button>
              </DialogActions>
            </Dialog>

            {/* Dialog for creating survey title */}

            <Dialog
              open={this.state.isCreateDialogOpen}
              onClose={this.handleCloseCreateDialog}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {'Name your survey.'}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <Field
                    name="surveyTitle"
                    component="input"
                    type="text"
                  ></Field>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  classes={{ label: classes.btn_dis_label }}
                  onClick={this.handleCloseCreateDialog}
                  variant="outlined"
                  color="primary"
                >
                  Disagree
                </Button>
                <Button
                  onClick={() => {
                    this.props.history.push('/survey/create')
                    this.handleCloseCreateDialog()
                  }}
                  variant="contained"
                  color="primary"
                  autoFocus
                >
                  Agree
                </Button>
              </DialogActions>
            </Dialog>
          </div>

          {/* end survey lists */}
        </Grid>

        <LoadingComp loading={this.props.loading}></LoadingComp>
      </div>
    )
  }
}

export default withStyles(styles)(SurveyDashboard)
