import { IconButton } from '@material-ui/core'
import React from 'react'

export function IButton(props) {
  const { className, size, ariaLabel, children, onClick } = props
  return (
    <IconButton
      aria-label={ariaLabel}
      size={size}
      onClick={onClick}
      className={className}
    >
      {children}
    </IconButton>
  )
}
