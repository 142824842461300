import { getFormValues, reset } from 'redux-form'
import { call, put, takeEvery } from 'redux-saga/effects'
import {
  asyncActionStart,
  fetchApplicantsError
} from '../actions'
import { VPT_API_SERVER } from '../constants'
import store from '../store'
import { normalPost } from '../utils/request'

export function* fetchApplicantsSaga() {
  try {
    yield put(asyncActionStart('fetchApplicants'))
    const url = `${VPT_API_SERVER}/GetApplicantsProfileInfos`

    const formValues = getFormValues('searchForm')(store.getState())

    let body

    if (formValues === undefined) {
      body = {}
    } else {
      body = {
        FirstName: formValues.qFirstName && formValues.qFirstName.trim(),
        LastName: formValues.qLastname && formValues.qLastname.trim(),
        email: formValues.qEmail && formValues.qEmail.trim(),
        phone: formValues.qPhone && formValues.qPhone.trim(),
        created_application: formValues.qDate && formValues.qDate.trim(),
        dueDate: formValues.qToDate && formValues.qToDate.trim(),
        Id: formValues.qJobTitle && formValues.qJobTitle
      }
    }
    const result = yield call(normalPost, url, JSON.stringify(body))
    const entries = JSON.parse(result.data.data)
    yield put({ type: 'FETCH_APPLICANTS_SUCCESS', applicants: entries })
  } catch (error) {
    console.warn(error)

    yield put({ type: 'FETCH_APPLICANTS_ERROR', message: error.message })
  }
}

export function* getApplicantDocDataByIDSaga({ applicantID}) {
  try {
    yield put(asyncActionStart('getApplicantDocDataByID'))
    const url = `${VPT_API_SERVER}/GetApplicantsDocDataByID`
    let body
    const formValues = getFormValues('searchForm')(store.getState())
    if (applicantID === undefined) {
      body = {}
     } else {
        body = {
          applicantID,
          createdApplication: formValues?.qDate && formValues.qDate.trim(),
          dueDate: formValues?.qToDate && formValues.qToDate.trim(),
          postId: formValues?.qJobTitle && formValues.qJobTitle
        }
      }
    const result = yield call(normalPost, url, JSON.stringify(body))
    const entries = JSON.parse(result.data.data)
    if (result.data.success) {
      yield put({
        type: 'GET_APPLICANT_DOC_DATA_BY_ID_SUCCESS',
        result: entries
      })
    }
  } catch (error) {
    console.warn(error)
    yield put({
      type: 'GET_APPLICANT_DOC_DATA_BY_ID_ERROR',
      message: error.message
    })
  }
}

function* resetFilterSaga() {
  try {
    yield put(reset('searchForm'))
  } catch (e) {
    console.warn(e)
    yield put(fetchApplicantsError())
  }
}

export function* watchFetchApplicantsSaga() {
  yield takeEvery('FETCH_APPLICANTS', fetchApplicantsSaga)
}

export function* watchGetApplicantDocDataByIDSaga() {
  yield takeEvery('GET_APPLICANT_DOC_DATA_BY_ID', getApplicantDocDataByIDSaga)
}

export function* watchApplicantResetFilterSaga() {
  yield takeEvery('RESET_FILTER', resetFilterSaga)
}
