import React, { useEffect } from 'react'
import { Grid } from '@material-ui/core'
import { Field, change } from 'redux-form'
import { withStyles } from '@material-ui/core/styles'
import {
  renderSwitchLabels as RenderSwitchLabels,
  renderTextField
} from '../../../utils/wrappers'
import TextEditor from '../../../utils/wrappers'
import { useState } from 'react'
import { autoEmailToApplicant } from '../../../actions'
import { useDispatch } from 'react-redux'
const styles = () => ({
  switchBtn: {
    display: 'flex',
    margin: '20px 0'
  },
  autoReplyContainer: {
    '& .rw-multiselect ': {
      border: '1px solid rgb(217,217,217)',
      paddingBottom: 5
    },
    '& .rw-multiselect-input': {
      display: 'none'
    },
    '& .rw-widget-input': {
      boxShadow: 'none',
      padding: 10,
      cursor: 'pointer'
    },
    '& .rw-multiselect-taglist': {
      width: '100%',
      display: 'flex'
    },
    '& .rw-multiselect-tag': {
      width: 'auto',
      marginRight: 10,
      padding: '0 5px',
      border: '1px solid rgb(217,217,217)',
      borderRadius: '5px'
    },
    '& .rw-list-option': {
      paddingLeft: '10px',
      boxSizing: 'border-box',
      border: '1px solid rgb(217,217,217)',
      width: '20%',
      borderRadius: '5px'
    },
    '& .rw-multiselect-tag-btn': {
      border: 'none',
      background: 'transparent'
    },
    '& .rw-list-empty': {
      display: 'none'
    }
  }
})

const AutomaticEmailAnswers = ({
  automaticEmailToggleBtn,
  classes,
  isSendThankyou,
  dispatch
}) => {
  const [isAutoReply, setIsAutoReply] = useState(automaticEmailToggleBtn)
  const [is_SendThankyou, setIs_SendThankYou] = useState(isSendThankyou)
  const dispatchsaga = useDispatch()
  /* code for multilanguage, could be used in future (import formvalues and dispatch) */
  /*const [languages, setLanguages] = useState(formValues.values.email_language)*/
  useEffect(() => {
    if (isSendThankyou !== undefined) {
      setIsAutoReply(is_SendThankyou)
    }
    dispatch(change('post', 'automaticEmailToggleBtn', isAutoReply))
  }, [isAutoReply, dispatch, is_SendThankyou, isSendThankyou])

  return (
    <>
      <div className={classes.switchBtn}>
        <RenderSwitchLabels
          label={'Send thank you email to all applicants'}
          isSendThankyou={isAutoReply}
          toggleChecked={() => {
            if (isSendThankyou !== undefined) {
              setIs_SendThankYou(!is_SendThankyou)
            }
            setIsAutoReply(!isAutoReply)
            dispatchsaga(autoEmailToApplicant(!isAutoReply))
          }}
        />
      </div>
      <Grid container className={classes.autoReplyContainer}>
        {(isAutoReply || is_SendThankyou) && (
          <Grid item xs={12}>
            <div style={{ marginBottom: 20 }}>
              <p style={{ color: '#6c757d' }}>{'Subject'}:</p>
              <Field
                component={renderTextField}
                label={'Subject'}
                name="email_subject"
                id="email_subject"
              />
            </div>
            <div>
              <p>{'Use default message or create your own message:'}</p>

              {/*code for multiLanguage*/}
              {/*<p>
                {
                  'Please select a message from templates in different languages or create your own message:'
                }
              </p>
              <Field
                component={renderMultiselect}
                name="email_language"
                dataKey="type"
                fullWidth
                margin="dense"
                required
                placeholder={'emailLanguage'}
                data={[
                  { type: 'finnish', label: 'Finnish' },
                  { type: 'english', label: 'English' }
                ]}
                value={languages}
                textField={'label'}
                label={'label'}
                onChange={(e) => {
                  setLanguages(e)
                }}
              />*/}
            </div>
            <Field
              component={TextEditor}
              fullWidth
              name="email_message"
              placeholder={'Email messages'}
              id="email_message"
            ></Field>
          </Grid>
        )}
      </Grid>
    </>
  )
}

export default withStyles(styles)(AutomaticEmailAnswers)
