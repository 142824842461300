const initialState = {
  applicants: [],
  filteredData: [],
  loading: false,
  error: null
}

export default function (state = initialState, action) {
  switch (action.type) {
    case 'FETCH_APPLICANTS':
      return { ...state, loading: true }
    case 'HANDLE_SEARCH':
      return {
        ...state,
        loading: false
      }
    case 'FETCH_APPLICANTS_SUCCESS':
      return { ...state, loading: false, applicants: action.applicants }
    case 'FETCH_APPLICANTS_ERROR':
      return { ...state, loading: false, error: action.message }

    default:
      return state
  }
}
