import { connect } from 'react-redux'
import {
  getSurveyDetailsByPostId,
  applicantSubmitSurvey
} from '../../../actions'
import SurveyComponent from '../../../components/survey/jobSeeker/Survey.component.js'
import { reduxForm } from 'redux-form'

const mapStateToProps = (state) => {
  const {
    survey: { surveyDetail },
    async
  } = state

  const newFormValue = {
    ID: surveyDetail.ID,
    surveyTitle: surveyDetail.SurveyTitle,
    pageTitle: (surveyDetail && surveyDetail.PageTitle) || null,
    description: (surveyDetail && surveyDetail.Description) || null,
    status: surveyDetail.Status,
    surveyList: surveyDetail.Questions && [
      ...surveyDetail.Questions.map((item, index) => {
        return {
          ...surveyDetail.Questions[index],
          id: index
        }
      })
    ]
  }

  return {
    state,
    formValues: newFormValue,
    loading: async.applicantSendSurvey.loading
  }
}

const actions = (dispatch) => {
  return {
    dispatch,
    applicantSubmitSurvey: () => dispatch(applicantSubmitSurvey()),
    getSurveyDetailsByPostId: (id) => dispatch(getSurveyDetailsByPostId(id))
  }
}

const SurveyForm = reduxForm({
  form: 'surveyAnswer',
  destroyOnUnmount: false
})(SurveyComponent)

const SurveyContainer = connect(mapStateToProps, actions)(SurveyForm)

export default SurveyContainer
