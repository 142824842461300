import { all } from 'redux-saga/effects'
import { watchSignUpSaga } from './signUp.saga'
import { watchSignInSaga, watchLogoutSaga } from './signIn.saga'
import {
  watchGetAllSurveys,
  watchGetSurveyDetailsById,
  watchSaveSurvey,
  watchUpdateSurveys,
  watchDeleteSurveys,
  watchApplicantSubmitSurvey,
  watchUpdateSurveyStatusSurvey,
  watchDeleteCVSaga,
  watchDeleteOtherFilesSaga
} from './survey.saga'
import {
  watchSavePost,
  watchGetAllPosts,
  watchUpdatePost,
  watchDeletePost,
  watchUpdatePostActiveSaga,
  watchGetPostDetailsById,
  watchPopulateAutoMessageSaga
} from './post.saga'
import {
  watchApplicantResetFilterSaga,
  watchFetchApplicantsSaga,
  watchGetApplicantDocDataByIDSaga
} from './ApplicantsSearch'

// Single entry point to start all sagas at once

export default function* rootSaga() {
  yield all([
    watchSignUpSaga(),
    watchSignInSaga(),
    watchLogoutSaga(),
    watchGetAllSurveys(),
    watchGetSurveyDetailsById(),
    watchSaveSurvey(),
    watchUpdateSurveys(),
    watchDeleteSurveys(),
    watchPopulateAutoMessageSaga(),
    watchApplicantSubmitSurvey(),
    watchUpdateSurveyStatusSurvey(),
    watchSavePost(),
    watchGetAllPosts(),
    watchUpdatePost(),
    watchDeletePost(),
    watchUpdatePostActiveSaga(),
    watchGetPostDetailsById(),
    watchDeleteCVSaga(),
    watchDeleteOtherFilesSaga(),
    watchFetchApplicantsSaga(),
    watchGetApplicantDocDataByIDSaga(),
    watchApplicantResetFilterSaga()
  ])
}
