import React, { Component } from 'react'
import {
  Dialog,
  DialogContent,
  DialogContentText,
  LinearProgress
} from '@material-ui/core'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
})

class Loading extends Component {
  render() {
    return (
      <Dialog
        open={this.props.loading}
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{ fontSize: '15px', margin: '20px' }}
          >
            Loading...
          </DialogContentText>
        </DialogContent>
        <div>
          <LinearProgress />
        </div>
      </Dialog>
    )
  }
}

Loading.propTypes = {
  loading: PropTypes.bool
}

export default withStyles(styles)(Loading)
