import React from 'react'
import { Provider } from 'react-redux'
import store, { persistor } from './store'
import Routes from './Routes'
import { ThemeProvider } from '@material-ui/styles'
import theme from './styles/customTheme'
import { PersistGate } from 'redux-persist/lib/integration/react'
import CacheBuster from './CacheBuster'

export default class RootComponent extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <PersistGate persistor={persistor} loading={null}>
            <CacheBuster>
              {({ loading, isLatestVersion, refreshCacheAndReload }) => {
                if (loading) return null
                if (!loading && !isLatestVersion) {
                  refreshCacheAndReload()
                }
                return <div></div>
              }}
            </CacheBuster>
            <Routes />
          </PersistGate>
        </ThemeProvider>
      </Provider>
    )
  }
}
