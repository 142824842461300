import React from 'react'
import Dropzone from 'react-dropzone'
import Placeholder from './placeholder'
import { connect } from 'react-redux'
import store from '../store'
import {
  retrieveImageInfo,
  retrieveLogoInfo,
  retrieveDocumentInfo,
  asyncActionStart,
  retrieveOtherFilesInfo
} from '../actions'
import { fromImage } from 'imtool'
import { renderDenseTextField as TextField } from './wrappers'
import { reWriteString } from './wrappers'

const renderDropzoneField = function ({
  input,
  name,
  imagefile,
  btnText,
  cvBtnLabel,
  isLogo,
  isImage,
  isCV,
  CV,
  uploadedDocument,
  isOtherFiles,
  meta: { touched, error, invalid },
  otherFilesLabel,
  uploadedDoc,
  otherFile
}) {
  return (
    <div>
      <Dropzone
        multiple={true}
        resizeWidth={100}
        name={name}
        onDrop={async (filesToUpload) => {
          var file = filesToUpload[0]
          if (isImage) {
            store.dispatch(retrieveImageInfo(file))

            const reader = new FileReader()
            reader.onload = (event) => {
              fromImage(event.target.result)
                .then((tool) => tool.thumbnail(1000, false).toDataURL())
                .then((blob) => {
                  input.onChange(blob)
                })
            }
            reader.readAsDataURL(file)
          }
          if (isLogo) {
            store.dispatch(retrieveLogoInfo(file))
            const reader = new FileReader()
            reader.onload = (event) => {
              input.onChange(event.target.result)
            }
            reader.readAsDataURL(file)
          }

          if (isOtherFiles) {
            if (file.size <= 4720128) {
              store.dispatch(retrieveOtherFilesInfo(file))
              const reader = new FileReader()
              reader.onload = (event) => {
                input.onChange(event.target.result)
              }
              reader.readAsDataURL(file)
            } else {
              store.dispatch(asyncActionStart('isShowSnackbarFileMaximunSize'))
            }
          } else if (isCV) {
            if (file.size <= 4720128) {
              store.dispatch(retrieveDocumentInfo(file))
              const reader = new FileReader()
              reader.onload = (event) => {
                input.onChange(event.target.result)
              }
              reader.readAsDataURL(file)
            } else {
              store.dispatch(asyncActionStart('isShowSnackbarFileMaximunSize'))
            }
          }
        }}
      >
        {({ getRootProps, getInputProps }) => {
          const newInputProps = {
            ...getInputProps(),
            accept: !isImage ? '.doc,.docx,.pdf' : undefined
          }

          return (
            <section>
              <div {...getRootProps()}>
                <input {...newInputProps} />

                {isCV ? (
                  !uploadedDocument.name ? (
                    <>
                      <label htmlFor="upload_cv">Lisää ansioluettelo</label>
                      <TextField
                        placeholder={cvBtnLabel}
                      ></TextField>
                    </>
                  ) : CV !== '' && uploadedDocument.size <= 4720128 ? (
                    reWriteString(uploadedDocument.name)
                  ) : (
                    <>
                      <label htmlFor="upload_cv">Lisää ansioluettelo</label>
                      <TextField
                        placeholder={cvBtnLabel}
                      ></TextField>
                    </>
                  )
                ) : isOtherFiles ? (
                  !uploadedDoc.name ? (
                    <>
                      <label htmlFor="upload_file">Lisää tiedosto</label>
                      <TextField
                        placeholder={otherFilesLabel}
                      ></TextField>
                    </>
                  ) : otherFile !== '' && uploadedDoc.size <= 4720128 ? (
                    reWriteString(uploadedDoc.name)
                  ) : (
                    <>
                      <label htmlFor="upload_file">Lisää tiedosto</label>
                      <TextField
                        placeholder={otherFilesLabel}
                      ></TextField>
                    </>
                  )
                ) : (
                  <Placeholder
                    error={error}
                    touched={touched}
                    btnText={btnText}
                    imagefile={imagefile}
                    isLogo={isLogo}
                    isImage={isImage}
                  />
                )}
              </div>
            </section>
          )
        }}
      </Dropzone>
    </div>
  )
}

export default connect()(renderDropzoneField)
