import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import $ from 'jquery'

/**
 * Function to generate a PDF from content within a specified HTML element.
 */
export function getPDF() {
  html2canvas($('#canvas_div_pdf')[0], { allowTaint: true, scale: 2 }).then(function (
    canvas
  ) {
    canvas.getContext('2d')

    const contentWidth = canvas.width
    const contentHeight = canvas.height

    //The height of the canvas which one pdf page can show
    const pageHeight = (contentWidth / 595.28) * 841.89
    let leftHeight = contentHeight
    //addImage y-axial offset
    let position = 0
    //a4 format 
    const imgWidth = 595.28
    const imgHeight = (595.28 / contentWidth) * contentHeight

    const pageData = canvas.toDataURL('image/jpeg', 1.0)

    const pdf = new jsPDF('', 'pt', 'a4')

    if (leftHeight < pageHeight) {
      // add the image to the pdf if it fits withing one page
      pdf.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight)
    } else {
      // if the content doesnt fit, iterate through each page
      while (leftHeight > 0) {
        pdf.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight)
        leftHeight -= pageHeight
        position -= 841.89
        // to not have empty pages
        if (leftHeight > 0) {
          pdf.addPage()
        }
      }
    }
    pdf.save('qa_tallentia.pdf')
  })
}
