import { connect } from 'react-redux'
import {
  clearImagesFromState,
  savePost,
  asyncActionFinish,
  updatePost,
  getSurveyDetailsByPostId,
  setPostState
} from '../../../actions'
import PostCreateComponent from '../../../components/survey/company/PostCreate.component'
import { reduxForm, getFormValues } from 'redux-form'
import store from '../../../store'
import { postValidate as validate } from '../../validate'

const mapStateToProps = (state) => {
  const { post, async } = state

  const postForm = getFormValues('post')(store.getState())
  const { postDetail, postState } = post
  const emailSubject = 'Kiitos hakemuksestasi / Nordic C-Management'
  const emailMessage =
    '<p>Moi,</p> <p>Kiit&auml;mme mielenkiinnostasi yrityst&auml;mme kohtaan ja vahvistamme, ett&auml; hakemuksesi on vastaanotettu.<br>K&auml;sittelemme kaikki hakemukset ja olemme sinuun yhteydess&auml; henkil&ouml;kohtaisesti, mik&auml;li sinut on valittu haastatteluun.<br>Muussa tapauksessa toivotamme sinulle onnea ty&ouml;nhakuun!</p> <p>Yst&auml;v&auml;llisin terveisin</p> <p>Nordic C-Management</p> <p>Puh:+358 40 3571 533</a>,<br>Salomonkatu 17 B, 9.krs<br>00100 HELSINKI<br><a title="http://www.c-management.fi/" href="http://www.c-management.fi/" target="_blank">http://www.c-management.fi/</a></p>'
  let initialValues = {
    title: '',
    location: '',
    description: '',
    dueDate: '',
    document: '',
    email_subject: `${emailSubject}`,
    email_message: `${emailMessage}`,
    validuntill_due: 'valid_until_further_notice'
  }
  //for editing
  if (postDetail && postDetail.ID) {
    initialValues = {
      id: postDetail.ID,
      title: postDetail.Title,
      location: postDetail.Location,
      dueDate: postDetail.due_date,
      validuntill_due: postDetail.due_date,
      description: postDetail.Description,
      document: postDetail.Document,
      email_subject: `${emailSubject}`,
      email_message: `${emailMessage}`
    }
  }

  return {
    state,
    stateImageFile: postForm && postForm.document,
    postPic: (postForm && postForm.document) || postDetail.Document,
    postDetail: post.postDetail,
    showCreateSurvey: async.showCreateSurvey.loading,
    initialValues,
    postState,
    loading: async.savePost.loading
  }
}

const actions = (dispatch) => {
  return {
    dispatch,
    clearImagesFromState: () => dispatch(clearImagesFromState()),
    setPostState: (postState) => dispatch(setPostState(postState)),
    savePost: () => dispatch(savePost()),
    asyncActionFinish: (name) => dispatch(asyncActionFinish(name)),
    updatePost: (isChangeImg) => dispatch(updatePost(isChangeImg)),
    getSurveyDetailsByPostId: (id, routeDes) =>
      dispatch(getSurveyDetailsByPostId(id, routeDes))
  }
}

const PostCreateForm = reduxForm({
  form: 'post',
  validate
})(PostCreateComponent)

const PostCreateContainer = connect(mapStateToProps, actions)(PostCreateForm)

export default PostCreateContainer
